import styled from 'styled-components';
import { primaryDarkNew, primaryNew } from 'themes/variables';
import media from 'themes/media';

export const Stepper = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0;
  max-width: 624px;
  display: flex;
  justify-content: space-between;

  &.full {
    max-width: 100%;
  }

  li {
    font-size: 14px;
    line-height: 18px;
    color: rgba(${primaryDarkNew}, 0.2);
    font-family: 'GT-Walsheim-Pro-Medium';
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;

    &.completed {
      color: rgb(${primaryNew});
      .line {
        background: rgb(${primaryNew});
        &:before,
        &:after {
          border-color: rgb(${primaryNew});
        }
      }
    }

    &.active:not(:last-child) {
      .line {
        &:after {
          border-color: rgba(${primaryDarkNew}, 0.2);
        }
      }
    }

    &.active {
      color: rgb(${primaryNew});
      .line {
        border: 1.5px solid rgb(${primaryNew});
      }
    }
    &.completed {
      .line {
        svg {
          display: block;
        }
      }
    }

    &:first-child {
      .line {
        &:before {
          display: none;
        }
      }
    }
    &:last-child {
      .line {
        &:after {
          display: none;
        }
      }
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      background: #ffffff;
      border-radius: 100%;
      border: 1.5px solid rgba(${primaryDarkNew}, 0.2);
      margin-bottom: 10px;

      svg {
        display: none;
      }
      &.initial-step {
        background: rgb(${primaryNew});
      }

      &:after {
        content: '';
        position: absolute;
        width: 85%;
        height: 1px;
        border: 1px dashed rgba(${primaryDarkNew}, 0.2);
        right: 0;
        top: 6px;
        left: calc(15%);
      }
      ${media.medium`
       &:after {
        content: '';
        position: absolute;
        width: 92%;
        height: 1px;
        border: 1px dashed rgba(${primaryDarkNew}, 0.2);
        right: 0;
        top: 6px;
        left: calc(7%);
      }
    `};
    }
    ${media.medium`
      font-size: 18px;
      line-height: 21px;
    `};
  }
`;
