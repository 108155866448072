import { defineMessages } from 'react-intl';

export const scope = 'Notchup.containers.AdminCompanies';

export default defineMessages({
  title: {
    id: `${scope}.title.head`,
    defaultMessage: 'Companies',
  },
  metaTitle: {
    id: `${scope}.metaTitle.head`,
    defaultMessage: 'Companies',
  },
  heading: {
    id: `${scope}.heading`,
    defaultMessage: 'All Companies',
  },
  noRecordTitle: {
    id: `${scope}.noRecordTitle`,
    defaultMessage: 'No companies were found!',
  },
  noRecordDesc: {
    id: `${scope}.noRecordDesc`,
    defaultMessage:
      'Create a company account and invite clients to create their profiles. You will be able to see the list of companies here once you create a company account or any client will create an account.',
  },
  addCompanies: {
    id: `${scope}.addCompanies`,
    defaultMessage: 'Add a company',
  },
  inviteNow: {
    id: `${scope}.inviteNow`,
    defaultMessage: 'Send an invite',
  },
  referModalTitle: {
    id: `${scope}.referModalTitle`,
    defaultMessage: 'Invite',
  },
  referModalText: {
    id: `${scope}.referModalText`,
    defaultMessage: 'Invite client to claim this company profile or other users to access this profile. ',
  },
  inviteUsersSubText: {
    id: `${scope}.inviteUsersSubText`,
    defaultMessage: 'Invite by email',
  },
  referModalAmbassadorClientText: {
    id: `${scope}.referModalAmbassadorClientText`,
    defaultMessage:
      'Simply invite the decision makers at the comanies actively looking to hire remote talents. When they build their teams through our platform, you will earn exciting rewards including cash payouts.',
  },
  subjectToOurTerms: {
    id: `${scope}.subjectToOurTerms`,
    defaultMessage: 'Subject to our terms',
  },
  backTonviteLink: {
    id: `${scope}.backTonviteLink`,
    defaultMessage: 'Back to invite link',
  },
  inviteMailHeadingForRecruiter: {
    id: `${scope}.inviteMailHeadingForRecruiter`,
    defaultMessage: 'Refer and earn cash per referral.',
  },
  inviteMailContentForRecruiter: {
    id: `${scope}.inviteMailContentForRecruiter`,
    defaultMessage: 'Simply invite your talent to sign-up to Notchup using their email address.',
  },
  inviteMailContentForClientTab: {
    id: `${scope}.inviteMailContentForClientTab`,
    defaultMessage: 'Simply invite your clients to sign-up to Notchup using their email address.',
  },
  jobBriefDetailReferEarnContent: {
    id: `${scope}.jobBriefDetailReferEarnContent`,
    defaultMessage: 'Know someone who match the required skills?',
  },
});
