/**
 * This class used for Store value on localStorage of browser.
 * @author Innovify
 */

// Encoding string to base64 with special characters
function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.

  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      // eslint-disable-next-line prefer-template
      return String.fromCharCode('0x' + p1);
    }),
  );
}

// Decoding base64  to original string
function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      // eslint-disable-next-line prefer-template
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
}

const StorageService = {
  /**
   * check value exists on localStorage of browser.
   * @author Innovify
   * @Developer Ankit Gujarati
   */
  exists(key) {
    return this.get(key);
  },
  /**
   * set value from localStorage of browser.
   * @author Innovify
   * @Developer Ankit Gujarati
   */
  set(key, value, opts = {}) {
    let storedValue = value;
    if (opts.stringify) {
      storedValue = JSON.stringify(storedValue);
    }
    if (opts.hash) {
      storedValue = b64EncodeUnicode(storedValue);
    }
    try {
      if (opts.keepMe) {
        const d = new Date();
        d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${key}=${JSON.stringify({
          opts,
          body: storedValue,
        })};${expires};path=/`;
      } else {
        document.cookie = `${key}=${JSON.stringify({
          opts,
          body: storedValue,
        })};path=/`;
      }
    } catch (err) {
      throw err;
    }
  },
  /**
   * get value from localStorage of browser.
   * @author Innovify
   */
  get(key) {
    // if (!this.exists(key)) return false;
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const data = JSON.parse(c.substring(name.length, c.length));
        let { body } = data;
        if (data.opts && data.opts.hash) {
          body = b64DecodeUnicode(body);
        }
        if (data.opts && data.opts.stringify) {
          body = JSON.parse(body);
        }
        return body;
      }
    }
    return false;
    /* const data = JSON.parse(localStorage.getItem(key));
    let { body } = data;
    if (data.opts && data.opts.hash) body = base.decode(body);
    if (data.opts && data.opts.stringify) body = JSON.parse(body);
    return body; */
  },
  /**
   * delete value from localStorage of browser.
   * @author Innovify
   */
  delete(key, opts = {}) {
    const d = new Date();
    d.setTime(d.getTime() + 0);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=${JSON.stringify({
      opts,
      body: '',
    })};${expires};path=/`;
    // localStorage.removeItem(key);
  },
  /**
   * clear localStorage of browser.
   * @author Innovify
   */
  clear() {
    this.delete('profileId');
    this.delete('signupStep');
    this.delete('apiSignupStep');
    this.delete('userVersion');
    this.delete('user');
    this.delete('recentSearch');
    this.delete('jwtToken');
    this.delete('location');
    this.delete('unAuthorize');
    this.delete('userPhoneCountry');
    this.delete('registerType');
    this.delete('userEmail');
    this.delete('userType');
    this.delete('agencyLogo');
    this.delete('pageNumber');
    this.delete('userPhoneNumber');
    this.delete('profilePicture');
    this.delete('firstName');
    this.delete('lastName');
    this.delete('userPhone');
    this.delete('Notification');
    this.delete('NotificationType');
    this.delete('talentProjectPageNumber');
    this.delete('talentProjectFilter');
    this.delete('clientProjectPageNumber');
    this.delete('clientProjectStatus');
    this.delete('isPaymentSkipped');
    this.delete('agencyMyTeamPageNumber');
    this.delete('adminAgencyPageNumber');
    this.delete('adminAgencyFilterObject');
    this.delete('adminBriefDetailID');
    this.delete('clientsFilterObject');
    this.delete('clientsPageNumber');
    this.delete('recruitersPageNumber');
    this.delete('adminAmbassadorSearch');
    this.delete('interviewFilterObject');
    this.delete('interviewPageNumber');
    this.delete('adminProjectDetailsBriefPageNumber');
    this.delete('projectFilterObject');
    this.delete('projectPageNumber');
    this.delete('talentFilterObject');
    this.delete('talentPageNumber');
    this.delete('agencyQuotePage');
    this.delete('selectedRoleType');
    this.delete('filterObject');
    this.delete('proxyType');
    this.delete('proxyToken');
    this.delete('talentBriefPage');
    this.delete('talentBriefFilter');
    this.delete('clientBriefDetailsPageNumber');
    this.delete('clientBriefPage');
    this.delete('clientBriefFilter');
    this.delete('clientID');
    this.delete('adminBriefPage');
    this.delete('adminTalentSearch');
    this.delete('adminAgencySearch');
    this.delete('adminClientSearch');
    this.delete('adminRecruiterSearch');
    this.delete('adminAmbassadorSearch');
    this.delete('isLoginViaAdmin');
    this.delete('userBillingDetailsObj');
    this.delete('hamburgerToggle');
    this.delete('RecruiterBriefPage');
    this.delete('recruiterId');
    this.delete('ambassadorId');
    this.delete('timesheetPage');
    this.delete('subscribed');
    this.delete('subscriptionPlan');
    this.delete('teamFromDashboard');
    this.delete('restrictedActions');
    this.delete('validForm');
    this.delete('isSuperAdmin');
    this.delete('draftJobPost');
    this.delete('userBillingDetails');
    this.delete('teamsPageNumber');
    this.delete('talentsPageNumber');
    this.delete('talentTeamSort');
    this.delete('talentTeamPageNumber');
    this.delete('talentProjectDetailsBriefPageNumber');
    this.delete('talentPrimaryRole');
    this.delete('talentJobListPageNumber');
    this.delete('talentId');
    this.delete('subscriptionPlanDetails');
    this.delete('stepCompleted');
    this.delete('projectDetailsJobBriefPageNumber');
    this.delete('projectDetailId');
    this.delete('employerUsersPageNumber');
    this.delete('draftJobPostId');
    this.delete('clientTeamTalentSort');
    this.delete('clientTalentPageNumber');
    this.delete('clientProjectSort');
    this.delete('clientBrand');
    this.delete('appVersion');
    this.delete('ambassadorsPageNumber');
    this.delete('ambassadorClientsSearch');
    this.delete('agencyProjectsSort');
    this.delete('agencyProjectsPageNumber');
    this.delete('agencyProjectsFilter');
    this.delete('adminProjectSearch');
    this.delete('adminInterviewSearch');
    this.delete('adminBriefSort');
    this.delete('ONE_TIME_SUBSCRIPTION_PRICE');
    this.delete('ClientTimeZone');
    this.delete('AgencyBriefPage');
    this.delete('AgencyBriefFilter');
    this.delete('clientCompanyDetails');
    this.delete('clientCompany');
    this.delete('clientCompanyName');
    this.delete('isClaimer');
    this.delete('clientUserRole');
    this.delete('clientProjectPageNumber');
    this.delete('clientProjectStatus');
    this.delete('clientProjectSort');
    this.delete('clientInterviewRequestsPageNumber');
    this.delete('adminInterviewRequestsPageNumber');
    this.delete('clientInterviewRequestsFilterObject');
    this.delete('adminInterviewRequestsFilterObject');
    this.delete('clientInterviewRequestsSearch');
    this.delete('adminInterviewRequestsSearch');
    this.delete('talentInterviewRequestsPageNumber');
    this.delete('talentInterviewRequestsFilterObject');
    this.delete('talentInterviewRequestsSearch');
    this.delete('adminClientSearch');
    this.delete('clientsPageNumber');
    this.delete('clientsFilterObject');
  },
};

export default StorageService;
