import React, { useEffect, useState } from 'react';
import EmptyScreenCard from 'components/EmptyScreens/EmptyScreenCard';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { PrivateGrid, H3, Card, ToastifyMessage, Button, Badge, P } from 'components';
import Content from 'components/Content';
import DataTable from 'react-data-table-component';
import containerMessage from 'containers/messages';
import { SortSection } from 'containers/Talent/MyProjects/styles';
import { connect } from 'react-redux';
import SearchComponent from 'components/SearchComponent';
import { VALIDATION } from 'utils/constants';
import { textItemRender } from 'containers/TalentListingPage/utils';
import { localeInfo } from 'containers/TalentListingPage/constants';
import { SelectBox } from 'containers/Admin/Projects/styles';
import SVG from 'react-inlinesvg';
import { reduxForm, Field } from 'redux-form';
import injectSaga from 'utils/injectSaga';
import { debounce, get } from 'lodash';
import Pagination from 'rc-pagination';
import request from 'utils/request';
import { getDynamicInterviewBadgeClass, getHash, redirectTo } from 'containers/App/utils';
import history from 'utils/history';
import {
  API_URL,
  customStyles,
  talentEmptyIcon,
  eyeIcon,
  shareIcon,
  plusIcon,
  downArrowIcon,
  userProfileIcon,
} from 'containers/App/constants';
import { formatAURL } from 'utils/Helper';
import { toast } from 'react-toastify';
import { ActionIcon } from 'containers/MyProfilePage/styles';
import Selects from 'components/Selects';
import { LinearIndeterminate } from '../Talents/constants';
import { HrLine, SortDropdown, ClientNameWithProfile } from './styles';
import { columns, sortFilterCompanyArray, key } from './constants';
import messages from './messages';
import * as actions from './actions';
import saga from './saga';
import { SortDiv } from 'components/SortComponent/styles';
import 'rc-pagination/assets/index.css';

export const imgError = (e) => {
  e.target.onerror = null;
  e.target.src = userProfileIcon;
};

function AdminCompanies(props) {
  const [currentSort, setCurrentSort] = useState(sortFilterCompanyArray[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [list, setList] = useState([]);
  const [search, setSearch] = useState('');
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    const hash = getHash(window.location, 'SEARCH_FILTER_COMPANY');
    history.push(`${window.location.pathname}${hash}`);
  }, []);

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const debounceOnChange = debounce(handleSearchChange, 500);

  const handleChangeStatus = (compId, selectedVal) => {
    const { onChangeStatus } = props;
    const data = { id: compId, status: selectedVal?.value };
    onChangeStatus(data, handleSuccess);
  };

  const handleSuccess = () => {
    loadCompanyList(pageNum);
  };

  const handleSort = (e) => {
    setCurrentSort(e);
  };

  const handleSortChange = (selectedSort) => {
    handleSort(selectedSort);
  };

  const dropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleShare = (brand) => {
    const origin = window.location.origin;
    const url = `${origin}/${formatAURL(brand)}`;
    navigator.clipboard.writeText(url);
    toast.success(<ToastifyMessage message="URL copied to clipboard" type="success" />, {
      className: 'Toast-success',
    });
  };

  const handleViewCompany = (brand) => {
    const origin = window.location.origin;
    const url = `${origin}/${formatAURL(brand)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // eslint-disable-next-line no-shadow
  const loadCompanyList = async (pageNum) => {
    try {
      setIsListLoading(true);
      const data = { method: 'GET' };
      let requestURL = `${API_URL}/company/list?page=${pageNum}&limit=10&status=${encodeURIComponent(currentSort.value)}`;
      requestURL += search ? `&q=${encodeURIComponent(search)}` : '';

      const res = await request(requestURL, data);
      setPaginationData(get(res, 'data', {}));
      const array = [];
      const temp = res?.data?.page * res?.data?.limit - (res?.data?.limit - 1);

      res?.data?.docs?.forEach((ele, index) => {
        const number = temp + index;
        const brand = get(ele, 'brand', '');
        const companyName = get(ele, 'name', '-');
        const clientName = get(ele, 'clientName', '');
        const email = get(ele, 'email', '-');
        const subscription = get(ele, 'subscription', '-');
        const profilePicture = get(ele, 'clientLogo', '');

        const inviteUsers = (
          <Button
            type="button"
            className="btn btn-icon btn-link text-primary mb-auto"
            onClick={() => redirectTo(null, '/admin/add-company/invite', { data: ele })}
          >
            Invite
          </Button>
        );
        const name = (
          <ClientNameWithProfile>
            <img src={`${profilePicture}?_t=${new Date().getTime()}`} alt="user profile" onError={imgError} />
            <span className="ms-2">{clientName}</span>
          </ClientNameWithProfile>
        );
        const status = <Badge className={`${getDynamicInterviewBadgeClass(get(ele, 'status'))} badge-sm`}>{get(ele, 'status')}</Badge>;
        const action = (
          <div className="d-flex">
            <SelectBox className="input-sm small-arrow me-3">
              <Field
                name="status"
                type="text"
                component={Selects}
                defaultValue={[
                  { label: 'Active', value: 1 },
                  { label: 'Suspended', value: 2 },
                ].filter((item) => item.label === get(ele, 'status'))}
                menuPortalTarget={document.body}
                searchable={false}
                placeHolder="Select"
                options={[
                  { label: 'Active', value: 1 },
                  { label: 'Suspended', value: 2 },
                ].map((item) => ({
                  label: `${item.label}`,
                  value: item.value,
                }))}
                onChange={(e) => handleChangeStatus(ele?._id, e)}
              />
            </SelectBox>
            <ActionIcon type="button" onClick={() => handleShare(brand)}>
              <SVG src={shareIcon} />
            </ActionIcon>
            <ActionIcon type="button" onClick={() => handleViewCompany(brand)}>
              <SVG src={eyeIcon} />
            </ActionIcon>
          </div>
        );
        array.push({ number, companyName, name, email, inviteUsers, status, action, subscription });
      });
      setList(array);
      setIsListLoading(false);
    } catch (error) {
      setIsListLoading(false);
      toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
    }
  };

  useEffect(() => {
    loadCompanyList(pageNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, currentSort]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{messages.title.defaultMessage}</title>
        <meta name="description" content={messages.metaTitle.defaultMessage} />
      </Helmet>
      <Content className="mt-4 mt-sm-0">
        <PrivateGrid>
          <Row>
            <Col lg={12}>
              <div className="d-flex flex-column flex-md-row justify-content-between mb-3">
                <div className="d-flex align-items-center me-3">
                  <H3>{messages.heading.defaultMessage}</H3>
                  <Badge className="ms-1 primary badge-sm">
                    <P className="p14 mb-0" lineHeight="16">
                      {paginationData?.totalDocs}
                    </P>
                  </Badge>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
                  <SearchComponent
                    handleSearchChange={(e) => {
                      debounceOnChange(e);
                    }}
                    placeholder={containerMessage.searchPlaceholder.defaultMessage}
                    className="mt-3 mt-md-0"
                  />
                  <SortSection className="ms-0 ms-md-3 me-3 mt-3 mt-md-0">
                    <SortDiv>
                      <SortDropdown className="me-0 align-items-center sort-dropdown" isOpen={dropdownOpen} toggle={() => dropdownToggle()}>
                        <DropdownToggle className={`opacity-100 selected-sort ms-0`}>
                          {currentSort ? currentSort.label : messages.sort.defaultMessage}
                          <SVG className="down-arrow" src={downArrowIcon} />
                        </DropdownToggle>
                        <DropdownMenu right>
                          {sortFilterCompanyArray.map((item) => (
                            <DropdownItem
                              key={item.value}
                              className={currentSort.value === item.value ? 'active' : ''}
                              onClick={() => handleSortChange(item)}
                            >
                              {item.label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </SortDropdown>
                    </SortDiv>
                  </SortSection>
                  <HrLine className="vertical d-none d-md-block" />
                  <Button className="btn-primary btn-sm btn-plus ms-0 ms-md-3 w-sm-100" onClick={() => history.push('/admin/add-company')}>
                    <SVG className="me-1" src={plusIcon} />
                    <FormattedMessage {...messages.addCompanies} />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </PrivateGrid>
        <Card className="p-0">
          <DataTable
            noHeader
            columns={columns}
            data={list}
            totalRows={1}
            customStyles={customStyles}
            direction="ltr"
            progressPending={isListLoading}
            progressComponent={<LinearIndeterminate />}
            highlightOnHover
            paginationComponentOptions={{ noRowsPerPage: true }}
            noDataComponent={
              <>
                <EmptyScreenCard
                  cardTitle={messages.noRecordTitle}
                  cardDescription={messages.noRecordDesc}
                  icon={talentEmptyIcon}
                  textContainersClasses="text-muted d-flex flex-column align-items-start align-items-sm-center mt-3 mb-3 text-left text-md-center"
                  cardClasses="d-flex flex-column align-items-start align-items-sm-center p-40 mb-0 rounded border-none"
                >
                  <Button className="btn-primary btn-sm btn-plus mt-3 ms-3" onClick={() => history.push('/admin/add-company')}>
                    <SVG className="me-1" src={plusIcon} />
                    <FormattedMessage {...messages.addCompanies} />
                  </Button>
                </EmptyScreenCard>
              </>
            }
            overflowY
            overflowYOffset="70px"
          />
        </Card>
        {paginationData?.totalDocs > 20 ? (
          <Pagination
            total={paginationData?.totalDocs}
            className="ant-pagination"
            current={paginationData?.page}
            defaultPageSize={paginationData?.limit}
            onChange={loadCompanyList}
            itemRender={textItemRender}
            locale={localeInfo}
          />
        ) : (
          ''
        )}
      </Content>
    </React.Fragment>
  );
}

export function mapDispatchToProps(dispatch) {
  return {
    onChangeStatus: (data, onSuccess) => dispatch(actions.changeStatus(data, onSuccess)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
const withSaga = injectSaga({ key, saga });

export default compose(
  withSaga,
  withConnect,
  reduxForm({
    form: key,
    touchOnChange: true,
  }),
)(AdminCompanies);
