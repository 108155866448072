/*
 * SubscriptionModal Messages
 *
 * This contains all the text for the SubscriptionModal component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.containers.SubscriptionModal';

export default defineMessages({
  titleSelectPlan: {
    id: `${scope}.titleSelectPlan`,
    defaultMessage: 'Continue with free',
  },
  titleYearly: {
    id: `${scope}.titleYearly`,
    defaultMessage: 'Annually',
  },
  freePlan: {
    id: `${scope}.freePlan`,
    defaultMessage: 'Free',
  },
  btnSelectPlan: {
    id: `${scope}.btnSelectPlan`,
    defaultMessage: 'Select this plan',
  },
  btnSeeFeatures: {
    id: `${scope}.btnSeeFeatures`,
    defaultMessage: 'See all features',
  },
  bespokePlan: {
    id: `${scope}.bespokePlan`,
    defaultMessage: 'Custom Solution',
  },
  btnContactSales: {
    id: `${scope}.btnContactSales`,
    defaultMessage: 'Contact sales',
  },
  btnContinueFreePlan: {
    id: `${scope}.btnContinueFreePlan`,
    defaultMessage: 'Cancel & continue with free plan',
  },
  additionalJobText: {
    id: `${scope}.additionalJobText`,
    defaultMessage: 'Additional job brief',
  },
  payNowBtn: {
    id: `${scope}.payNowBtn`,
    defaultMessage: 'Pay now',
  },
  upgradToSuitablePlan: {
    id: `${scope}.upgradToSuitablePlan`,
    defaultMessage: 'or upgrade to any suitable plan',
  },
  enterprisePlanTitle: {
    id: `${scope}.enterprisePlanTitle`,
    defaultMessage: 'Enterprise',
  },
  btnGrowthPlan: {
    id: `${scope}.btnGrowthPlan`,
    defaultMessage: 'Upgrade to plus',
  },
  btnEnterprisePlan: {
    id: `${scope}.btnEnterprisePlan`,
    defaultMessage: 'Contact us',
  },
  starterPlanContent: {
    id: `${scope}.starterPlanContent`,
    defaultMessage: '+ 4% Talent Sourcing fees',
  },
  starterPlanText: {
    id: `${scope}.starterPlanText`,
    defaultMessage: '+ 5% Remote Talent Payout fees',
  },
  growthPlanContent: {
    id: `${scope}.growthPlanContent`,
    defaultMessage: '+ 0% Talent Sourcing fees',
  },
  growthPlanText: {
    id: `${scope}.growthPlanText`,
    defaultMessage: '+ 4% Remote Talent Payout fees',
  },
  limitTitle: {
    id: `${scope}.limitTitle`,
    defaultMessage: "What's included",
  },
  whatsIncludedText: {
    id: `${scope}.whatsIncludedText`,
    defaultMessage: "What's included",
  },
  starterPlanText1: {
    id: `${scope}.starterPlanText1`,
    defaultMessage: '1 Team / Project',
  },
  starterPlanText2: {
    id: `${scope}.starterPlanText2`,
    defaultMessage: '5 free job postings',
  },
  starterPlanText3: {
    id: `${scope}.starterPlanText3`,
    defaultMessage: '5 concurrent talents hired',
  },
  starterPlanText4: {
    id: `${scope}.starterPlanText4`,
    defaultMessage: '5 competitions hosted',
  },
  starterPlanText5: {
    id: `${scope}.starterPlanText5`,
    defaultMessage: 'Unlimited number of user',
  },
  growthPlanText1: {
    id: `${scope}.growthPlanText1`,
    defaultMessage: '3 Teams / Projects',
  },
  growthPlanText2: {
    id: `${scope}.growthPlanText2`,
    defaultMessage: '15 Free Job Postings',
  },
  growthPlanText3: {
    id: `${scope}.growthPlanText3`,
    defaultMessage: '15 concurrent talents hired',
  },
  growthPlanText4: {
    id: `${scope}.growthPlanText4`,
    defaultMessage: '15 Hosted Competitions',
  },
  enterprisePlanText1: {
    id: `${scope}.enterprisePlanText1`,
    defaultMessage: 'Unlimited Job Postings',
  },
  enterprisePlanText2: {
    id: `${scope}.enterprisePlanText2`,
    defaultMessage: 'Unlimited Teams/Projects',
  },
  enterprisePlanText3: {
    id: `${scope}.enterprisePlanText3`,
    defaultMessage: 'Unlimited Talents/Teams Search',
  },
  enterprisePlanText4: {
    id: `${scope}.enterprisePlanText4`,
    defaultMessage: 'Unlimited Hosted Competitions',
  },
  enterprisePlanSubContent: {
    id: `${scope}.enterprisePlanSubContent`,
    defaultMessage: 'Customised',
  },
  normalUpgradeTitle: {
    id: `${scope}.normalUpgradeTitle`,
    defaultMessage: 'Our plans to unlock your growth',
  },
  forceUpgradeTitle: {
    id: `${scope}.forceUpgradeTitle`,
    defaultMessage: 'You need to upgrade',
  },
  normalUpgradeDescription: {
    id: `${scope}.normalUpgradeDescription`,
    defaultMessage:
      'Please select one of the below plan for you to create more projects, post more jobs or hire more talents to accelerate your growth.',
  },
  forceUpgradeDescription: {
    id: `${scope}.forceUpgradeDescription`,
    defaultMessage:
      'You have reached the limits of your current plan. Please upgrade to a higher plan for you to create more projects, post more jobs or hire more talents. Alternatively, you can simply pay for additional job post without upgrading your current plan.',
  },
});
