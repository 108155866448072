import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink, Nav } from 'reactstrap';
import SVG from 'react-inlinesvg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import get from 'lodash/get';
import { userExists, getUserRegisterType } from 'utils/Helper';
import Emitter from 'utils/emitter';
import { defaultProps, propTypes } from 'containers/proptypes';
import { Badge, Button, P, SVGIcon } from 'components';
import Logo from 'components/Brand';
import {
  API_URL,
  DETAILS,
  USER,
  calendarWithTimeIcon,
  clientsIcon,
  closeIcon,
  contractThinIcon,
  perksandpartnershipIcon,
  postJobsIcon,
  projectsIcon,
  searchIcon,
  winnerCupThin,
} from 'containers/App/constants';
import { FixedSidebar, TourItemContainer } from './styles';
import { clientNavPages, adminNavPages } from './constants';
import messages from './messages';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Tour from 'reactour';
import { primaryNew } from 'themes/variables';
import request from 'utils/request';
import { useAppDispatch, useAppSelector } from 'hooks';
import { isEmpty } from 'lodash';
import { setClientDetails, setOpenMenu, setShowWalkThrough } from 'containers/App/reducer';
import { connect } from 'react-redux';

const opositeSide = {
  top: 'bottom',
  bottom: 'top',
  right: 'left',
  left: 'right',
};

export const style = {
  width: 341,
  // height: 227,
  padding: 25,
  ...doArrow('right', 'top', 'top'),
};

export function doArrow(position, verticalAlign, horizontalAlign) {
  if (!position || position === 'custom') {
    return {};
  }

  const width = 16;
  const height = 12;
  const color = 'white';
  const isVertical = position === 'top' || position === 'bottom';
  const spaceFromSide = 10;

  const obj = {
    [`--rtp-arrow-${isVertical ? opositeSide[horizontalAlign] : verticalAlign}`]: `${height + spaceFromSide}px`,
    [`--rtp-arrow-${opositeSide[position]}`]: `${-height + 2}px`,
    [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${width / 2}px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${width / 2}px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
  };
  return obj;
}

const onTourComplete = async () => {
  const apiCallData = {
    method: 'PUT',
    body: {
      tourCompleted: true,
    },
  };

  const requestURL = `${API_URL}${USER}/set-flags`;
  await request(requestURL, apiCallData);
};

export const TourFooter = ({ step, steps, close, goTo, onClick }) => (
  <div className="d-flex justify-content-between mt-3">
    <Button className="btn btn-link skip" onClick={close}>
      {step !== steps?.length && ` Skip all`}
    </Button>
    <Button className="btn btn-link" onClick={onClick}>
      {step === steps?.length ? 'Done' : 'Next'}
    </Button>
  </div>
);

export const TourHeader = ({ icon, step, steps, title = '', desc = '' }) => (
  <>
    <div className="d-flex justify-content-between">
      <SVGIcon src={icon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
      <P className="p14 m-0 p-0" opacityVal="0.5" lineHeight="22.9">
        {step}/{steps?.length}
      </P>
    </div>

    <P className="p20 m-0 p-0 mt-3">{title}</P>
    <P className="p16 m-0 p-0 mt-3" opacityVal="0.7" lineHeight="22">
      {desc}
    </P>
  </>
);

export const Step = ({ step, steps, close, goTo, title, desc, icon, onClick }) => (
  <TourItemContainer data-testid="TourItemContainer">
    <TourHeader
      {...{
        step,
        icon,
        steps,
        title,
        desc,
        onClick,
      }}
    />
    <TourFooter
      {...{
        step,
        steps,
        close,
        goTo,
        onClick,
      }}
    />
  </TourItemContainer>
);

const steps = ({ tourUserId, fetchUserDetails, dispatch }) => [
  {
    style,
    selector: '#client-listings',
    content: ({ close, goTo, step }) => (
      <Step
        {...{
          icon: searchIcon,
          title: 'Find teams or talents',
          desc: 'Here you will be able to find pre-build teams and talents listed on Notchup from all over the world.',
          close,
          dispatch,
          fetchUserDetails,
          goTo,
          step,
          tourUserId,
          steps: steps({ tourUserId }),
          onClick: step === steps({ tourUserId })?.length ? close : () => goTo(step), // this is to respect steps array starting from 0
        }}
      />
    ),
  },
  {
    style,
    selector: '#client-competitions',
    content: ({ close, goTo, step }) => (
      <Step
        {...{
          icon: winnerCupThin,
          title: 'Competitions',
          desc: 'Tap into our global talent pool to uncover exceptional talents through tailored hackathons and competitions hosted on our platform.',
          close,
          dispatch,
          fetchUserDetails,
          goTo,
          step,
          tourUserId,
          steps: steps({ tourUserId }),
          onClick: step === steps({ tourUserId })?.length ? close : () => goTo(step),
        }}
      />
    ),
  },
  {
    style,
    selector: '#client-talents',
    content: ({ close, goTo, step }) => (
      <Step
        {...{
          icon: clientsIcon,
          title: 'Talents',
          desc: 'Discover and access our pool of top remote, pre-vetted talents and teams available to work on your project on a flexible basis.',
          close,
          dispatch,
          fetchUserDetails,
          goTo,
          step,
          tourUserId,
          steps: steps({ tourUserId }),
          onClick: step === steps({ tourUserId })?.length ? close : () => goTo(step),
        }}
      />
    ),
  },
  {
    style,
    selector: '#client-timesheets',
    content: ({ close, goTo, step }) => (
      <Step
        {...{
          icon: calendarWithTimeIcon,
          title: 'Timesheets',
          desc: 'Conveniently manage talent timesheets approvals or rejections for live projects with our intuitive platform, accessible round the clock.',
          close,
          dispatch,
          fetchUserDetails,
          goTo,
          step,
          tourUserId,
          steps: steps({ tourUserId }),
          onClick: step === steps({ tourUserId })?.length ? close : () => goTo(step),
        }}
      />
    ),
  },
  {
    style,
    selector: '#client-perks',
    content: ({ close, goTo, step }) => (
      <Step
        {...{
          icon: perksandpartnershipIcon,
          title: 'Perks',
          desc: 'Attract and empower long-term remote talents and teams with tailored perks and benefits such as flexible schedules, health insurance, home office allowances, and more.',
          close,
          dispatch,
          fetchUserDetails,
          goTo,
          step,
          tourUserId,
          steps: steps({ tourUserId }),
          onClick: () => {
            if (document.querySelector('#client-sidebar')?.classList.contains('sidebar-open')) {
              document.querySelector('.mobile_close')?.click();
            }
            if (window.innerWidth <= 768) {
              window.scrollTo(0, document.body.scrollHeight / 9.5);
            } else {
              window.scrollTo(0, document.body.scrollHeight / 4);
            }
            setTimeout(() => (step === steps({ tourUserId })?.length ? close() : (() => goTo(step))()), 500);
          },
        }}
      />
    ),
  },
  {
    style,
    position: 'top',
    selector: '#all-projects',
    content: ({ close, goTo, step }) => (
      <Step
        {...{
          icon: projectsIcon,
          title: 'Your projects',
          desc: 'This is the number of projects you have in your account. Let’s outline your project — and start building your development team around its objectives.',
          close,
          dispatch,
          fetchUserDetails,
          goTo,
          step,
          tourUserId,
          steps: steps({ tourUserId }),
          onClick: () => {
            if (window.innerWidth <= 768) {
              window.scrollTo(0, document.body.scrollHeight / 8.5);
              return setTimeout(() => (step === steps({ tourUserId })?.length ? close() : (() => goTo(step))()), 500);
            }
            return (step === steps({ tourUserId })?.length ? close : () => goTo(step))();
          },
        }}
      />
    ),
  },
  {
    style,
    position: 'top',
    selector: '#jobs-live',
    content: ({ close, goTo, step }) => (
      <Step
        {...{
          icon: postJobsIcon,
          title: 'Jobs live',
          desc: 'Get your current job openings in front of top tech talent and teams, with our jobs tool that allows you post and publish roles in record time.',
          close,
          dispatch,
          fetchUserDetails,
          goTo,
          step,
          tourUserId,
          steps: steps({ tourUserId }),
          onClick: () => {
            if (window.innerWidth <= 768) {
              window.scrollTo(0, document.body.scrollHeight / 3.5);
              return setTimeout(() => (step === steps({ tourUserId })?.length ? close() : (() => goTo(step))()), 500);
            }
            return (step === steps({ tourUserId })?.length ? close : () => goTo(step))();
          },
        }}
      />
    ),
  },
  {
    style,
    position: 'top',
    selector: '#applications-received',
    content: ({ close, goTo, step }) => (
      <Step
        {...{
          icon: contractThinIcon,
          title: 'Applications received',
          desc: 'View, manage and track talent application profiles, status and communications on the platform with our centralised dashboard in real-time. ',
          close,
          dispatch,
          fetchUserDetails,
          goTo,
          step,
          tourUserId,
          steps: steps({ tourUserId }),
          onClick: () => {
            if (window.innerWidth <= 768) {
              window.scrollTo(0, document.body.scrollHeight / 2.5);
              return setTimeout(() => (step === steps({ tourUserId })?.length ? close() : (() => goTo(step))()), 500);
            }
            return (step === steps({ tourUserId })?.length ? close : () => goTo(step))();
          },
        }}
      />
    ),
  },
  {
    style,
    position: 'top',
    selector: '#talents-hired',
    content: ({ close, goTo, step }) => (
      <Step
        {...{
          icon: clientsIcon,
          title: 'Talents hired',
          desc: 'Get end-to-end view of your hired talents on our intuitive dashboard. Monitor talent workflow, timesheets, KPIs and other key metrics.',
          close,
          dispatch,
          fetchUserDetails,
          goTo,
          step,
          tourUserId,
          steps: steps({ tourUserId }),
          onClick: step === steps({ tourUserId })?.length ? close : () => goTo(step),
        }}
      />
    ),
  },
];

export const DashboardTour = ({ isOpen, shouldOpen }) => {
  const dispatch = useAppDispatch();
  const showWalkThrough = useAppSelector((state) => state.global.showWalkThrough);
  const clientDetails = useAppSelector((state) => state.global.clientDetails);
  const [render, setRender] = useState(false);

  const tourUserId = clientDetails?._id || '';

  useEffect(() => {
    setRender(showWalkThrough);
  }, [showWalkThrough]);

  useEffect(() => {
    if (isEmpty(clientDetails)) {
      setRender(false);
    } else {
      const tourComplete = clientDetails?.flags?.tourCompleted;
      const onboardingComplete = clientDetails?.flags?.onboardingCompleted;
      if (!tourComplete && onboardingComplete) {
        // if (isOpen && shouldOpen) {
        if (isOpen && shouldOpen && !clientDetails?.adminId) {
          if (document.querySelector('#client-sidebar')?.classList.contains('sidebar-close')) {
            document.querySelector('.navbar-toggler')?.click();
          }
          // this to check for product tour is modal tour is completed
          window.scrollTo(0, 0);
          setTimeout(() => {
            document.querySelector('body').style.overflow = 'hidden';
            setRender(true);
          }, 500);
        }
      }
    }
  }, [clientDetails, isOpen, shouldOpen]);

  const fetchUserDetails = async () => {
    dispatch(setShowWalkThrough(false));
    document.querySelector('body').style.overflow = '';
    onTourComplete();

    const data = {
      method: 'GET',
    };
    const requestURL = `${API_URL}${USER}${DETAILS}`;
    request(requestURL, data).then((response) => {
      dispatch(setClientDetails(response?.data));
    });
  };

  return (
    <Tour
      steps={steps({ tourUserId, fetchUserDetails, dispatch })}
      isOpen={render}
      disableDotsNavigation
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNumber={false}
      rounded={10}
      startAt={0}
      onRequestClose={() => {
        setRender(false);
        fetchUserDetails();
        if (document.querySelector('#client-sidebar')?.classList.contains('sidebar-open')) {
          document.querySelector('.navbar-toggler')?.click();
        }
      }}
    />
  );
};

export class ClientSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props?.openMenu,
      isSignIn: userExists(),
      roleType: getUserRegisterType(),
      badgeConfiguration: {},
    };
  }

  menToggle = () => {
    this.setState(
      (prevState) => ({ isOpen: !prevState.isOpen }),
      () => {
        const { isOpen } = this.state;
        Emitter.emit(Emitter.EVENTS.HAMBURGER_TOGGLE, isOpen);
      },
    );
  };

  setConstructor = () => {
    const stateData = { isSignIn: userExists(), roleType: getUserRegisterType() };
    this.setState({ ...stateData });
  };

  componentDidMount() {
    this.setConstructor();

    Emitter.on('proxyLoginClient', (proxyLoginClient) => {
      if (proxyLoginClient) {
        this.setConstructor();
      }
    });

    Emitter.on('proxyBackToAdmin', (proxyBackToAdmin) => {
      if (proxyBackToAdmin) {
        this.setConstructor();
      }
    });

    Emitter.on('badgeConfigurationUpdated', (badgeConfigurationUpdated) => {
      this.setState({ badgeConfiguration: badgeConfigurationUpdated });
    });

    Emitter.on(Emitter.EVENTS.HAMBURGER_TOGGLE, (isOpen) => {
      this.setState({ isOpen });
    });
  }

  componentWillUnmount() {
    Emitter.off('proxyLoginClient');
    Emitter.off('proxyBackToAdmin');
    Emitter.off('badgeConfigurationUpdated');
    Emitter.off(Emitter.EVENTS.HAMBURGER_TOGGLE);
  }

  renderAdminSideBar = (pathName, props) => (
    <>
      {adminNavPages.map((navItem) => (
        <NavItem key={navItem.title}>
          <NavLink
            onClick={() => props.dispatch(setOpenMenu(false))}
            to={navItem.pathName}
            className={navItem.paths.some((i) => i === pathName) ? 'active' : ''}
            tag={Link}
            title={navItem.title}
          >
            <span className={`${navItem.key === 'referrals' ? 'referral-icon' : ''} icon`}>
              <SVG src={navItem.icon} />
            </span>
            <span className="menu-option">{navItem.title}</span>
          </NavLink>
        </NavItem>
      ))}
    </>
  );

  renderClientSideBar = (pathName, props) => (
    <>
      {clientNavPages.map((navItem) => (
        <NavItem key={navItem.title}>
          <NavLink
            onClick={() => props.dispatch(setOpenMenu(false))}
            to={navItem.pathName}
            className={navItem.paths.some((i) => i === pathName) ? 'active' : ''}
            tag={Link}
            title={navItem.title}
          >
            <div className="d-flex align-items-center" id={navItem?.pathName.substring(1).replaceAll('/', '-')}>
              <span className="icon">
                <SVG src={navItem.icon} />
                {this.renderNewBadge(navItem)}
              </span>
              <span className="menu-option">
                {navItem.title}
                {this.renderNewBadge(navItem)}
              </span>
            </div>
          </NavLink>
        </NavItem>
      ))}
    </>
  );

  renderNewBadge = (navItem) => {
    const { badgeConfiguration } = this.state;
    return (
      <>
        {navItem.isBadgeConfig ? (
          <>
            {badgeConfiguration[navItem.badgeKey] && (
              <Badge className="success new-badge font-0">{messages.newBadgeText.defaultMessage}</Badge>
            )}
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  render() {
    const { location } = this.props;
    const { roleType, isSignIn, isOpen } = this.state;
    const pathName = get(location, 'pathname');

    return (
      <>
        <FixedSidebar id="client-sidebar" className={isOpen ? 'sidebar-open' : 'sidebar-close'}>
          <PerfectScrollbar>
            <div className="d-none d-md-block">
              <Logo />
            </div>
            <div className="d-flex justify-content-end d-md-none pe-4">
              {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */}
              {/* eslint-disable jsx-a11y/click-events-have-key-events  */}
              <img
                className="mobile_close"
                src={closeIcon}
                width="12.5"
                height="12.5"
                onClick={this.menToggle}
                alt="close"
                data-testid="mobile_close"
              />
            </div>
            <Nav>
              {isSignIn && roleType === 'client' && this.renderClientSideBar(pathName, this.props)}
              {isSignIn && roleType !== 'client' && this.renderAdminSideBar(pathName, this.props)}
            </Nav>
          </PerfectScrollbar>
        </FixedSidebar>
        <DashboardTour
          {...{
            isOpen,
            shouldOpen: isSignIn && roleType === 'client' && window.innerWidth >= 768, // do not start walkthorugh on mobile
          }}
        />
      </>
    );
  }
}

ClientSidebar.defaultProps = defaultProps;
ClientSidebar.propTypes = propTypes;
export default connect(null, null)(ClientSidebar);
