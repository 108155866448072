import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Badge from '../Badge';
import { RatingWrapper } from './rating-styles';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { primaryDarkNew, primaryNew } from 'themes/variables';
import Emitter from 'utils/emitter';

export const RatingComponent = (props) => {
  const { index, ratingItem, onChangeRating, itemList, setError, forceValidate, isValid, inputName } = props;
  const { label, rating } = ratingItem;
  const [rateLabel, setRateLabel] = useState(rating === undefined ? '1-10' : rating);
  const [ratingItemCurrent, setRatingItemCurrent] = useState(ratingItem);
  const [valid, setValid] = useState(isValid);

  const onChangeHandler = (indexRating, labelRating, value) => {
    onChangeRating(indexRating, value, labelRating);
    setRatingItemCurrent({ label, rating: value });
  };

  return (
    <RatingWrapper index={index}>
      <div className="rating-item">
        <Badge className="title badge-md secondary">{label}</Badge>
        <div className="ratings">
          <div className="rating-bars">
            <Slider
              dots
              max={10}
              step={1}
              defaultValue={ratingItem.rating}
              onAfterChange={(value) => {
                if (itemList.filter((item, i) => item.rating === value && i !== index).length >= 2 && forceValidate) {
                  setError(
                    inputName === 'languageRating'
                      ? 'Please do not rate any more than two languages at the same level.'
                      : 'Please do not rate any more than two skills at the same level.',
                  );
                  Emitter.emit(inputName, true);
                  setValid(value !== 0 && true);
                  onChangeHandler(index, label, value);
                } else {
                  setValid(value !== 0 && true);
                  itemList[index].rating = value;
                  const itemArr = [];
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                    const itemListArr = itemList.filter((ele, i) => ele.rating === e && i !== index);
                    itemListArr.length > 2 && itemArr.push(e);
                  });
                  if (itemArr.length === 0) {
                    Emitter.emit(inputName, '');
                    setError('');
                  }
                  setRateLabel(value);
                  onChangeHandler(index, label, value);
                }
              }}
              marks={Array.from({ length: 10 + 1 }, (_, i) => ({
                style: {
                  color: `rgb(${primaryDarkNew},0.5)`,
                },
                label: i,
              })).reduce(
                (acc, val, idx) => ({
                  ...acc,
                  [idx]: val,
                }),
                {},
              )}
              handleStyle={{ backgroundColor: `rgb(${primaryNew})`, borderColor: `rgb(${primaryNew})` }}
              activeDotStyle={{ borderColor: `rgb(${primaryNew})` }}
              railStyle={{
                backgroundColor: `rgb(${primaryDarkNew},0.2)`,
              }}
              trackStyle={{
                backgroundColor: `rgb(${primaryNew})`,
              }}
              dotStyle={{
                width: '10px',
                height: '10px',
                top: -2,
              }}
              {...props}
            />
          </div>
        </div>
      </div>
      {!valid && <span className="mt-3 mb-1 rating-error">This is a required field</span>}
    </RatingWrapper>
  );
};
RatingComponent.propTypes = {
  index: PropTypes.number,
  ratingItem: PropTypes.object,
  onChangeRating: PropTypes.func,
  forceValidate: PropTypes.bool,
  setError: PropTypes.func,
  itemList: PropTypes.array,
  isValid: PropTypes.bool,
};
RatingComponent.defaultProps = {
  index: 0,
  ratingItem: {},
  onChangeRating: () => {},
};
export default RatingComponent;
