/* * Wallet * */

import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'reactstrap';
import Content from 'components/Content';
import get from 'lodash/get';
import SVG from 'react-inlinesvg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { FormattedMessage } from 'react-intl';
import { primaryNew, primaryDarkNew } from 'themes/variables';
import { getUserRegisterType, getUserType } from 'utils/Helper';
import { redirectTo } from 'containers/App/utils';
import { TALENT, rightAngleIcon, currencyData, walletIcon, JobEmptyIcon, customStyles, earnIcon } from 'containers/App/constants';
import { getCurrencySymbol } from 'containers/MyProfilePage/components/utils';
import { Card, PrivateGrid, Badge, P, H3, Button, H2, H6, H4, UnControlledTooltip } from 'components';
import containerMessage from 'containers/messages';
import { PrimaryMainSVG } from 'containers/ClientHomePage/styles';
import messages from './messages';
import { columns, LinearIndeterminate, payoutItems } from './constants';
import { propTypes } from './proptypes';
import {
  LinkViewAll,
  DBcontainer,
  LeftCol,
  RightCol,
  CircularProgressbarBlock,
  BalanceCard,
  WalletIcon,
  PayoutCard,
  CardContainer,
} from './styles';
import 'react-circular-progressbar/dist/styles.css';
import ToolTip from 'components/ToolTip';
import DataTable from 'react-data-table-component';
import EmptyScreenCard from 'components/EmptyScreens/EmptyScreenCard';
import { isEmpty } from 'lodash';
import moment from 'moment';
export class Wallet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roleType: getUserRegisterType(),
      userDetails: props.userData,
    };
  }

  render() {
    const { userDetails } = this.state;
    const { walletBalance, isListLoading, transactionsList } = this.props;

    const utilisationVal = get(userDetails, 'data.utilisation', '');
    const currencyVal = getCurrencySymbol(currencyData, 'code', get(userDetails, 'data.currency'));
    const currencySymbol = getCurrencySymbol(currencyData, 'code', get(walletBalance, 'currency'));
    const currencySymbolTimesheet = getCurrencySymbol(currencyData, 'code', get(walletBalance, 'lastSubmittedTimesheet.currency'));

    return (
      <React.Fragment>
        <Helmet>
          <title>{messages.title.defaultMessage}</title>
          <meta name="description" content={messages.metaTitle.defaultMessage} />
        </Helmet>
        <Content className="mt-4 mt-sm-0">
          <PrivateGrid>
            <DBcontainer>
              <LeftCol>
                <Row>
                  <Col xs={12} lg={5}>
                    <div className="">
                      <H3 className="mb-3">
                        <FormattedMessage {...messages.balanceTitle} />
                      </H3>
                      <BalanceCard className="d-flex flex-column justify-content-between border-0">
                        <div>
                          <div className="d-flex  align-items-center">
                            <WalletIcon>
                              <SVG src={walletIcon} width="17px" height="17px" />
                            </WalletIcon>
                            <H2 className="m-0 p-0 text-white">
                              {currencySymbol}
                              {walletBalance?.walletBalance || 0.0}
                            </H2>
                          </div>
                          <hr className="bg-white" />
                        </div>

                        {isEmpty(get(walletBalance, 'lastSubmittedTimesheet')) && (
                          <div className="d-block">
                            <P className="p16 m-0 p-0 text-white" lineHeight="18px">
                              No submitted timesheet
                            </P>
                          </div>
                        )}

                        {!isEmpty(get(walletBalance, 'lastSubmittedTimesheet')) && (
                          <div className="d-block">
                            <P className="p20 m-0 p-0 text-white" lineHeight="22.9px">
                              {currencySymbolTimesheet}
                              {get(walletBalance, 'lastSubmittedTimesheet.amount', '')}
                            </P>
                            <P className="p16 m-0 p-0 text-white" lineHeight="18px">
                              Last submitted timesheet on{' '}
                              {moment(new Date(get(walletBalance, 'lastSubmittedTimesheet.createdAt', ''))).format('DD/MM/YYYY')}
                            </P>
                          </div>
                        )}

                        <div className="d-flex justify-content-end align-items-end mt-4">
                          <Button className="text-primary-50" id={`TooltipWithdrawButton`}>
                            Withdraw
                          </Button>

                          <UnControlledTooltip
                            targetId={`TooltipWithdrawButton`}
                            content="Coming soon. Your payout will be
                    auto withdraw for now."
                            placement="top"
                            bgColor="bg-main"
                          />
                        </div>
                      </BalanceCard>
                    </div>
                  </Col>

                  <Col xs={12} lg={7}>
                    <div className="">
                      <H3 className="mb-3">
                        <FormattedMessage {...messages.payoutTitle} />
                      </H3>
                      <PayoutCard className="d-flex flex-column justify-content-around align-items-center w-100">
                        <H2 className="">Coming soon!</H2>
                        <div className="d-flex flex-column justify-content-between align-items-center w-100">
                          <H6 className="">Receive payments via:</H6>

                          <div className="d-flex justify-content-between align-items-center mt-4">
                            {payoutItems.map((item) => (
                              <>
                                <WalletIcon id={`TooltipWithdrawButton${item?.name}`} key={item.name} type="button">
                                  <img src={item.icon} alt={item.name} />
                                </WalletIcon>

                                <UnControlledTooltip
                                  delay={0}
                                  targetId={`TooltipWithdrawButton${item?.name}`}
                                  content={item?.name}
                                  placement="top"
                                  bgColor="bg-main"
                                />
                              </>
                            ))}
                          </div>
                        </div>
                      </PayoutCard>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      <H3>
                        <FormattedMessage {...messages.transactions} />
                      </H3>
                      <div className="ms-2">
                        <ToolTip
                          wrapperClass={'m-0 p-0'}
                          titleClass={'m-0 p-0'}
                          placement="top"
                          isOpen
                          target="tooltipTalentTransactions"
                          content="Transactions are related to your timesheets."
                        />
                      </div>
                      <Badge className="badge-sm ms-2 primary d-flex align-items-center">{transactionsList?.length || 0}</Badge>
                    </div>
                    <LinkViewAll
                      to={{
                        pathname: `${TALENT}/wallet-transactions`,
                      }}
                    >
                      <span className="me-2">
                        <FormattedMessage {...containerMessage.linkViewAll} />
                      </span>
                      <PrimaryMainSVG src={rightAngleIcon} />
                    </LinkViewAll>
                  </div>
                </Row>
                <Row>
                  <DataTable
                    noHeader
                    columns={columns}
                    data={transactionsList}
                    totalRows={0}
                    direction="ltr"
                    progressPending={isListLoading}
                    progressComponent={<LinearIndeterminate />}
                    overflowX
                    overflowXOffset="70px"
                    noDataComponent={
                      <>
                        <EmptyScreenCard
                          style={{
                            minHeight: 323,
                          }}
                          cardTitle={messages.noRecordTitle}
                          cardDescription={messages.noRecordDesc}
                          icon={JobEmptyIcon}
                          textContainersClasses="text-muted d-flex flex-column align-items-start align-items-sm-center mt-3 mb-3 text-left text-md-center"
                          cardClasses="d-flex flex-column justify-content-center align-items-start align-items-sm-center p-40 mb-0 border-0 border-none w-100"
                        />
                      </>
                    }
                    customStyles={{
                      ...customStyles,
                      headCells: {
                        style: {
                          ...customStyles.headCells.style,
                          color: '#04004E',
                        },
                      },
                      cells: {
                        style: {
                          ...customStyles.cells.style,
                          color: `rgb(${primaryDarkNew},0.5)`,
                          textAlign: 'left',
                          paddingLeft: 0,
                        },
                      },
                    }}
                  />
                </Row>
              </LeftCol>
              <RightCol className="mt-0">
                <H3 className="mb-3">Earnings</H3>
                <Card>
                  <CircularProgressbarBlock className="mt-3 mb-3">
                    <CircularProgressbar
                      value={utilisationVal}
                      text={`${utilisationVal}%`}
                      strokeWidth={7}
                      styles={buildStyles({
                        rotation: 0.25,
                        textSize: '23px',
                        pathTransitionDuration: 0.15,
                        pathColor: `rgba(${primaryNew})`,
                        textColor: `rgb(${primaryDarkNew})`,
                        trailColor: '#D9D8FF',
                      })}
                    />
                  </CircularProgressbarBlock>
                  <P className="p16 text-center">Utilisation</P>
                  <hr className="my-4" />
                  <Row>
                    <Col sm={6}>
                      <P className="p20 mb-1">
                        {currencyVal}
                        {get(userDetails, 'data.lastMonthEarnings', '')}
                      </P>
                      <P className="p16" opacityVal="0.5">
                        This month
                      </P>
                    </Col>
                    <Col sm={6}>
                      <P className="p20 mb-1">
                        {currencyVal}
                        {get(userDetails, 'data.taxYearEarnings', '')}
                      </P>
                      <P className="p16" opacityVal="0.5">
                        This tax year
                      </P>
                    </Col>
                    <Col sm={6}>
                      <P className="p20 mb-1">
                        {currencyVal}
                        {get(userDetails, 'data.lifeTimeEarnings', '')}
                      </P>
                      <P className="p16 mb-0" opacityVal="0.5">
                        Lifetime
                      </P>
                    </Col>
                  </Row>
                </Card>

                <H3 className="mt-2 mb-3">Refer & Earn</H3>
                <Card className="bg-secondary-gradient">
                  <div className="d-flex align-items-center">
                    <SVG src={earnIcon} width="40" height="40" />
                    <H4 className="newH4 ms-3 mb-0 mt-0">Earn cash</H4>
                  </div>
                  <div className="mt-3">
                    <P className="p-sm">Know someone who match the required skills?</P>
                    <P className="p-sm">
                      Refer and earn up to <span className="text-primary">$150</span> cash.
                    </P>
                  </div>
                  <div>
                    <Button
                      name={`${getUserType()}_refer_n_earn`}
                      className={`${getUserType()}_refer_n_earn btn btn-sm btn-outline w-100`}
                      onClick={() => redirectTo(null, `${TALENT}/referrals`)}
                    >
                      Invite now
                    </Button>
                  </div>
                </Card>
              </RightCol>
            </DBcontainer>
          </PrivateGrid>
        </Content>
      </React.Fragment>
    );
  }
}

Wallet.propTypes = propTypes;

export default Wallet;
