import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TalentNameBtn = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  img {
    width: 34px;
    height: 34px;
    border-radius: 100%;
  }
  span {
    text-transform: capitalize;
  }
`;

export const TalentName = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: default;
  img {
    width: 34px;
    height: 34px;
    border-radius: 100%;
  }
  span {
    text-transform: capitalize;
  }
`;
