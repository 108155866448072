import styled from 'styled-components';
import media from 'themes/media';
import { primaryNew, white, successNew, primaryDarkNew, dangerNew } from 'themes/variables';

export const OnboardingContainer = styled.div`
  flex: 1 1 auto;
  background: rgb(${white});
  z-index: 0;

  .inner-container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    ${media.medium`
      flex-direction: row;
    `};
  }
`;

export const TabSectionBlock = styled.div`
  background: rgb(${white});
  width: 100%;
  padding: 110px 25px 0;

  .text-align-left {
    text-align: left;
  }

  ${media.medium`
    width: 270px;
    border-right: 1px solid rgba(${primaryNew}, 0.1);
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1;
    height: 100vh;
  `};

  ${media.large`
    width: 361px;
    padding: 130px 50px 0;
  `};

  .inner-tab-block {
    .progress {
      width: 100%;
      background: rgba(${primaryDarkNew}, 0.1);

      & div {
        height: 100%;
        background: rgb(${successNew});
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: center;

      ${media.medium`
        flex-direction: column;
      `};

      li {
        display: flex;
        align-items: start;
        font-family: 'GT-Walsheim-Pro-Regular';
        position: relative;
        margin-right: 24px;
        flex-direction: column;

        ${media.medium`
          margin-right: 0;
          margin-bottom: 30px;
          padding-left: 32px;
        `};

        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
          &:after {
            display: none;
          }
        }

        a,
        button {
          background: transparent;
          border: 0;
          padding: 0;
          font-size: 14px;
          line-height: 16px;
          color: rgb(${primaryDarkNew}, 0.5);
          display: flex;
          align-items: center;
          text-decoration: none;
          width: 100%;
          justify-content: space-between;

          ${media.large`
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 12px;
          `};

          .progress-indicator {
            width: 16px;
            margin-right: 0;

            ${media.medium`
              margin-right: 16px;
              position: absolute;
              left: 0;
              top: 0;
            `};
          }

          .tick-icon {
            height: 16px;
            width: 16px;
          }

          .unfilled-circle {
            width: 16px;
            height: 16px;
            border: 1px solid rgb(${primaryDarkNew}, 0.5);
            border-radius: 100%;
          }

          .danger-badge {
            width: 8px;
            height: 8px;
            background: rgb(${dangerNew});
            border-radius: 100%;
            position: absolute;
            right: -2px;
            top: -11px;

            ${media.medium`
              right: -6px;
              top: 50%;
              margin-top: -4px;
            `};
          }

          &:hover,
          &:focus,
          &:active,
          &:active:focus {
            background: inherit;
            color: inherit;
            box-shadow: none;
          }

          svg {
            &.right-angle {
              height: 12px;
              width: 12px;
              path {
                stroke: rgba(${primaryDarkNew}, 0.2);
              }

              display: none;

              ${media.medium`
                display: block;
              `};
            }
          }
        }

        &.active,
        &:hover {
          .unfilled-circle {
            border: 1px solid rgb(${primaryDarkNew});
          }

          a,
          button {
            color: rgb(${primaryDarkNew});

            svg {
              &.right-angle {
                path {
                  stroke: rgba(${primaryDarkNew});
                }
              }
            }
          }
        }
      }
    }
  }
`;
TabSectionBlock.displayName = 'TabSectionBlock';
