/**
 * Tiny MCE
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { TINY_MCE_API_KEY } from 'containers/App/constants';
import { primaryDarkNew } from 'themes/variables';
import { ValidationMessage, TinyMCEFormGroup } from '../Input';
export class TinyMCE extends React.Component {
  constructor(props) {
    super(props);
    const { defaultValue = '' } = props;
    const nbspReplacedText = typeof defaultValue === 'string' ? defaultValue.replace(/&nbsp;/gi, ' ') : '';
    const summaryText = nbspReplacedText.replace(/<(.|\n)*?>/g, '');

    this.state = {
      value: props.defaultValue,
      disabled: props.disabled,
      maxLength: props.maxLength,
      minLength: props.minLength,
      requiredFlag: props.requiredFlag,
      currentLength: summaryText.length > 0 ? summaryText.length || 0 : props.currentLength || 0,
      errorMsg: '',
    };
  }

  /**
   * call when component will receive Params.
   */
  componentWillReceiveProps(nextProps) {
    const { defaultValue } = this.props;
    if (nextProps.defaultValue !== defaultValue) {
      this.setDefaultValue(nextProps.defaultValue);
    }
    this.setDescCurrentLength(nextProps.currentLength);
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    const textContent = this.removeHTMLTags(defaultValue);
    this.setDescCurrentLength(textContent.length);
  }

  setDefaultValue = (value) => {
    this.setState({ value });
  };

  removeHTMLTags = (str) => {
    if (str === null || str === '') {
      return false;
    }

    str = str.toString();
    const finalStr = str.replace(/(<([^>]+)>)/gi, '');
    return finalStr.trim();
  };

  handleDescriptionValueChange = (value, editor) => {
    const { handleDescriptionChange } = this.props;
    this.setState({ value });
    const editorContent = editor.getContent({ format: 'text' });
    const textLength = editorContent.length > 0 ? editorContent.trim().length : editorContent.length;
    this.setDescCurrentLength(textLength);
    handleDescriptionChange(value, textLength);
  };

  setDescCurrentLength = (currentLength) => {
    const { maxLength, minLength, requiredFlag, value } = this.state;
    const { setValidValue } = this.props;

    if (currentLength === 0) {
      const nbspReplacedText = typeof value === 'string' ? value.replace(/&nbsp;/gi, ' ') : '';
      const summaryText = nbspReplacedText.replace(/<(.|\n)*?>/g, '');
      const descCount = summaryText.length;
      currentLength = descCount;
    }

    this.setState({ currentLength });

    let errorMsg = '';

    if (currentLength === 0 && requiredFlag) {
      errorMsg = 'This is a required field';
      setValidValue(false);
    }

    if (currentLength !== 0 && currentLength < minLength) {
      errorMsg = `Minimum ${minLength} characters required`;
      setValidValue(false);
    }

    if (currentLength !== 0 && currentLength > maxLength) {
      errorMsg = `Maximum ${maxLength} characters allowed`;
      setValidValue(false);
    }

    if (errorMsg === '') {
      setValidValue(true);
    }

    this.setState({ errorMsg });
  };

  render() {
    const {
      meta: { error },
      height,
      menuBar,
      contentStyle,
      toolbar,
      placeholder,
      defaultValue,
    } = this.props;
    const { value, currentLength, maxLength } = this.state;
    const { disabled } = this.state;
    let { errorMsg } = this.state;
    const defaultValueText = value !== '' ? value : defaultValue;

    if (currentLength > maxLength) {
      errorMsg = `Maximum ${maxLength} characters allowed`;
    }

    const success = errorMsg === '' ? 'success' : '';
    const errors = errorMsg !== '' ? ' has-error ' : success;

    const errorMsgText = `${errorMsg}`;
    const characterLengthText = `Current length ${currentLength}`;

    const editorTinyMCE = (
      <Editor
        apiKey={TINY_MCE_API_KEY}
        onEditorChange={this.handleDescriptionValueChange}
        onBlur={(e) => {
          const editorContent = e.target.getContent({ format: 'text' });
          const textLength = editorContent.length > 0 ? editorContent.trim().length : editorContent.length;
          this.setDescCurrentLength(textLength);
        }}
        value={defaultValueText}
        disabled={disabled}
        toolbar={toolbar}
        scriptLoading={{ defer: true }}
        init={{
          toolbar,
          height: `${height}`,
          menubar: `${menuBar}`,
          placeholder: `${placeholder}`,
          valid_styles: 'text-align,font-size',
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'help',
            'wordcount',
          ],
          invalid_elements: 'script,iframe,video,audio',
          forced_root_block: '',
          paste_data_images: false,
          formats: {
            underline: { inline: 'u', exact: true },
            bold: { inline: 'b' },
            italic: { inline: 'i' },
          },
          paste_preprocess: function (_, args) {
            const regex = /<p>(https?:\/\/.*?)<\/p>/g;
            args.content = args.content.replace(regex, '<a href="$1" target="_blank">$1</a>');
          },
        }}
      />
    );
    return (
      <TinyMCEFormGroup className={`${error}`}>
        <div className={`${errors} form-control-validated`}>
          {editorTinyMCE}
          <ValidationMessage>{errorMsgText}</ValidationMessage>
          <span className="rightElemTinyText font-10">{characterLengthText}</span>
        </div>
      </TinyMCEFormGroup>
    );
  }
}

TinyMCE.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  meta: PropTypes.object,
  height: PropTypes.string,
  menuBar: PropTypes.bool,
  contentStyle: PropTypes.string,
  disabled: PropTypes.bool,
  toolbar: PropTypes.string,
  handleDescriptionChange: PropTypes.func,
  setValidValue: PropTypes.func,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  requiredFlag: PropTypes.bool,
  currentLength: PropTypes.number,
};

TinyMCE.defaultProps = {
  defaultValue: '',
  meta: {},
  height: '500px',
  menuBar: true,
  contentStyle: `body { font-family:"GT-Walsheim-Pro-Regular","Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif; font-size:16px; color:rgb(${primaryDarkNew}) }`,
  disabled: false,
  handleDescriptionChange: () => {},
  setValidValue: () => {},
  placeholder: 'Description',
  minLength: 50,
  maxLength: 500,
  requiredFlag: true,
  currentLength: 0,
  toolbar: 'bold italic underline alignleft aligncenter alignright alignjustify | bullist numlist',
};

export default TinyMCE;
