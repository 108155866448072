import { configureStore, StoreEnhancer, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { InjectedReducersType } from 'utils/types/injector-typings';
import { reducer as formReducer } from 'redux-form';
import globalReducer from 'containers/App/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import compareTalentReducer from 'containers/CompareTalentSticky/reducer';
import { all } from 'redux-saga/effects';
import TalentDetailsUpdateForm from 'containers/Client/Talents/saga';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() });

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  return combineReducers({
    global: globalReducer,
    language: languageProviderReducer,
    form: formReducer,
    router: routerReducer,
    compareTalents: compareTalentReducer,
    ...injectedReducers,
  });
}

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  const middlewares = [sagaMiddleware, routerMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middlewares),
    devTools: process.env.NODE_ENV === 'development',
    enhancers,
  });
  sagaMiddleware.run(rootSaga);

  return store;
}

export function* rootSaga() {
  yield all([
    TalentDetailsUpdateForm(),
    // Other sagas...
  ]);
}

export const store = configureAppStore();
export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
