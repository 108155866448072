import styled from 'styled-components';
import { primaryNew, lightBlue } from 'themes/variables';
import media from 'themes/media';
import { FormControlWrapper } from '../Input';

export const OnboardingForm = styled.div`
  label {
    margin-bottom: 0;
  }
  label + .form-control,
  label + ${FormControlWrapper} {
    margin-top: 5px;
  }
  .form-group {
    margin-bottom: 0px;
  }
  .form-group + .form-group {
    margin-top: 20px;
    ${media.large`
      margin-top: 30px;
    `};
  }
  textarea {
    min-height: 170px;
  }
  hr {
    margin: 30px 0;
    ${media.large`
      margin: 60px 0;
    `};
  }
`;

export const OnBoardingFormBody = styled.div`
  .layout-v2 & {
    max-width: 360px;
    margin: 30px auto 0;
    ${media.medium`
      margin: 30px 0 0;
    `};
  }
  .layout-v1 & {
    max-width: 560px;
    margin: 30px auto 0;
    text-align: left;
  }

  ${media.large`
    .layout-v2 & {
      margin: 50px 0 0;
    }
    .layout-v1 & {
      margin: 65px auto 0;
    }
  `};

  .custom-radio {
    max-width: 560px;
    border: 1px solid ${lightBlue};
    border-radius: 10px;
    flex: 1;
    padding: 15px 20px;
    p {
      opacity: 0.5;
    }
    .checkmark {
      top: 31% !important;
      right: 15px !important;
    }
  }

  .radio-label .checkmark {
    right: 15px !important;
    left: unset !important;
  }

  .custom-radio-focus {
    border-color: rgb(24, 51, 204);
    box-shadow: 0px 0px 0px 2px rgb(24 51 204 / 20%);
    p {
      opacity: 1;
    }
  }

  .company-logo {
    border: 1px solid #d1d6f5;
    border-radius: 10px;
  }

  .mt-30 {
    margin-top: 30px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  &.iframe-content {
    max-width: 80%;
  }
`;
