/**
 * AddACompanyPage
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col, FormGroup } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import injectReducer from 'utils/injectReducer';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import { loadRepos } from 'containers/App/actions';
import { reduxForm, change, touch, Field } from 'redux-form';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import {
  API_URL,
  companyTypeArray,
  editNoteIcon,
  plusSquareIcon,
  tickIcon,
  timeXZone,
  trashIcon,
  COMPANY,
  LOCATION,
} from 'containers/App/constants';
import ToastifyMessage from 'components/ToastifyMessage';
import { getHash, redirectTo } from 'containers/App/utils';
import { makeSelectLoading, makeSelectSuccess, makeSelectError } from 'containers/App/selectors';
import { P, FormWrapper, Button, Card, PrivateGrid, H2, H4, UserInfoList, UnControlledTooltip, FormLabel } from 'components';
import { getSelectedFieldFromList, getIndustryList, getCompanyCultures } from 'containers/Auth/utils';
import containerMessage from 'containers/messages';
import { getPictureDropZone } from 'containers/Auth/PersonalDetails/pictureDropZone';
import { getBtnClass } from 'containers/Auth/PersonalDetails/utils';
import { getFilterValue } from 'containers/Auth/Preferences/utils';
import { VALIDATION, MIN_LOGO_WIDTH, MIN_LOGO_HEIGHT } from 'utils/constants';
import * as actions from './actions';
import * as selectors from './selectors';
import reducer from './reducer';
import saga from './saga';
import SVG from 'react-inlinesvg';
import messages from './messages';
import { addCompanySteps, key } from './constants';
import Content from 'components/Content';
import { Stepper } from 'containers/Client/AddBrief/styles';
import { getStepperClass } from 'containers/Client/AddBrief/utils';
import { AboutCompanyFields } from 'containers/Auth/Client/AboutCompanyPage/AboutCompanyFields';
import { errorIndicatorTooltipStyle } from 'containers/Auth/constants';
import { getTimezoneOffest } from 'containers/MyProfilePage/components/utils';
import ModalWrapper from 'components/ModalWrapper';
import AddressFields from 'containers/Auth/Client/CompanyLocationPage/AddressFields';
import * as formValidations from 'utils/formValidations';
import { renderField } from 'utils/Fields';
import * as normalize from 'utils/normalize';
import request from 'utils/request';
import AddACompanyModal from './components/AddACompanyModal';
import { formatAURL } from 'utils/Helper';
import { isNil } from 'lodash';

const OptionalField = [
  'vatNumber',
  'registeredNumber',
  'linkedInUrl',
  'gitHubUrl',
  'stackOverFlowUrl',
  'dribbbleUrl',
  'behanceUrl',
  'glassdoorUrl',
  'portfolioUrl',
];

export class AddACompanyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      industryList: [],
      companyCulturesList: [],
      currentStep: 1,
      showAddLocationsModal: false,
      locationsModalType: '',
      companyDetails: {
        companyLocation: [],
      },
      openDeleteModal: false,
    };
  }

  componentDidMount() {
    const { companyDetails } = this.state;
    const hash = getHash(window.location, 'ADD_COMPANY');
    this.props.history?.push(`${window.location.pathname}${hash}`);
    getIndustryList(this.setIndustries);
    getCompanyCultures(this.setCompanyCultures);

    this.setValues(companyDetails);
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    let errorFiles = '';
    rejectedFiles.forEach((file, index) => {
      errorFiles = `${errorFiles} (${index + 1}) ${file.name}`;
    });
    if (get(rejectedFiles, '[0].errors[0].code') === 'file-invalid-type') {
      toast.error(<ToastifyMessage message={VALIDATION.invalidFileType} type="error" />, { className: 'Toast-error' });
    } else if (
      get(rejectedFiles, '[0].errors[0].code') === 'file-too-large' ||
      get(rejectedFiles, '[0].errors[0].code') === 'file-too-small'
    ) {
      toast.error(<ToastifyMessage message={VALIDATION.invalidFile} type="error" />, { className: 'Toast-error' });
    } else if (rejectedFiles.length > 1) {
      toast.error(<ToastifyMessage message={VALIDATION.maxOneFileLength} type="error" />, { className: 'Toast-error' });
    } else {
      const reader = new FileReader();
      const selectedFile = acceptedFiles[0];
      this.checkFileType(selectedFile, reader);
    }
  };

  checkFileType(selectedFile, reader) {
    if (!selectedFile) {
      return;
    }
    const file = selectedFile;
    const regex = new RegExp('(.*?).(png|jpg|jpeg)$');
    if (regex.test(file.type)) {
      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          if (image.height < MIN_LOGO_HEIGHT || image.width < MIN_LOGO_WIDTH) {
            toast.error(<ToastifyMessage message={VALIDATION.MINIMUX_IMAGE_RESOLUTION} type="error" />, { className: 'Toast-error' });
            return null;
          }
          this.setState({
            image: reader.result,
            selectedFile,
          });
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.error(<ToastifyMessage message={VALIDATION.invalidFileType} type="error" />, {
        className: 'Toast-error',
      });
    }
  }

  deletePhoto = () => {
    this.setState({ image: '' });
  };

  setValues = (companyDetails) => {
    const { dispatch, onChangeBio } = this.props;

    const companyType = getSelectedFieldFromList(companyTypeArray, 'value', get(companyDetails, 'companyType', ''));
    const industry = get(companyDetails, 'industry', '')
      ? { label: get(companyDetails, 'industry', ''), value: get(companyDetails, 'industry', '') }
      : null;
    const cultures = (get(companyDetails, 'cultures', []) || []).map((v) => ({ label: v, value: v }));
    const setData = {
      name: get(companyDetails, 'name', ''),
      brand: get(companyDetails, 'brand', ''),
      registeredNumber: get(companyDetails, 'registeredNumber', ''),
      vatNumber: get(companyDetails, 'vatNumber', ''),
      industry,
      companyType,
      cultures,
      linkedInUrl: get(companyDetails, 'linkedInUrl', ''),
      gitHubUrl: get(companyDetails, 'gitHubUrl', ''),
      stackOverFlowUrl: get(companyDetails, 'stackOverFlowUrl', ''),
      dribbbleUrl: get(companyDetails, 'dribbbleUrl', ''),
      behanceUrl: get(companyDetails, 'behanceUrl', ''),
      portfolioUrl: get(companyDetails, 'portfolioUrl', ''),
      corporateWebsiteUrl: get(companyDetails, 'corporateWebsiteUrl', ''),
      glassdoorUrl: get(companyDetails, 'glassdoorUrl', ''),
      bio: get(companyDetails, 'bio', ''),
    };
    const logoURL = get(companyDetails, 'logo', '');
    this.setState({ image: logoURL ? `${logoURL}?_t=${new Date().getTime()}` : logoURL });
    Object.keys(setData).forEach((fieldKey) => {
      dispatch(change(key, fieldKey, setData[fieldKey]));
      if (!OptionalField.includes(fieldKey)) {
        dispatch(touch(key, fieldKey));
      }
    });
    dispatch(actions.changeName(setData.name));
    dispatch(actions.changeBrand(setData.brand));
    dispatch(actions.changeRegisteredNumber(setData.registeredNumber));
    dispatch(actions.changeVatNumber(setData.vatNumber));
    dispatch(actions.changeIndustry(setData.industry));
    dispatch(actions.changeCompanyType(setData.companyType));
    dispatch(actions.selectTagCompanyCultures(setData.cultures));
    dispatch(actions.changeLinkedInProfile(setData.linkedInUrl));
    dispatch(actions.changeGithubProfile(setData.gitHubUrl));
    dispatch(actions.changeStackoverflowProfile(setData.stackOverFlowUrl));
    dispatch(actions.changeDribbleProfile(setData.dribbbleUrl));
    dispatch(actions.changeBehanceProfile(setData.behanceUrl));
    dispatch(actions.changePersonalProfile(setData.portfolioUrl));
    dispatch(actions.changeGlassdoorURL(setData.glassdoorUrl));
    dispatch(actions.changeCorporateWebsiteUrl(setData.corporateWebsiteUrl));
    onChangeBio(setData.bio);
  };

  setIndustries = (response, cb) => {
    if (get(response, 'status')) {
      this.setState({ industryList: response.data }, cb);
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
      cb();
    }
  };

  setCompanyCultures = (response, cb) => {
    if (get(response, 'status')) {
      this.setState({ companyCulturesList: response.data }, cb);
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
      cb();
    }
  };

  handleSaveForLater = (e, type, currentStep) => {
    if (type !== 'continue') {
      e.preventDefault();
    }
    const {
      onSubmitAddACompanyForm,
      name,
      brand,
      registeredNumber,
      vatNumber,
      industry,
      companyType,
      cultures,
      linkedInUrl,
      gitHubUrl,
      stackOverFlowUrl,
      dribbbleUrl,
      behanceUrl,
      portfolioUrl,
      glassdoorUrl,
      corporateWebsiteUrl,
      bio,
    } = this.props;

    const { selectedFile, image, companyDetails } = this.state;
    const data = {
      name,
      brand,
      registeredNumber,
      vatNumber,
      industry: industry?.value ? industry?.value : '',
      companyType: companyType?.value ? companyType?.value : '',
      cultures: cultures?.some((i) => i.label) ? getFilterValue(cultures) : cultures,
      linkedInUrl,
      gitHubUrl,
      stackOverFlowUrl,
      dribbbleUrl,
      behanceUrl,
      portfolioUrl,
      glassdoorUrl,
      corporateWebsiteUrl,
      bio,
      logo: !selectedFile ? image : selectedFile,
    };

    if (get(companyDetails, '_id', '')) {
      data['id'] = get(companyDetails, '_id', '');
    }

    onSubmitAddACompanyForm(e, data, type, (details, step) => {
      this.setCurrentCompanyDetails(details);
      this.changeStep(step);
    });
  };

  changeStep = (currentStep) => {
    this.setState({ currentStep });
  };

  setCurrentCompanyDetails = (companyDetails) => {
    this.setState({ companyDetails });
  };

  handleLocationsOpenModal = (type, id) => {
    const { dispatch } = this.props;
    if (type === 'add') {
      const data = {
        locationName: '',
        addressLineOne: '',
        postcode: '',
        city: '',
        country: [],
        timezone: [],
        state: '',
        addressLineTwo: '',
      };
      Object.keys(data).forEach((fieldKey) => {
        dispatch(change(key, fieldKey, data[fieldKey]));
        if (!OptionalField.includes(fieldKey)) {
          dispatch(touch(key, fieldKey));
        }
      });
      dispatch(actions.changeCountry(data.country));
      dispatch(actions.changeTimeZone(data.timezone));
    }
    if (type === 'edit') {
      const {
        companyDetails: { companyLocation },
      } = this.state;
      // eslint-disable-next-line no-underscore-dangle
      const locationData = companyLocation.find((obj) => obj._id === id);

      const dataObj = {
        locationName: locationData.locationName,
        addressLineOne: locationData.addressLineOne,
        postcode: locationData.postcode,
        city: locationData.city,
        country: { label: locationData.country, value: locationData.country },
        timezone: { label: locationData.timezone, value: locationData.timezone },
        state: locationData.state,
        addressLineTwo: locationData.addressLineTwo,
      };
      Object.keys(dataObj).forEach((fieldKey) => {
        dispatch(change(key, fieldKey, dataObj[fieldKey]));
        dispatch(touch(key, fieldKey));
      });
      dispatch(actions.changeCountry(dataObj.country));
      dispatch(actions.changeTimeZone(dataObj.timezone));
      dispatch(actions.changePostcode(dataObj.postcode));
      dispatch(actions.changeLocationName(dataObj.locationName));
      dispatch(actions.changeState(dataObj.state));
      dispatch(actions.changeCity(dataObj.city));
      dispatch(actions.changeAddressLineTwo(dataObj.addressLineTwo));
      dispatch(actions.changeAddressLineOne(dataObj.addressLineOne));
    }
    this.setState({ showAddLocationsModal: true, locationsModalType: type, selectedLocationID: id });
  };

  handleLocationsCloseModal = () => {
    this.setState({ showAddLocationsModal: false });
  };

  handleSubmitCompanyLocationForm = (e) => {
    const { locationName, addressLineOne, postcode, city, country, timezone, state, addressLineTwo, onSubmitAddCompanyLocationForm } =
      this.props;
    const { companyDetails, locationsModalType, selectedLocationID } = this.state;

    const data = {
      locationName,
      addressLineOne,
      postcode,
      city,
      country: country && country.value,
      timezone: timezone && timezone.value,
      state,
      addressLineTwo,
      companyId: get(companyDetails, '_id', ''),
    };

    if (locationsModalType === 'edit') {
      data.id = selectedLocationID;
    }

    onSubmitAddCompanyLocationForm(e, locationsModalType, data, (companyLocation) => {
      this.setCurrentCompanyDetails({ ...companyDetails, companyLocation });
      this.setState({ showAddLocationsModal: false });
    });
  };

  deleteRecord = (id) => {
    const { companyDetails } = this.state;
    const data = {
      method: 'DELETE',
      body: { id },
    };
    const requestURL = `${API_URL}${COMPANY}${LOCATION}`;
    request(requestURL, data).then((response) => {
      if (get(response, 'status')) {
        this.setCurrentCompanyDetails({ ...companyDetails, companyLocation: get(response, 'data') });
      } else {
        toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
      }
    });
  };

  onGoToStep3 = () => {
    const { dispatch, onChangeDomain } = this.props;
    const { companyDetails } = this.state;
    const domain = get(companyDetails, 'domain', '');

    dispatch(change(key, 'domain', `@${domain}`));
    dispatch(touch(key, 'domain'));
    onChangeDomain({ target: { value: `@${domain}` } });
    this.changeStep(3);
  };

  handleCreateAccount = (e) => {
    const { invitedUsername, invitedUserEmail, domain, onSubmitCreateAccount } = this.props;
    const { companyDetails } = this.state;

    if (invitedUserEmail) {
      const data = {
        companyId: get(companyDetails, '_id', ''),
        email: invitedUserEmail.trim() + domain,
        fullName: invitedUsername,
      };

      onSubmitCreateAccount(e, data, () => this.setOpenDeleteModal(true));
    } else {
      this.setOpenDeleteModal(true);
    }
  };

  setOpenDeleteModal = (condition) => {
    this.setState({ openDeleteModal: condition });
  };

  constructURL = () => {
    const { companyDetails } = this.state;
    const origin = window.location.origin;

    if (get(companyDetails, 'brand')) {
      const url = `${origin}/${formatAURL(get(companyDetails, 'brand'))}`;
      return url;
    }

    return '';
  };

  render() {
    const {
      invalid,
      loading,
      responseSuccess,
      responseError,
      handleSubmit,
      locationName,
      onChangeLocationName,
      invitedUsername,
      invitedUserEmail,
      onChangeInvitedUsername,
      onChangeInvitedUserEmail,
      domain,
      bio,
    } = this.props;
    const {
      image,
      industryList,
      companyCulturesList,
      currentStep,
      showAddLocationsModal,
      locationsModalType,
      companyDetails,
      openDeleteModal,
    } = this.state;

    const { companyLocation } = companyDetails;

    const renderBtnText = () =>
      !isNil(invitedUserEmail) && invitedUserEmail.trim().length > 0 ? 'Invite & Create account' : 'Skip & Create account';
    return (
      <React.Fragment>
        <Helmet>
          <title>{messages.title.defaultMessage}</title>
          <meta name="description" content={messages.metaTitle.defaultMessage} />
        </Helmet>
        <Content>
          <PrivateGrid>
            <Card className="d-flex justify-content-center 70vh">
              <div className="dense">
                <H2 className="mb-3">
                  <FormattedMessage {...messages.addACompany} />
                </H2>
                <P className="p16 mb-5" opacityVal="0.5">
                  <FormattedMessage {...messages.addACompanyTagLine} />
                </P>
                <Stepper className="mb-4 full">
                  {addCompanySteps.map((name, index) => (
                    <li className={getStepperClass(currentStep, index)} key={name}>
                      <span className="line">
                        <SVG src={tickIcon} />
                      </span>
                      {name}
                    </li>
                  ))}
                </Stepper>

                <FormWrapper>
                  <form onSubmit={handleSubmit}>
                    {currentStep === 1 && (
                      <>
                        <Row>
                          <Col className="col-12 d-flex align-items-center">
                            <div className="d-inline mb-3">
                              <H4 className="newH4 m-0 d-inline" opacityVal="0.5">
                                <FormattedMessage {...messages.uploadCompanyLogo} />
                              </H4>

                              <P className="p14 m-0 ms-1 d-inline text-capitalize" opacityVal="0.5">
                                (Required)
                              </P>
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div id="dropZone">
                              {getPictureDropZone({
                                that: this,
                                type: 'logoUploader',
                                required: true,
                              })}
                            </div>
                          </Col>
                        </Row>
                        <AboutCompanyFields
                          formKey={key}
                          {...this.props}
                          industryList={industryList}
                          companyCulturesList={companyCulturesList}
                        />
                      </>
                    )}
                    {currentStep === 2 && (
                      <UserInfoList>
                        {companyLocation.map((item, i) => (
                          <li>
                            <span style={errorIndicatorTooltipStyle} id={`TooltipLocations${i}`} className={`${'d-none'}`} />
                            <UnControlledTooltip
                              targetId={`TooltipLocations${i}`}
                              content={containerMessage.profileErrorMessage.defaultMessage}
                              placement="top"
                              bgColor="bg-danger"
                            />
                            <div>
                              <div className="list-outer-block">
                                <div className="list-content">
                                  <div>
                                    <P className="p16 mb-1">{item.locationName}</P>
                                    <P className="p14 mb-2" opacityVal="0.5">
                                      {item.addressLineOne}, {item.addressLineTwo && `${item.addressLineTwo}, `} {item.city} -{' '}
                                      {item.postcode}, {item.addressLineTwo && `${item.state}`} ({item.country})
                                    </P>
                                    <P className="p14 mb-3" opacityVal="0.5">
                                      {item.timezone} ({getTimezoneOffest(timeXZone, 'name', item.timezone)})
                                    </P>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="list-action">
                              <Button type="button" className="btn-icon btn-link ms-auto" onClick={() => this.deleteRecord(item._id)}>
                                <SVG src={trashIcon} className="me-1" />
                                Delete
                              </Button>
                              <Button
                                type="button"
                                className="btn-icon btn-link ms-auto"
                                onClick={() => this.handleLocationsOpenModal('edit', item._id)}
                              >
                                <SVG src={editNoteIcon} className="me-1" />
                                Edit
                              </Button>
                            </div>
                          </li>
                        ))}
                        <li>
                          <Button
                            type="button"
                            className="btn-icon text-primary btn-link ms-auto"
                            onClick={() => this.handleLocationsOpenModal('add')}
                          >
                            <SVG src={plusSquareIcon} className="me-1" />
                            {messages.labelAdd.defaultMessage}
                          </Button>
                        </li>
                      </UserInfoList>
                    )}

                    {currentStep === 3 && (
                      <>
                        <Row>
                          <Col className="col-12 d-flex align-items-center">
                            <div className="d-inline  mb-3">
                              <H4 className="newH4 m-0 d-inline" opacityVal="0.5">
                                Invite employer to claim this profile
                              </H4>

                              <P className="p14 m-0 ms-1 d-inline text-capitalize" opacityVal="0.5">
                                <FormattedMessage {...containerMessage.optionalText} />
                              </P>
                            </div>
                          </Col>
                          <Col className="col-12">
                            <Row>
                              <Col md={7} className="d-flex">
                                <Col md={7} className="ps-0">
                                  <FormGroup>
                                    <FormLabel>Email</FormLabel>
                                    <Field
                                      name="invitedUserEmail"
                                      component={renderField}
                                      type="text"
                                      defaultValue={invitedUserEmail}
                                      placeholder="Enter work email"
                                      onChange={onChangeInvitedUserEmail}
                                      normalize={normalize.trimSpace}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={5} className="ms-1">
                                  <FormGroup>
                                    <FormLabel></FormLabel>
                                    <Field
                                      name="domain"
                                      className="opacity-25"
                                      readOnly
                                      component={renderField}
                                      type="text"
                                      defaultValue={domain}
                                    />
                                  </FormGroup>
                                </Col>
                              </Col>
                              <Col md={5}>
                                <FormGroup>
                                  <FormLabel>Full name</FormLabel>
                                  <Field
                                    name="invitedUsername"
                                    component={renderField}
                                    type="text"
                                    defaultValue={invitedUsername}
                                    placeholder="e.g. John Doe"
                                    onChange={onChangeInvitedUsername}
                                    validate={[
                                      formValidations.minLength2,
                                      formValidations.maxLength30,
                                      formValidations.isHTMLtagContent,
                                      formValidations.isValidNameFormate,
                                    ]}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    )}
                    <div className="d-flex flex-column align-items-center flex-md-row justify-content-md-between my-5">
                      <Button
                        className={`btn-sm btn-outline mt-3 mt-md-0 ms-md-3`}
                        type="button"
                        onClick={(e) =>
                          currentStep === 1
                            ? redirectTo(null, '/admin/company-listing')
                            : (() => {
                                this.setValues(companyDetails);
                                this.changeStep(currentStep - 1);
                              })()
                        }
                      >
                        {currentStep === 1 ? 'Cancel' : 'Back'}
                      </Button>

                      <Button
                        type="submit"
                        className={`${getBtnClass(loading, responseSuccess, responseError)} mt-3 mt-md-0 ms-md-3`}
                        disabled={invalid || !image || (currentStep === 1 ? bio?.length < 107 : false)}
                        onClick={handleSubmit((e) =>
                          currentStep === 1
                            ? this.handleSaveForLater(e, 'continue', currentStep)
                            : currentStep === 2
                            ? this.onGoToStep3()
                            : this.handleCreateAccount(e),
                        )}
                      >
                        {currentStep === 3 ? renderBtnText() : 'Continue'}
                      </Button>
                    </div>
                  </form>
                </FormWrapper>
              </div>
            </Card>
          </PrivateGrid>
        </Content>

        <ModalWrapper
          loading={loading}
          responseSuccess={responseSuccess}
          responseError={responseError}
          disabled={invalid}
          isOpen={showAddLocationsModal}
          onDiscard={this.handleLocationsCloseModal}
          onHandleSubmit={handleSubmit(this.handleSubmitCompanyLocationForm)}
          title={`${locationsModalType === 'add' ? 'Add' : 'Edit'} location`}
          primaryBtnText="Save"
          secondaryBtnText="Cancel"
          onSecondarySubmit={this.handleLocationsCloseModal}
        >
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Name</FormLabel>
              <Field
                name="locationName"
                component={renderField}
                type="text"
                defaultValue={locationName}
                placeholder="Company name / Headquarter / Subsidiary"
                onChange={onChangeLocationName}
                validate={[
                  formValidations.minLength2,
                  formValidations.maxLength30,
                  formValidations.requiredField,
                  formValidations.isHTMLtagContent,
                ]}
              />
            </FormGroup>
            <AddressFields {...this.props} />
          </form>
        </ModalWrapper>

        <AddACompanyModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={this.setOpenDeleteModal}
          url={this.constructURL()}
          onContinue={() => {
            redirectTo(null, '/admin/company-listing');
          }}
        />
      </React.Fragment>
    );
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    onChangeName: (evt) => dispatch(actions.changeName(evt.target.value)),
    onChangeBrand: (evt) => dispatch(actions.changeBrand(evt.target.value)),
    onChangeRegisteredNumber: (evt) => dispatch(actions.changeRegisteredNumber(evt.target.value)),
    onChangeVatNumber: (evt) => dispatch(actions.changeVatNumber(evt.target.value)),
    onChangeIndustry: (evt) => dispatch(actions.changeIndustry(evt)),
    onChangeCompanyType: (evt) => dispatch(actions.changeCompanyType(evt)),
    onChangeCompanyCultures: (evt) => dispatch(actions.selectTagCompanyCultures(evt)),

    onChangeLinkedInProfile: (evt) => dispatch(actions.changeLinkedInProfile(evt.target.value)),
    onChangeGithubProfile: (evt) => dispatch(actions.changeGithubProfile(evt.target.value)),
    onChangeStackoverflowProfile: (evt) => dispatch(actions.changeStackoverflowProfile(evt.target.value)),
    onChangeDribbleProfile: (evt) => dispatch(actions.changeDribbleProfile(evt.target.value)),
    onChangeBehanceProfile: (evt) => dispatch(actions.changeBehanceProfile(evt.target.value)),
    onChangeGlassdoorURL: (evt) => dispatch(actions.changeGlassdoorURL(evt.target.value)),
    onChangeCorporateWebsiteUrl: (evt) => dispatch(actions.changeCorporateWebsiteUrl(evt.target.value)),
    onChangePersonalProfile: (evt) => dispatch(actions.changePersonalProfile(evt.target.value)),

    onSaveForLater: (evt, data, type, onSuccess) => {
      if (evt !== undefined && evt.preventDefault) {
        evt.preventDefault();
      }
      dispatch(actions.submitAddACompanyForm(type, data, onSuccess));
    },
    onSubmitAddACompanyForm: (evt, data, type, onSuccess) => {
      if (evt !== undefined && evt.preventDefault) {
        evt.preventDefault();
      }
      dispatch(loadRepos());
      dispatch(actions.submitAddACompanyForm(type, data, onSuccess));
    },
    onChangeInvitedUsername: (evt) => dispatch(actions.onChangeInvitedUsername(evt.target.value)),
    onChangeInvitedUserEmail: (evt) => dispatch(actions.onChangeInvitedUserEmail(evt.target.value)),
    onChangeDomain: (evt) => dispatch(actions.onChangeDomain(evt.target.value)),
    onChangeLocationName: (evt) => dispatch(actions.changeLocationName(evt.target.value)),
    onChangePostcode: (evt) => dispatch(actions.changePostcode(evt.target.value)),
    onChangeAddressLineOne: (evt) => dispatch(actions.changeAddressLineOne(evt.target.value)),
    onChangeAddressLineTwo: (evt) => dispatch(actions.changeAddressLineTwo(evt.target.value)),
    onChangeCity: (evt) => dispatch(actions.changeCity(evt.target.value)),
    onChangeCountry: (evt) => dispatch(actions.changeCountry(evt)),
    onChangeState: (evt) => dispatch(actions.changeState(evt.target.value)),
    onChangeTimeZone: (evt) => dispatch(actions.changeTimeZone(evt)),
    onChangeBio: (data) => dispatch(actions.changeBio(data)),
    // onSaveForLater: () => dispatch(actions.saveForLater('saveForLater')),
    onSubmitAddCompanyLocationForm: (evt, type, data, onSuccess) => {
      if (evt !== undefined && evt.preventDefault) {
        evt.preventDefault();
      }
      dispatch(loadRepos());
      dispatch(actions.submitAddCompanyLocationForm(type, data, onSuccess));
    },
    onSubmitCreateAccount: (evt, data, onSuccess) => {
      if (evt !== undefined && evt.preventDefault) {
        evt.preventDefault();
      }
      dispatch(loadRepos());
      dispatch(actions.submitCreateAccount(data, onSuccess));
    },
  };
}
const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  responseSuccess: makeSelectSuccess(),
  responseError: makeSelectError(),
  name: selectors.makeSelectName(),
  brand: selectors.makeSelectBrand(),
  registeredNumber: selectors.makeSelectRegisteredNumber(),
  vatNumber: selectors.makeSelectVatNumber(),
  industry: selectors.makeSelectIndustry(),
  companyType: selectors.makeSelectCompanyType(),
  cultures: selectors.makeSelectCompanyCultures(),
  linkedInUrl: selectors.makeSelectLinkedInProfile(),
  gitHubUrl: selectors.makeSelectGithubProfile(),
  stackOverFlowUrl: selectors.makeSelectStackoverflowProfile(),
  dribbbleUrl: selectors.makeSelectDribbleProfile(),
  behanceUrl: selectors.makeSelectBehanceProfile(),
  portfolioUrl: selectors.makeSelectPersonalProfile(),
  glassdoorUrl: selectors.makeSelectGlassdoorUrl(),
  corporateWebsiteUrl: selectors.makeSelectCorporateWebsiteUrl(),
  locationName: selectors.makeSelectLocationName(),
  postcode: selectors.makeSelectPostcode(),
  addressLineOne: selectors.makeSelectAddressLineOne(),
  addressLineTwo: selectors.makeSelectAddressLineTwo(),
  city: selectors.makeSelectCity(),
  country: selectors.makeSelectCountry(),
  state: selectors.makeSelectState(),
  timezone: selectors.makeSelectTimeZone(),
  invitedUserEmail: selectors.makeInvitedUserEmail(),
  invitedUsername: selectors.makeInvitedUsername(),
  domain: selectors.makeDomain(),
  bio: selectors.makeBio(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  reduxForm({
    form: key,
    touchOnChange: true,
  }),
)(AddACompanyPage);
