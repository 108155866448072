/**
 * Client Sig-up widget Page
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { sha256 } from 'js-sha256';
import { FormGroup, Tooltip } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { reduxForm, Field } from 'redux-form';
import * as normalize from 'utils/normalize';
import queryString from 'query-string';
import { getBtnClass } from 'containers/Auth/PersonalDetails/utils';
import { renderField } from 'utils/Fields';
import * as formValidations from 'utils/formValidations';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { loadRepos } from 'containers/App/actions';
import { makeSelectLoading } from 'containers/App/selectors';
import { OnBoardingFormBody, Button } from 'components';
import { defaultProps, propTypes } from 'containers/proptypes';
import { signUpEmail, signUpPassword, signUp } from 'containers/Auth/SignUp/actions';
import { makeSelectEmail, makeSelectPassword } from 'containers/Auth/SignUp/selectors';
import reducer, { initialState } from 'containers/Auth/SignUp/reducer';
import saga from 'containers/Auth/SignUp/saga';
import { key } from 'containers/Auth/SignUp/constants';
import { PasswordFieldWrapper } from 'containers/Auth/SignUp/signup-styles';
import { passwordTooltip } from 'containers/Auth/SignUp/utils';
import { get } from 'lodash';
import { OnboardingFormContainer } from './styles';

export class ClientSignupWidget extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const params = queryString.parse(location.search);

    this.state = {
      queryParams: params,
      statePassword: '',
      tooltipOpen: false,
    };
  }

  handleChangePassword = (data) => {
    this.setState({ statePassword: data });
  };

  setToolTipOpen = (data) => {
    this.setState({ tooltipOpen: data });
  };

  render() {
    const { handleSubmit, onSubmitForm, email, password, onChangeEmail, onChangePassword, loading } = this.props;
    const { statePassword, tooltipOpen, queryParams } = this.state;

    return (
      <>
        <form onSubmit={handleSubmit((e) => onSubmitForm(e))}>
          <OnboardingFormContainer>
            <OnBoardingFormBody className="m-0">
              <>
                <FormGroup>
                  <Field
                    name="email"
                    className="client-widget-input-box"
                    type="text"
                    component={renderField}
                    placeholder="Work email"
                    value={email}
                    onChange={(e) => onChangeEmail(e.target.value)}
                    normalize={normalize.trimSpace}
                    validate={[formValidations.required, formValidations.email, formValidations.isCompanyEmailAdress]}
                    wrapperClassName="no-icon"
                  />
                </FormGroup>
                <FormGroup>
                  <PasswordFieldWrapper>
                    <div className="w-100">
                      <Field
                        className="client-widget-input-box"
                        name="password"
                        type="password"
                        component={renderField}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          onChangePassword(e);
                          this.handleChangePassword(e.target.value);
                        }}
                        normalize={normalize.trimSpace}
                        validate={[formValidations.required, formValidations.password]}
                        onFocus={() => this.setToolTipOpen(true)}
                        onclick={() => this.setToolTipOpen(true)}
                        onBlur={() => this.setToolTipOpen(false)}
                        id="tooltipPassword"
                        errorMessageToShow={!tooltipOpen}
                        wrapperClassName="no-icon"
                      />
                    </div>
                    <div className="tooltip-wrapper">
                      <span id="tooltipPassword" />
                      <Tooltip placement="right" className="d-none d-md-block" isOpen={tooltipOpen} target="tooltipPassword">
                        {passwordTooltip(statePassword)}
                      </Tooltip>
                    </div>
                  </PasswordFieldWrapper>
                </FormGroup>
                <div className="mt-4">
                  <Button className={`${getBtnClass(loading)} btn-client-widget w-100`} type="submit">
                    {get(queryParams, 'btnCaption', '') ? get(queryParams, 'btnCaption') : 'SIGN UP NOW'}
                  </Button>
                </div>
              </>
            </OnBoardingFormBody>
          </OnboardingFormContainer>
        </form>
      </>
    );
  }
}

ClientSignupWidget.defaultProps = defaultProps;
ClientSignupWidget.propTypes = propTypes;

export function mapDispatchToProps(dispatch) {
  return {
    onChangeEmail: (value) => dispatch(signUpEmail(value)),
    onChangePassword: (evt) => {
      const passwordHashed = sha256(evt.target.value);
      dispatch(signUpPassword(passwordHashed));
    },
    onSubmitForm: (evt) => {
      if (evt !== undefined && evt.preventDefault) {
        evt.preventDefault();
      }
      dispatch(loadRepos());
      dispatch(signUp());
    },
  };
}

const mapStateToProps = createStructuredSelector({
  email: makeSelectEmail(),
  password: makeSelectPassword(),
  loading: makeSelectLoading(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  reduxForm({
    form: key,
    initialValues: initialState,
    touchOnChange: true,
  }),
)(ClientSignupWidget);
