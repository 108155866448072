import styled from 'styled-components';
import { H3, Card } from 'components';
import { primaryDarkNew, primaryNew, white, dangerNew, black, darkGrayNew } from 'themes/variables';
import media from 'themes/media';
import { Dropdown, Navbar, Container, DropdownToggle, DropdownMenu, NavbarToggler } from 'reactstrap';
import Img from 'components/Img';

export const HeaderActions = styled.div`
  margin: 0 6px;

  ${media.large`
    margin: 0 12px;
  `}

  .btn {
    &.btn-sm {
      padding: 0px 10px;

      ${media.large`
      padding: 10px 20px;
  `}
    }
  }
`;

export const ProfileImg = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(${white});

  svg {
    path {
      stroke: rgba(${primaryDarkNew}, 0.5);
    }
  }
`;

export const ProfileImgModal = styled.div`
  width: ${(props) => (props.width ? props.width : '36px')};
  height: ${(props) => (props.height ? props.height : '36px')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.bgColor ? 'transparent' : `rgba(${darkGrayNew}, 0.2)`)};
  svg {
    width: 21px;
    height: 21px;
    path {
      stroke: rgba(${primaryDarkNew}, 0.5);
    }
  }
`;

export const NotificationsWrapper = styled(Dropdown)`
  .btn-secondary {
    background-color: transparent;
    border: none;
    padding: 0;
    &:hover,
    &:focus,
    &:not(:disabled):not(:disabled):active {
      background-color: transparent;
      box-shadow: none;
    }
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: none;
    }
  }
  .dropdown-menu {
    box-shadow: 0 2px 14px rgba(${black}, 0.07);
    border: none;
    border-radius: 3px;
    margin-top: 24px;
    padding: 0;
    width: 320px;

    ${media.medium`
    transform: translate3d(-545px, 32px, 0px) !important;
      left: 4px !important;
      width: 580px;
      &:after {
        content: '';
        position: absolute;
        top: -8px;
        right: 8px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 8px solid rgb(${white});
      }
    `};

    .notification-header {
      padding: 20px 20px 0;
      ${media.medium`
        padding: 30px 30px 0;
      `};
      .inner-block {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid rgb(${primaryNew}, 0.1);
        button.mark-read-btn {
          color: rgb(${primaryNew});
          font-family: 'GT-Walsheim-Pro-Regular';
          background: none;
          border: none;
          font-size: 14px;
          outline: none;
        }
      }
    }
    .notification-body {
      max-height: 320px;
      overflow-y: auto;
      padding: 15px;
      ${media.large`
        max-height: 420px;
      `};
      ${media.extraLarge`
        max-height: 781px;
      `};
      .dropdown-item {
        padding: 10px;
        white-space: normal;
        border-bottom: 1px solid rgb(${primaryNew}, 0.1);
        outline: none;

        ${media.medium`
          padding: 15px;
        `};

        &:hover,
        &:active,
        &.active,
        &:focus {
          color: initial;
          background: rgba(${primaryNew}, 0.05);
        }

        &.unread {
          position: relative;
          &:after {
            content: '';
            width: 6px;
            height: 6px;
            background: rgb(${dangerNew});
            position: absolute;
            bottom: 10px;
            right: 10px;
            border-radius: 100%;
            ${media.medium`
              width: 10px;
              height: 10px;
              bottom: 15px;
              right: 15px;
            `};
          }
        }
        ${ProfileImg} {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          svg,
          img {
            width: 30px;
            height: 30px;
            ${media.medium`
              width: 40px;
              height: 40px;
            `};
          }
          ${media.medium`
            width: 40px;
            height: 40px;
            margin-right: 15px;
          `};
        }
        .action-item {
          svg {
            width: 16px;
            height: 16px;
            path {
              stroke: rgb(${primaryNew});
            }
          }
        }
      }
    }
  }
`;

export const HamburgerToggle = styled(NavbarToggler)`
  display: none;
  border: 0;
  padding: 0;
  margin-right: 10px;
  height: 9px;
  /* top: -3px;
  position: relative; */

  ${media.large`
    margin-right: 30px;
    /* top:0; */
  `};

  .private-layout & {
    display: block;
  }

  &:focus {
    outline: none;
  }
  &.navbar-toggler {
    &:focus {
      box-shadow: none;
    }

    .navbar-toggler-icon {
      position: relative;
      height: 100%;
      width: 16px;
      border-top: 2px solid rgb(${primaryDarkNew}, 0.5);
      border-bottom: 2px solid rgb(${primaryDarkNew}, 0.5);
      background: none;

      &:after {
        content: '';
        width: 10px;
        border-top: 2px solid rgb(${primaryDarkNew}, 0.5);
        bottom: -8px;
        position: absolute;
        left: 0;
      }
    }
  }
`;

export const HeaderNav = styled.div`
  flex: 0;
  z-index: 3;
`;

export const FixedNavbar = styled(Navbar)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 0;
  box-shadow: 0 3px 3px rgba(${black}, 0.05) !important;
  background: rgb(${white});
  color: #fff;

  ${media.medium`
    /* padding: 26px 0; */
    box-shadow: none;
  `};

  &.fixed-nav {
    position: fixed !important;
  }

  &.absolute-nav {
    position: absolute !important;
  }

  .private-layout,
  .public-layout,
  & {
    box-shadow: 0 3px 3px rgba(${black}, 0.05);
    ${media.medium`
    box-shadow: none !important;
    left: 265px;
    background: transparent;
    `}

    ${media.extraLarge`
      left: 260px;
    `};

    &.sidebar-close {
      left: 0px;
      ${media.medium`
        left: 90px;
      `}

      .search-bar {
        display: flex;
      }
    }
    &.sidebar-open {
      ${HamburgerToggle} {
        &.navbar-toggler {
          .navbar-toggler-icon {
            &:after {
              width: 16px;
            }
          }
        }
      }

      .search-bar {
        display: none;

        ${media.large`
        display: flex;
      `}
      }
    }
    .cm-logo {
      display: block;
      margin-bottom: 15px;
      ${media.medium`
      display: none;
      `}
    }
  }
`;

export const UserImg = styled(Img)`
  width: 100%;
  margin: 0 auto;
  display: block;
  background: rgba(${white});
`;

export const UserImgModal = styled(Img)`
  width: 100%;
  border-radius: 10px;
  margin: 0 auto;
  display: block;
  background: rgba(${white});
`;

export const Image = styled.div`
  width: 30px;
  height: 30px;
  padding: 0;
`;

export const UserProfileToggle = styled(DropdownToggle)`
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 10px;
  border: 3px solid transparent;
  background-color: transparent;
  transition: all 0.6s linear;
  overflow: hidden;

  ${media.medium`
    display: block;
  `}

  svg {
    width: 22px;
    height: 22px;
    path {
      stroke: rgba(${primaryDarkNew}, 0.5);
    }
  }

  &:hover,
  &:focus,
  &:not(:disabled):not(:disabled):active {
    border-color: rgba(${primaryDarkNew}, 0.1);
    background-color: rgba(${white}, 0.15);
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: none;
  }

  .user-logo {
    width: 100%;
    margin: 0 auto;
    display: block;
    background: rgba(${white});
  }
`;

export const SecondaryNav = styled.div`
  align-items: center;
  display: flex;
  margin-right: 0px;
  position: relative;

  & .nav-link {
    display: flex;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding: 0;
    margin-right: 10px;
    display: flex;
    align-items: center;
    background: transparent;
    border-radius: 100%;

    svg {
      path {
        stroke: rgba(${primaryDarkNew}, 0.5);
      }
    }

    ${media.large`
      margin-right: 14px;
      width: 40px;
      height: 40px;
    `};

    &.noty-dot {
      position: relative;
      &:after {
        content: '';
        width: 14px;
        height: 14px;
        background: rgb(${dangerNew});
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 100%;
        border: 2px solid rgb(${white});
      }
    }

    &:hover {
      background: rgba(${primaryDarkNew}, 0.05);
    }

    &:last-child {
      margin-right: 0;
    }

    & svg {
      width: 22px;
      height: 22px;
    }
  }
`;

export const UserProfileMenu = styled(DropdownMenu)`
  width: 260px;
  max-height: 365px;
  overflow-y: auto;
  outline: none;
  border: none;
  background: transparent;
  margin-top: 4px;

  .inner-block {
    background: rgb(${white});
    border-radius: 10px;
    border: 1px solid rgb(${primaryNew}, 0.1);
    box-shadow: 0px 5px 20px rgba(${primaryDarkNew}, 0.08);
    padding: 15px 30px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: -8px;
      right: 15px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 8px solid rgb(${white});
    }
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      right: 14px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 9px solid rgb(${primaryNew}, 0.1);
    }
  }

  & .dropdown-item {
    font-family: 'GT-Walsheim-Pro-Regular';
    font-size: 16px;
    line-height: 18px;
    color: rgba(${primaryDarkNew}, 0.3);
    padding: 15px 0;
    display: flex;
    align-items: center;

    svg {
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }

    svg {
      path,
      circle {
        stroke: rgba(${primaryDarkNew}, 0.3);
      }
    }

    &:hover {
      color: rgb(${primaryDarkNew});
      svg {
        path,
        circle {
          stroke: rgb(${primaryDarkNew});
        }
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;
      color: rgb(${primaryDarkNew});
    }

    &:focus {
      outline: none;
    }

    &:last-child {
      border: 0;
    }
  }
`;

export const StartHiringMenu = styled(DropdownMenu)`
  width: 260px;
  max-height: 365px;
  overflow-y: auto;
  outline: none;
  border: none;
  background: transparent;
  margin-top: 4px;

  .inner-block {
    background: rgb(${white});
    border-radius: 10px;
    border: 1px solid rgb(${primaryNew}, 0.1);
    box-shadow: 0px 5px 20px rgba(${primaryDarkNew}, 0.08);
    position: relative;
    padding: 5px 10px;
  }

  & .dropdown-item {
    padding: 10px 20px;
    font-family: 'GT-Walsheim-Pro-Regular';
    font-size: 16px;
    line-height: 18px;
    color: rgba(${primaryDarkNew}, 0.3);
    display: flex;
    align-items: center;

    svg {
      margin-right: 15px;
    }

    &:hover,
    &:focus {
      border-radius: 5px;
      background-color: rgb(${primaryNew}, 0.05);
      color: rgb(${primaryDarkNew});
    }

    &:focus {
      outline: none;
    }

    &:last-child {
      border: 0;
    }
  }
`;

export const NavbarContainer = styled(Container)`
display: flex;
justify-content: space-between;
  grid-template-areas:
    'logo header'
    'search search'
    'page page';
  align-items: start !important;
  row-gap: 10px;
  ${media.medium`
  grid-template-areas: 'page header';
    align-items: center !important;
  `};

  .logo {
    height: 36px;
    grid-area: logo;
    grid-row: 1;
  }
  .page {
    grid-area: page;
    grid-row: 3;

    ${media.medium`
      grid-row: 1
    `}
  }
  .search {
    grid-area: search;
    grid-row: 2;
    width: 100%;
  }
  .header {
    grid-area: header;
    grid-row: 1;
    position: fixed;
    top: 16px;
    right: 16px;
    ${media.medium`
      top: auto;
      right: auto;
      position: relative;
    `}
  }
  .headway {
    font-size: 0;
    align-self: center;
    margin: 15px;
    margin-right: 19px !important;
    &.right{
      .HW_right{
      right: -12px;
      }
    }
    .btn{
      padding:6px;
      height:auto;
      min-width:80px;
    }
    span{
      right:47px;
      height:28px;
      bottom:0px;
        .HW_badge{
          left: -28px;
          top: -9px;
          border-radius:50px;
          width: 133px;
          height: 46px;
          background-color: transparent;
          font-size: 16px;
          padding-top: 10px;
      }
    }
  }
  }
`;

export const PageTitleContainer = styled.div`
  h1 {
    font-size: 25px;

    ${media.medium`
    font-size: 20px;
     
     `}

    ${media.large`
    font-size: 34px;
     
     `}
  }

  grid-area: 'first';
  grid-row: 1;
`;

export const TalentHeaderContainer = styled.div`
  justify-content: flex-end;
  ${media.medium`
  grid-row: 1
    `}
`;

export const ProfileNavBarCloseButton = styled.button`
  background: none;
  border: 0;
  outline: 0;
  padding: 0;
`;

export const ProfileNavModalContainer = styled.div`
  .nav {
    flex-direction: column;
    margin-top: 35px;

    .nav-item {
      margin-bottom: 0;

      .nav-link {
        display: flex;
        align-items: center;
        padding: 0;
        color: rgba(${primaryDarkNew}, 0.3);
        font-family: 'GT-Walsheim-Pro-Regular';
        font-size: 20px;
        position: relative;
        min-height: 50px;

        ${media.medium`
            font-size: 16px;
        `};

        &:hover,
        &.active {
          cursor: pointer;
          color: rgb(${primaryDarkNew});
          .icon {
            path,
            circle {
              stroke: rgb(${primaryDarkNew});
            }
          }
        }
        &.active {
          &:after {
            content: '';
            width: 3px;
            height: 100%;
            background-color: rgb(${primaryNew});
            position: absolute;
            left: -2px;
            top: 0;
            bottom: 0;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
          color: rgb(${primaryNew}) !important;
        }

        .icon {
          display: flex;
          margin-right: 16px;
          position: relative;

          svg {
            width: 21px;
            height: 21px;
          }

          ${media.medium`
            svg {
              width: 15px;
              height: 15px;
            }
          `};

          ${media.extraLarge`
            svg {
              width: 20px;
              height: 20px;
            }
          `};

          path,
          circle {
            stroke: rgba(${primaryDarkNew}, 0.3);
          }
        }

        .menu-option {
          position: relative;
        }
      }
    }
  }
`;

export const ProfileTitle = styled(H3)`
  font-size: 24px;
  color: rgb(${black});
`;

export const OpenToWorkCard = styled(Card)`
  border-radius: 20px;
  padding: 12px;
  margin-top: 30px;
  height: 36px;

  &.dense {
    padding: 12px;
  }
`;
