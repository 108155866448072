import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import { userProfileIcon, trashIcon, logoPlaceholder, cloudUploadIcon, projectPlaceholderSM, editNoteIcon } from 'containers/App/constants';
import { P, Button } from 'components';
import containerMessage from 'containers/messages';
import messages from './messages';
import { DropArea, InnerDropArea, ProfileImage } from './style';
import { fileMinSize, fileMaxSize, acceptedFileTypes, dropAreaMessage } from './utils';

export const getPictureDropZone = ({ that, type, maxFiles = 1, isDisable = true, required = false, key = 'image' }) => {
  let dropzoneRef;
  const { image, banner, certificateImage, defaultLogo } = that.state;
  const imagePlaceholder =
    type === 'certificateUploader'
      ? null
      : type === 'profilePhotoUploader'
      ? userProfileIcon
      : type === 'competitionBannerUploader'
      ? projectPlaceholderSM
      : logoPlaceholder;

  const imageTorRender = type === 'certificateUploader' ? certificateImage : key === 'image' ? image : banner;

  return (
    <Dropzone
      id="dropZone"
      ref={(node) => {
        dropzoneRef = node;
      }}
      minSize={fileMinSize(type)}
      maxSize={fileMaxSize(type)}
      onDrop={(acceptedFiles, rejectedFiles) => that.onDrop(acceptedFiles, rejectedFiles, type, key)}
      accept={acceptedFileTypes(type)}
      noClick
      noKeyboard
      multiple={maxFiles !== 1}
      maxFiles={maxFiles}
      disabled={!isDisable}
    >
      {({ getRootProps, getInputProps }) => (
        <DropArea {...getRootProps()} className={`${imageTorRender ? 'file-uploaded' : 'no-file'} ${required ? 'required' : ''}`}>
          <input {...getInputProps()} />
          <div className={`${type === 'multiplePhotosUploader' ? 'justify-content-center' : ''} d-flex align-items-center`}>
            {type !== 'multiplePhotosUploader' && (
              <ProfileImage type={type} className={imageTorRender ? 'has-img' : ''}>
                {imageTorRender ? <img src={imageTorRender} className="img-fluid" alt="img" /> : <SVG src={imagePlaceholder} />}
              </ProfileImage>
            )}
            {(!imageTorRender || type === 'multiplePhotosUploader') && (
              <InnerDropArea className={`${type === 'multiplePhotosUploader' ? 'ms-0 text-center' : ''} d-flex`}>
                <div className="d-flex align-items-start justify-content-center justify-content-md-start">
                  <div className="drop-content d-flex">
                    <SVG src={cloudUploadIcon} className="d-none d-md-inline cloud-upload" />
                    <div className="ms-3">
                      <P className="p16" opacityVal="0.5">
                        {type === 'multiplePhotosUploader' ? (
                          <FormattedMessage {...messages.dragAndDropImgs} />
                        ) : (
                          <FormattedMessage {...messages.dragAndDrop} />
                        )}
                        <Button
                          btnClassName="mx-1 btn-link"
                          disabled={!isDisable}
                          onClick={(e) => {
                            e.preventDefault();
                            dropzoneRef.open();
                          }}
                        >
                          <FormattedMessage {...containerMessage.browseButton} />
                        </Button>
                        to upload
                      </P>
                      <P className="p12 mb-0" opacityVal="0.5">
                        {dropAreaMessage(type)}
                      </P>
                    </div>
                  </div>
                </div>
              </InnerDropArea>
            )}
            {imageTorRender && type === 'competitionBannerUploader' && (
              <Button
                className="btn-icon btn-link ms-auto"
                onClick={(e) => {
                  e.preventDefault();
                  dropzoneRef.open();
                }}
              >
                <SVG src={editNoteIcon} className="me-1" />
                Edit
              </Button>
            )}
            {imageTorRender && type !== 'multiplePhotosUploader' && type !== 'competitionBannerUploader' && (
              <Button
                className="btn-icon btn-link ms-auto"
                onClick={(e) => {
                  e.preventDefault();
                  that.deletePhoto(key);
                }}
                disabled={defaultLogo}
              >
                <SVG src={trashIcon} className="me-1" />
                Delete
              </Button>
            )}
          </div>
        </DropArea>
      )}
    </Dropzone>
  );
};

getPictureDropZone.defaultProps = {
  that: '',
  type: '',
  maxFiles: 1,
  isDisable: true,
  required: true,
};
getPictureDropZone.propTypes = {
  that: PropTypes.any,
  type: PropTypes.string,
  maxFiles: PropTypes.any,
  isDisable: PropTypes.bool,
  required: PropTypes.bool,
};
