/**
 SignupFields
 */

import React, { Component } from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Tooltip } from 'reactstrap';
import { renderField, renderFieldoptCheckbox } from 'utils/Fields';
import * as formValidations from 'utils/formValidations';
import * as normalize from 'utils/normalize';
import SVGIcon from 'components/SVGIcon';
import { getBtnClass } from 'containers/Auth/PersonalDetails/utils';
import { P, A, Button } from 'components';
import { defaultProps, propTypes } from 'containers/proptypes';
import { primaryNew, white } from 'themes/variables';
import {
  TERMS_CONDITION_URL,
  CM_CONTACT_SALES_URL,
  arrowRightIcon,
  linkedinNoBGIcon,
  LINKEDIN_CLIENT_ID,
  LINKEDIN_REDIRECT_URI,
} from 'containers/App/constants';
import { loginLink, recruiterLoginLink, ambassadorLoginLink } from 'containers/App/utils';
import messages from './messages';
import { passwordTooltip } from './utils';
import { PasswordFieldWrapper } from './signup-styles';

const generateRandomString = (length = 20) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const LinkedInSignup = ({ text }) => {
  const linkedInLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${LINKEDIN_REDIRECT_URI}&state=${generateRandomString()}&scope=${encodeURI(
        'openid profile email',
      )}`,
      '_self',
    );
  };

  return (
    <Button onClick={linkedInLogin} className="btn-linkedin w-100 d-flex align-items-center justify-content-center">
      <SVGIcon className="me-3 mb-1" src={linkedinNoBGIcon} iconColor={`rgb(${white})`} pathFillColor={`rgb(${white})`} />
      <span>{text}</span>
    </Button>
  );
};
export class SignupFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statePassword: '',
      tooltipOpen: false,
    };
  }

  handleChangePassword = (data) => {
    this.setState({ statePassword: data });
  };

  getLoginLink = () => {
    const { isRecruiter, isAmbassador } = this.state;
    let link = loginLink;
    if (isRecruiter) {
      link = recruiterLoginLink;
    }
    if (isAmbassador) {
      link = ambassadorLoginLink;
    }
    return link;
  };

  setToolTipOpen = (data) => {
    this.setState({ tooltipOpen: data });
  };

  privacyPolicyButton = (
    <>
      {messages.privacyPolicy1.defaultMessage}
      <A href={TERMS_CONDITION_URL} target="_blank" className="ms-1 me-1 text-decoration-underline">
        <FormattedMessage {...messages.privacyPolicy12} />
      </A>
    </>
  );

  render() {
    const {
      btnName,
      email,
      password,
      loading,
      responseSuccess,
      responseError,
      onChangeEmail,
      onChangePassword,
      invalid,
      privacyCheck,
      onPrivacyPolicyCheck,
      disableEmail,
      isAgency,
      signupWidget,
      talentActive,
      location = {},
    } = this.props;
    const { statePassword, tooltipOpen } = this.state;
    const query = new URLSearchParams(location.search);
    let btnCaption = messages.signupReferralButton.defaultMessage;
    if (query.get('btnCaption')) {
      btnCaption = query.get('btnCaption');
    }

    let emailValidation = [formValidations.required, formValidations.email];
    if (!talentActive) {
      emailValidation = [...emailValidation, formValidations.isCompanyEmailAdress];
    }

    return (
      <>
        <FormGroup>
          <Field
            name="email"
            className={signupWidget ? 'widget-input-box' : ''}
            type="text"
            component={renderField}
            placeholder="Email"
            value={email}
            disabled={disableEmail}
            onChange={(e) => onChangeEmail(e.target.value)}
            normalize={normalize.trimSpace}
            validate={emailValidation}
            wrapperClassName="no-icon"
          />
        </FormGroup>
        <FormGroup>
          <PasswordFieldWrapper>
            <div className="w-100">
              <Field
                className={signupWidget ? 'widget-input-box' : ''}
                name="password"
                type="password"
                component={renderField}
                placeholder={messages.placeholderPassword.defaultMessage}
                value={password}
                onChange={(e) => {
                  onChangePassword(e);
                  this.handleChangePassword(e.target.value);
                }}
                normalize={normalize.trimSpace}
                validate={[formValidations.required, formValidations.password]}
                onFocus={() => this.setToolTipOpen(true)}
                onclick={() => this.setToolTipOpen(true)}
                onBlur={() => this.setToolTipOpen(false)}
                id="tooltipPassword"
                errorMessageToShow={!tooltipOpen}
                wrapperClassName="no-icon"
              />
            </div>
            <div className="tooltip-wrapper">
              <span id="tooltipPassword" />
              <Tooltip placement="bottom" className="d-none d-md-block" isOpen={tooltipOpen} target="tooltipPassword">
                {passwordTooltip(statePassword)}
              </Tooltip>
            </div>
          </PasswordFieldWrapper>
        </FormGroup>
        <P className="p14 mt-5 text-start" opacityVal="0.5">
          <small>
            <Field
              name="privacyPolicy"
              type="checkbox"
              component={renderFieldoptCheckbox}
              value={privacyCheck}
              onChange={(e) => onPrivacyPolicyCheck(e)}
              message={this.privacyPolicyButton}
              validate={[formValidations.checked]}
            />
          </small>
        </P>
        {!isAgency && (
          <div className="mt-4">
            <Button className={`${btnName} ${getBtnClass(loading, responseSuccess, responseError)} w-100`} disabled={invalid} id={btnName}>
              {signupWidget ? btnCaption : messages.signupButton.defaultMessage}
            </Button>

            {!signupWidget && (
              <>
                <P className="text-center my-2 p14">Or</P>
                <LinkedInSignup text="Sign up with LinkedIn" />
              </>
            )}

            {/* <LinkedIn
              scope="openid profile email"
              clientId={LINKEDIN_CLIENT_ID}
              redirectUri={LINKEDIN_REDIRECT_URI}
              onSuccess={(code) => {
                console.log(code);
              }}
              onError={(error) => {
                console.log(error);
              }}
            >
              {({ linkedInLogin }) => (
                <Button onClick={linkedInLogin} className="btn-linkedin w-100 d-flex align-items-center justify-content-center">
                  <SVGIcon className="me-3 mb-1" src={linkedinNoBGIcon} iconColor={`rgb(${white})`} pathFillColor={`rgb(${white})`} />
                  <span>Sign up with Linkedin</span>
                </Button>
              )}
            </LinkedIn> */}

            {!signupWidget ? (
              <>
                <P className="p14 mt-4">
                  <FormattedMessage {...messages.textHaveAccount} />
                  <A href={this.getLoginLink()} className="ms-1 text-decoration-underline">
                    <FormattedMessage {...messages.loginLink} />
                  </A>
                </P>
                {!talentActive ? (
                  <>
                    <hr className="mt-4 mb-3" />
                    <P className="p14 mt-4">
                      Want to discuss your global hiring needs?
                      <A href={CM_CONTACT_SALES_URL} className="ms-1">
                        Contact sales during sign up
                        <SVGIcon className="ms-1" src={arrowRightIcon} iconColor={`rgb(${primaryNew})`} />
                      </A>
                    </P>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        )}
      </>
    );
  }
}

SignupFields.defaultProps = defaultProps;
SignupFields.propTypes = propTypes;

export default SignupFields;
