/*
 * AddACompanyPage Messages
 *
 * This contains all the text for the AddACompanyPage component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.containers.AddACompanyPage';

export default defineMessages({
  title: {
    id: `${scope}.title.head`,
    defaultMessage: 'Add a company',
  },
  metaTitle: {
    id: `${scope}.metaTitle.head`,
    defaultMessage: 'Add a company',
  },
  addACompany: {
    id: `${scope}.addACompany`,
    defaultMessage: 'Add a company',
  },
  addACompanyTagLine: {
    id: `${scope}.addACompanyTagLine`,
    defaultMessage: 'Create a company account on behalf of client so that client can easily onboard.',
  },
  uploadCompanyLogo: {
    id: `${scope}.uploadCompanyLogo`,
    defaultMessage: 'Logo',
  },
  labelBrandName: {
    id: `${scope}.labelBrandName`,
    defaultMessage: 'Brand / Trading name',
  },
  labelVatTaxNumber: {
    id: `${scope}.labelVatTaxNumber`,
    defaultMessage: 'VAT / GST / Tax No',
  },
  placeholderVatTaxNumber: {
    id: `${scope}.placeholderVatTaxNumber`,
    defaultMessage: 'VAT (or Tax) No.',
  },
  titleOnlineProfiles: {
    id: `${scope}.titleOnlineProfiles`,
    defaultMessage: 'Online profiles',
  },

  labelAdd: {
    id: `${scope}.labelAdd`,
    defaultMessage: 'Add location',
  },
});
