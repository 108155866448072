// Lazy Loading for every components
import React, { lazy, Suspense } from 'react';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyWithRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(true); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

// Main loadable dynamic function
const loadable = (importFunc, { fallback = null } = { fallback: null }) => {
  const LazyComponent = lazy(() => lazyWithRetry(importFunc));

  return (props) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
