/**
 * ModalWrapper
 *
 * This is the code for the omnipresent ModalWrapper.
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Scrollbar } from 'react-scrollbars-custom';
import { closeIcon } from 'containers/App/constants';
import { Button, H2 } from 'components';
import { getBtnClass } from 'containers/Auth/PersonalDetails/utils';

export class ModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      disabled,
      isOpen,
      children,
      onHandleSubmit,
      onSecondarySubmit,
      onDiscard,
      title,
      loading,
      responseSuccess,
      responseError,
      primaryBtnText,
      secondaryBtnText,
      modalClassName,
      modalBodyClass,
      modalHeaderClass,
      modalContentClass,
      noFooter,
    } = this.props;
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onDiscard}
        className={`modal-dialog full-screen-modal ${modalClassName}`}
        style={{ overlay: { zIndex: 12 } }}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        ariaModal
      >
        <div className={`modal-content ${modalContentClass}`}>
          <div className={`modal-header ${modalHeaderClass}`}>
            <H2 className="modal-title newH2 d-flex align-items-center my-0">{title}</H2>
            <button type="button" className="modal-dismiss" onClick={onDiscard}>
              <img src={closeIcon} alt="close" />
              <span className="visually-hidden">Close</span>
            </button>
          </div>
          <Scrollbar className={`modal-body ${modalBodyClass}`}>{children}</Scrollbar>
          {!noFooter ? (
            <div className="modal-footer justify-content-between">
              {secondaryBtnText ? (
                <Button className="btn-sm btn-outline me-4 h-auto" type="button" onClick={onSecondarySubmit}>
                  {secondaryBtnText}
                </Button>
              ) : (
                ''
              )}
              <Button
                type="submit"
                className={`${getBtnClass(loading, responseSuccess, responseError)} btn-sm`}
                disabled={disabled}
                onClick={onHandleSubmit}
              >
                {primaryBtnText}
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      </ReactModal>
    );
  }
}

ModalWrapper.defaultProps = {
  disabled: false,
  isOpen: false,
  children: [],
  onHandleSubmit: () => {},
  onDiscard: () => {},
  onSecondarySubmit: () => {},
  title: '',
  loading: false,
  responseSuccess: false,
  responseError: false,
  noFooter: false,
  primaryBtnText: '',
  secondaryBtnText: '',
  modalClassName: '',
  modalBodyClass: '',
  modalHeaderClass: '',
  modalContentClass: '',
};

ModalWrapper.propTypes = {
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  noFooter: PropTypes.bool,
  children: PropTypes.any,
  onHandleSubmit: PropTypes.func,
  onDiscard: PropTypes.func,
  onSecondarySubmit: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  responseSuccess: PropTypes.bool,
  responseError: PropTypes.bool,
  primaryBtnText: PropTypes.string,
  secondaryBtnText: PropTypes.string,
  modalClassName: PropTypes.string,
  modalBodyClass: PropTypes.string,
  modalHeaderClass: PropTypes.string,
  modalContentClass: PropTypes.string,
};

export default ModalWrapper;
