/**
 * VerifyLinkedIn
 * This is the Login page for the App, at the '/linkedin' route
 */

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { defaultProps, propTypes } from 'containers/proptypes';
import { FormattedMessage } from 'react-intl';
import StorageService from 'utils/StorageService';
import { redirectTo } from 'containers/App/utils';
import { OnboardingForm, P, H1, OnBoardingFormBody, ContainerMod, Card, ToastifyMessage } from 'components';
import messages from './messages';
import { API_URL } from 'containers/App/constants';
import { toast } from 'react-toastify';
import request from 'utils/request';
import LoadingIndicator from 'components/LoadingIndicator';
import { compose } from '@reduxjs/toolkit';
import injectSaga from 'utils/injectSaga';
import saga from './saga';
import reducer from './reducer';
import { useAppDispatch } from 'hooks';
import injectReducer from 'utils/injectReducer';

export const key = 'linkedinLogin';

export const VerifyLinkedIn = () => {
  const [showPage, setShowPage] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const onSuccess = (code) => {
      const data = {
        method: 'POST',
        body: {
          code,
          userType: StorageService.get('selectedRoleType'),
        },
      };
      const requestURL = `${API_URL}/auth/linkedin-signin`;
      request(requestURL, data)
        .then((response) => {
          dispatch({
            type: 'LINKEDIN_LOGIN',
            payload: response,
          });
        })
        .catch(() => {
          toast.error(<ToastifyMessage message="something went wrong" type="error" />, { className: 'Toast-error' });
        });
    };

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const code = params.code;

    if (!code) {
      return redirectTo(null, '/');
    }
    setShowPage(true);
    onSuccess(code);

    return () => {};
  }, [dispatch]);

  if (!showPage) {
    return null;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{messages.title.defaultMessage}</title>
        <meta name="description" content={messages.metaTitle.defaultMessage} />
      </Helmet>
      <ContainerMod className={''}>
        <Card className={'p-0 bg-transparent'}>
          <OnboardingForm>
            <OnBoardingFormBody className="mx-auto">
              <>
                <H1 className="mb-3">
                  <FormattedMessage {...messages.headingVerifyLinkedin} />
                </H1>
                <P className="p16" opacityVal="0.5">
                  <FormattedMessage {...messages.textIntro} />
                </P>

                <LoadingIndicator />
              </>
            </OnBoardingFormBody>
          </OnboardingForm>
        </Card>
      </ContainerMod>
    </React.Fragment>
  );
};

VerifyLinkedIn.defaultProps = defaultProps;
VerifyLinkedIn.propTypes = propTypes;

const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });

export default compose(withSaga, withReducer)(VerifyLinkedIn);
