import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function LazyImage({ src, alt, placeholderSrc, className, wrapperClassName, ...props }) {
  return (
    <LazyLoadImage
      src={src}
      alt={alt}
      className={className}
      wrapperClassName={wrapperClassName}
      placeholderSrc={placeholderSrc}
      effect="blur"
      {...props}
    />
  );
}

export default LazyImage;
