import styled from 'styled-components';
import Slider from 'react-slick';
import { white, primaryNew, primaryDarkNew } from 'themes/variables';

export const JobWidgetSlider = styled(Slider)`
  max-width: 100%;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;

  .slick-list {
    padding: 0 !important;
  }

  .slick-dots {
    position: relative;
    bottom: 0;
    li {
      width: 50px !important;
      height: 6px !important;
      margin: 0 7px;

      @media (max-width: 767px) {
        width: 30px !important;
        margin: 0 4px;
      }

      &.slick-active {
        button {
          &:before {
            background: rgb(${primaryNew}, 0.3);
            opacity: 1;
          }
        }
      }
      button {
        width: 50px !important;
        height: 6px !important;

        @media (max-width: 767px) {
          width: 30px !important;
        }
        &:before {
          opacity: 1;
          background: rgb(${primaryNew}, 0.1);
          font-size: 0;
          width: 50px !important;
          height: 6px !important;
          border-radius: 30px;

          @media (max-width: 767px) {
            width: 30px !important;
          }
        }
      }
    }
  }
`;

export const WidgetCard = styled.div`
  padding: 28px;
  width: 100% !important;
  min-width: fit-content !important;

  @media (max-width: 767px) {
    padding: 28px 8px 28px 0 !important;
  }

  .inner-card-block {
    margin: 0 10px;
    border-radius: 10px;
    background: rgb(${white});
    padding: 30px;
    min-height: 446px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.03);
    }

    &:after {
      content: '';
      position: absolute;
      background: rgb(${white}, 0.5);
      border-radius: 10px;
      bottom: -12px;
      right: -12px;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    @media (max-width: 767px) {
      margin: 0;
    }

    @media (max-width: 1199px) {
      min-height: 383px;
    }
    @media (max-width: 600px) {
      min-height: 420px;
    }

    h4 {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 20px;
      color: rgb(${primaryDarkNew});
      font-family: 'Poppins', sans-serif;
    }
    h5 {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: rgb(${primaryDarkNew});
      font-family: 'Poppins', sans-serif;
      font-weight: 400;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .btn-outline {
      font-family: 'Poppins', sans-serif;
      height: 36px;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      padding: 9px 30px;
    }

    .job-tag {
      border-radius: 12px;
      font-size: 14px;
      line-height: 16px;
      padding: 4px 10px;
      text-align: center;
      color: rgb(${primaryDarkNew});
      margin-right: 8px;

      &.bg-info {
        background: linear-gradient(114.05deg, #fde5bf 0%, #ffceca 100%);
      }

      &.bg-primary {
        background: linear-gradient(118.83deg, #bfdeff 0%, #dcd7ff 100%);
      }
    }

    ul {
      list-style: none;
      display: inline-flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      margin-bottom: 16px;

      li {
        padding: 5px 10px;
        border: 1px solid rgb(${primaryDarkNew}, 0.2);
        border-radius: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        font-family: 'Poppins', sans-serif;
        margin-right: 10px;
        margin-bottom: 10px;
        align-self: flex-start;
        color: rgb(${primaryDarkNew});
        &:last-child {
          margin-right: 0;
        }

        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 12px;
          padding: 8px 15px;
        }
      }
    }
  }
`;
