import styled from 'styled-components';
import media from 'themes/media';
import { primaryDarkNew, primaryNew } from 'themes/variables';

const H2 = styled.h2`
  font-size: 22px;
  line-height: 25px;
  color: rgb(${primaryDarkNew});
  font-family: 'GT-Walsheim-Pro-Regular';
  margin-bottom: 20px;

  ${media.medium`
    font-size: 30px;
    line-height: 34px;
  `};
  &.color-primary {
    color: rgb(${primaryNew});
  }
`;

export default H2;
