/*
 * TalentCardHeader Component
 */

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SVG from 'react-inlinesvg';
import { CLIENT, circleTickIcon, logoPlaceholder, plusIcon } from 'containers/App/constants';
import { Button, P, SVGIcon } from 'components';
import containerMessage from 'containers/messages';
import history from 'utils/history';
import LazyImage from 'components/LazyImage';
import { primaryNew } from 'themes/variables';
import { getUserRegisterType } from 'utils/Helper';
function TalentCardHeader(props) {
  const { data, showInterviewTalent, loading, setSaveToAList, isSubscribed, SubscriptionModalRef } = props;

  const registerType = getUserRegisterType();

  return (
    <div className="d-flex justify-content-between">
      {get(data, 'profilePicture') ? (
        <LazyImage
          src={`${get(data, 'profilePicture')}?_t=${new Date().getTime()}`}
          alt={get(data, 'name')}
          placeholderSrc={logoPlaceholder}
          className="image-container"
          wrapperClassName="image-container"
        />
      ) : (
        <SVG src={logoPlaceholder} className="image-container" />
      )}

      {showInterviewTalent && (
        <Button
          disabled={loading}
          className="btn btn-sm btn-outline d-inline d-md-none"
          onClick={(e) => {
            if (!isSubscribed) {
              return SubscriptionModalRef.current.handleSubscriptionPlanOpenModal();
            }

            history.push(`${CLIENT}/schedule-interview`, {
              data: {
                ...data,
                talentId: get(data, 'talentId', get(data, '_id', get(data, 'talentUserId', ''))),
              },
            });
          }}
        >
          <span>{containerMessage.btnInterview.defaultMessage}</span>
        </Button>
      )}
      {registerType === 'client' && (
        <Button
          className="btn-link align-items-center ms-3 d-flex d-md-none"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSaveToAList(true);
          }}
        >
          <SVGIcon
            src={data?.isAddedToList ? circleTickIcon : plusIcon}
            className="me-1"
            width={10}
            height={10}
            {...(!data?.isAddedToList && { iconColor: `rgb(${primaryNew})` })}
          />
          <P className={`p14 ${data?.isAddedToList ? 'text-success' : 'text-primary'} m-0 p-0`} lineHeight="16.03">
            {data?.isAddedToList ? 'Added to a list' : 'Save to a list'}
          </P>
        </Button>
      )}
    </div>
  );
}

TalentCardHeader.defaultProps = {
  data: {},
  showInterviewTalent: true,
  loading: false,
};

TalentCardHeader.propTypes = {
  data: PropTypes.object,
  showInterviewTalent: PropTypes.bool,
  loading: PropTypes.bool,
};

export default TalentCardHeader;
