import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'styles/index.css';
import GlobalStyle from 'styles/global-styles';
import LanguageProvider from 'containers/LanguageProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import FontFaceObserver from 'fontfaceobserver';
import { Router } from 'react-router-dom';
import 'sanitize.css/sanitize.css';
import App from 'containers/App';
import { store, history } from 'store/configureStore';
import reportWebVitals from 'reportWebVitals';
import * as serviceWorker from './serviceWorker';

import { translationMessages } from './i18n';

const openSansObserver = new FontFaceObserver('Open Sans', {});
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const root = ReactDOM.createRoot(document.getElementById('app'));

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <LanguageProvider messages={translationMessages}>
        <Router history={history}>
          <GlobalStyle />
          <App />
        </Router>
      </LanguageProvider>
    </Provider>
  </ErrorBoundary>,
);

if (module.hot) {
  module.hot.accept(['./i18n'], () => {});
}

reportWebVitals();
serviceWorker.register();
