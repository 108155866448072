/**
 * this is Team pages layout structure.
 * this have a code when user are not login into system this layout call else it will automatically
 * redirect to main home page
 */

import React from 'react';
import { Route } from 'react-router-dom';
import { userExists, checkCurrectUserPage } from 'utils/Helper';
import { checkIfHasAccessURL } from 'containers/App/utils';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { defaultProps, propTypes } from 'containers/proptypes';
import StorageService from 'utils/StorageService';
import useEngagebay from '../../../hooks/useEngagebay';
import useCheckVersion from '../../../hooks/useCheckVersion';
import { Redirects } from '../AuthLayout/index';
import { MainContent, ContainerMod } from './styles';

/**
 * Layout is main layout renderer const.
 * @param {object} props are property object pass into this const function
 */

export const Layout = ({ children }) => {
  useEngagebay();
  useCheckVersion();
  return (
    <div className="d-flex flex-column flex-1">
      <Header />
      <MainContent>
        <ContainerMod>{children}</ContainerMod>
      </MainContent>
      <Footer className="auth-footer" />
    </div>
  );
};
Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;
Layout.displayName = 'Layout';

/**
 * TeamLayout is main layout files.
 * @param {object} props are property object pass into this const function
 */

const TeamLayout = (props) => {
  const userType = parseInt(StorageService.get('userType'), 10);

  return userExists() && checkCurrectUserPage() && checkIfHasAccessURL(userType) ? (
    <Layout {...props}>
      <Route {...props} />
    </Layout>
  ) : (
    <Redirects {...props} />
  );
};

TeamLayout.displayName = 'TeamLayout';

export default TeamLayout;
