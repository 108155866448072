import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { SVGIcon, Button, Card, ToastifyMessage, H1, P, ContainerMod } from 'components';
import request from 'utils/request';
import { toast } from 'react-toastify';
import { API_URL, unsubscribeIcon, circleTickIcon, arrowLeftBackIcon, unsubscribeArray } from 'containers/App/constants';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { RadioButton, renderField } from 'utils/Fields';
import { VALIDATION } from 'utils/constants';
import Logo from 'components/Brand';
import * as formValidations from 'utils/formValidations';
import { history } from 'store/configureStore';
import queryString from 'query-string';
import get from 'lodash/get';
import { white } from 'themes/variables';
import Footer from 'components/Footer';
import MainContent from 'components/MainContent';
import { PublicWrapper } from './styles';

function UnSubScribePage(props) {
  const userData = queryString.parse(props?.location?.search);
  const [reason, setReason] = useState('');
  const [reasonDes, setReasonDes] = useState('');
  const [unsubscribeDetails, setUnsubscribeDetails] = useState(null);

  const handleUnSubScribeUser = async () => {
    const requestURL = `${API_URL}/user/unsubscribe/${userData?.id}`;
    const data = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: {
        reason: reason === 0 ? reasonDes : reason.label,
        email: userData?.email,
      },
    };
    request(requestURL, data)
      .then((response) => {
        if (get(response, 'status')) {
          setUnsubscribeDetails(get(response, 'data', {}));
        } else {
          toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
        }
      })
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  useEffect(
    () => () => {
      setUnsubscribeDetails(null);
      setReasonDes('');
      setReason('');
    },
    [],
  );

  return (
    <React.Fragment>
      <PublicWrapper className="private-layout">
        <Helmet>
          <title>Unsubscribe Email</title>
          <meta name="description" content="Unsubscribe Email" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="d-md-block m-4 mt-3">
          <Logo />
        </div>
        <hr className="mt-0" />
        <MainContent className="ps-0">
          <ContainerMod>
            <Card className="unsubscribe m-auto">
              {unsubscribeDetails === null && (
                <div>
                  <div className="text-center">
                    <img className="unsubscribe-img" src={unsubscribeIcon} alt="unsubscribe" />
                  </div>
                  <H1 className="mt-4 mb-3 text-center">Unsubscribe</H1>
                  <P className="p16 text-center" opacityVal="0.5">
                    We&apos;re sorry to see go!
                  </P>
                  <P className="p16 text-center mt-4">
                    Please let us know why you want to unsubscribe? So we can improve our email communications
                  </P>
                  <div className="radio-check mt-4">
                    <div>
                      {unsubscribeArray.map((item, i) => (
                        <React.Fragment key={i}>
                          <Field
                            name="status"
                            defaultValue={reason.value}
                            value={reason.value}
                            component={RadioButton}
                            label={item.label}
                            className="radio-sm"
                            checked={reason?.value === item.value}
                            onChangeRadio={() => setReason(item)}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    {reason?.value === 0 && (
                      <div className="reason-input">
                        <P className="p16 mt-4 mb-2" opacityVal="0.5">
                          Reason
                        </P>
                        <Field
                          name="linkedInUrl"
                          type="text"
                          component={renderField}
                          placeholder="Write a short reason"
                          onChange={(e) => setReasonDes(e.target.value)}
                          validate={[formValidations.required, formValidations.minLength10]}
                        />
                      </div>
                    )}

                    <div className="d-flex justify-content-between w-100 mt-4">
                      <Button className="btn btn-outline btn-sm mt-2" type="button" onClick={() => history.back()}>
                        Cancel
                      </Button>
                      <Button
                        className="btn-primary btn-sm mt-2"
                        disabled={reason === '' ? true : reason?.value === 0 && reasonDes === '' && true}
                        onClick={() => {
                          handleUnSubScribeUser();
                        }}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {unsubscribeDetails !== null && (
                <div>
                  <div className="text-center">
                    <img src={circleTickIcon} width="40" alt="unsubscribed" />
                  </div>
                  <H1 className="mt-4 mb-3 text-center">Unsubscribed</H1>
                  <P className="p16 text-center mt-4" opacityVal="0.5">
                    You won&apos;t receive our emails anymore. If you change your mind, you can subscribe again from our website.
                  </P>

                  <div className="d-flex justify-content-center w-100 mt-4">
                    <Button className="btn-primary btn-lg mt-2" onClick={() => history.push('/login')}>
                      <SVGIcon className="me-2" src={arrowLeftBackIcon} iconColor={`rgb(${white})`} />
                      Return to our website
                    </Button>
                  </div>
                </div>
              )}
            </Card>
          </ContainerMod>
        </MainContent>
        <div className="w-100 bottom-0 ">
          <Footer className="auth-footer" />
        </div>
      </PublicWrapper>
    </React.Fragment>
  );
}

export default compose(
  reduxForm({
    form: 'unsubscribe',
    touchOnChange: true,
  }),
)(UnSubScribePage);
