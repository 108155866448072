/* eslint-disable react/destructuring-assignment */
/** Clients Page
 * This is the Clients page for ambassador, at the '/ambassador/clients' route
 */
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';

import get from 'lodash/get';
import debounce from 'lodash/debounce';
import DataTable from 'react-data-table-component';
import StorageService from 'utils/StorageService';
import Pagination from 'rc-pagination';
import { toast } from 'react-toastify';
import Selects from 'components/Selects';
import Content from 'components/Content';
import { ToastifyMessage, Card, PrivateGrid, H3, Badge } from 'components';
import { getBadgeClass, getReferralLink } from 'containers/App/utils';
import { TableSkeletonCol6 } from 'components/SkeletonLoader';
import request from 'utils/request';
import { VALIDATION } from 'utils/constants';
import { referEarnType } from 'containers/Talent/Dashboard/actions';
import ReferEarnModal from 'containers/Talent/ReferEarn/ReferEarnModal';
import { localeInfo } from 'containers/TalentListingPage/constants';
import { API_URL, AMBASSADOR, TALENT, LIST, customStyles, CLIENT, plusIcon } from 'containers/App/constants';
import { getUserType } from 'utils/Helper';
import { textItemRender } from 'containers/TalentListingPage/utils';
import SearchComponent from 'components/SearchComponent';
import { makeSelectReloadTable } from 'containers/Talent/Dashboard/selectors';
import { propTypes } from 'containers/proptypes';
import TalentNameButton from 'components/TalentNameButton';
import containerMessage from 'containers/messages';
import referEarnMessages from 'containers/Talent/ReferEarn/messages';
import headerContainerMessage from 'components/Header/messages';
import { inviteTalentColumns } from 'containers/Recruiter/Dashboard/constants';
import { key, DEFAULT_PAGE_NO, ambassadorTalentActionStatusArray } from 'containers/Ambassador/Talent/constants';
import messages from './messages';
import 'rc-pagination/assets/index.css';

export class Clients extends React.Component {
  constructor(props) {
    super(props);
    const search = StorageService.get('ambassadorClientsSearch') || '';
    const userType = getUserType();
    let requestURLtype = AMBASSADOR;
    if (userType === 'talent') {
      requestURLtype = TALENT;
    }
    this.state = {
      ambassadorList: [],
      paginationData: [],
      isListLoading: false,
      pageNum: DEFAULT_PAGE_NO,
      search,
      statusFilter: { label: 'All', value: -1 },
      totalDocs: 0,
      requestURLtype,
    };
  }

  componentDidMount() {
    const { onChangeReferType } = this.props;
    const getPageNumber = StorageService.get('clientsPageNumber');
    const updatedPageNumber = JSON.parse(getPageNumber) || 1;
    onChangeReferType(CLIENT);
    this.loadAmbassadorDetails(updatedPageNumber);
  }

  componentDidUpdate(prevProps) {
    const { isReloadTable } = this.props;
    if (prevProps.isReloadTable !== isReloadTable) {
      const getPageNumber = StorageService.get('clientsPageNumber');
      const updatedPageNumber = JSON.parse(getPageNumber) || 1;
      this.loadAmbassadorDetails(updatedPageNumber);
    }
  }

  loadAmbassadorDetails = (pageNum) => {
    const { statusFilter, requestURLtype } = this.state;
    StorageService.set('clientsPageNumber', JSON.stringify(pageNum));
    this.setState({ isListLoading: true });
    const status = get(statusFilter, 'value', -1);
    const { search } = this.state;

    const data = { method: 'GET' };
    let requestURL = '';
    requestURL = `${API_URL}${requestURLtype}${CLIENT}${LIST}?page=${pageNum}&q=${search}`;

    // statusFilter
    requestURL += status !== -1 ? `&status=${encodeURIComponent(status)}` : '';

    request(requestURL, data)
      .then(this.setAmbassadorDetails)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  setAmbassadorDetails = (response) => {
    if (get(response, 'status')) {
      const data = Object.assign({}, response.data);
      const totalDocs = get(response, 'data.totalDocs');
      const array = [];
      data.docs.forEach((listData) => {
        const id = get(listData, '_id');
        const referredOn = get(listData, 'referredOn');
        const registeredOn = get(listData, 'registeredOn');
        const verifiedOn = get(listData, 'verifiedOn');
        const hiredOn = get(listData, 'hiredOn');
        const status = get(listData, 'status', '');
        const refereeProfilePicture = get(listData.referee, 'profilePicture', '');

        const refereeBtnProps = {
          redirectTo: `/${CLIENT}/`,
          talentId: get(listData.referee, 'talentId', ''),
          profileId: get(listData.referee, 'profileId', ''),
          redirectType: '',
          talentName: `${get(listData.referee, 'firstName')} ${get(listData.referee, 'lastName')}`,
          extra: {},
          profilePicture: refereeProfilePicture,
          disabled: true,
        };
        const referral = <TalentNameButton {...refereeBtnProps} />;
        array.push({
          id,
          email: get(listData, 'email'),
          name: get(listData.referee, 'firstName') ? referral : get(listData, 'email'),
          referredOn: referredOn ? moment(referredOn).format('DD/MM/YYYY') : '',
          signedUp: registeredOn ? moment(registeredOn).format('DD/MM/YYYY') : '',
          activatedOn: verifiedOn ? moment(verifiedOn).format('DD/MM/YYYY') : '',
          hiredOn: hiredOn ? moment(hiredOn).format('DD/MM/YYYY') : '',
          status: <Badge className={`${getBadgeClass(status)} badge-sm`}>{status}</Badge>,
        });
      });
      this.setState({ totalDocs, ambassadorList: array, paginationData: response.data, isListLoading: false });
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
    }
  };

  debounceFn = debounce((value) => this.handleSearchChange(value), 500);

  handleSearchChange = (value) => {
    StorageService.set('ambassadorClientsSearch', value);
    const { pageNum } = this.state;
    this.setState({ search: value });
    this.loadAmbassadorDetails(pageNum);
  };

  handleStatusFilter = (e) => {
    this.setState({ statusFilter: e }, () => {
      this.loadAmbassadorDetails(DEFAULT_PAGE_NO);
    });
  };

  render() {
    const { pageNum } = this.state;
    const LinearIndeterminate = () => (
      <div className="w-100 flex-column d-flex">
        <TableSkeletonCol6 cardCount={5} />
      </div>
    );

    const referEarnProps = {
      buttonText: headerContainerMessage.inviteClients.defaultMessage,
      buttonClass: 'btn btn-sm btn-outline btn-plus',
      buttonIcon: plusIcon,
      enableCancel: true,
      referEarnType: CLIENT,
      inviteBlockModalContent: referEarnMessages.referModalAmbassadorClientText,
      inviteEmailModalContent: referEarnMessages.inviteMailContentForRecruiter,
      inviteLink: getReferralLink(CLIENT),
      onSubmitReLoadPage: () => this.loadAmbassadorDetails(pageNum),
    };

    const { search, ambassadorList, paginationData, isListLoading, statusFilter, totalDocs } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{messages.title.defaultMessage}</title>
          <meta name="description" content={messages.metaTitle.defaultMessage} />
        </Helmet>

        <Content>
          <PrivateGrid>
            <div className="d-flex align-items-center mb-3">
              <H3>
                <FormattedMessage {...messages.allPartners} />
              </H3>
              <Badge className="primary badge-sm ms-1 ">{totalDocs}</Badge>
            </div>
            <Card className="p-20">
              <Card className="input-sm d-flex flex-column flex-md-row table-header border-0 p-0">
                <ReferEarnModal {...referEarnProps} />
                <div className="d-flex ms-auto align-items-center mt-3 mt-lg-0">
                  <SearchComponent
                    customClass="search-lg"
                    handleSearchChange={this.debounceFn}
                    placeholder={containerMessage.searchPlaceholder.defaultMessage}
                    initialValue={search}
                  />

                  <Field
                    formGroupClasses="mb-0 mb-lg-0 ms-2 with-width"
                    name="statusFilter"
                    type="text"
                    component={Selects}
                    defaultValue={statusFilter}
                    searchable={false}
                    options={ambassadorTalentActionStatusArray.map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    onChange={(e) => this.handleStatusFilter(e)}
                  />
                </div>
              </Card>
              <DataTable
                noHeader
                columns={inviteTalentColumns}
                customStyles={customStyles}
                data={ambassadorList}
                totalRows={0}
                direction="ltr"
                progressPending={isListLoading}
                progressComponent={<LinearIndeterminate />}
                paginationComponentOptions={{ noRowsPerPage: true }}
                overflowY
                overflowYOffset="70px"
                noDataComponent={<p className="p-4 m-0 text-muted">{containerMessage.noRecord.defaultMessage}</p>}
              />
              {paginationData.totalDocs > 20 ? (
                <Pagination
                  total={paginationData.totalDocs}
                  className="ant-pagination"
                  current={paginationData.page}
                  defaultPageSize={paginationData.limit}
                  onChange={this.loadAmbassadorDetails}
                  itemRender={textItemRender}
                  locale={localeInfo}
                />
              ) : (
                ''
              )}
            </Card>
          </PrivateGrid>
        </Content>
      </React.Fragment>
    );
  }
}

Clients.propTypes = propTypes;

const mapStateToProp = createStructuredSelector({
  isReloadTable: makeSelectReloadTable(),
});
export function mapDispatchToProps(dispatch) {
  return {
    onChangeReferType: (data) => dispatch(referEarnType(data)),
  };
}

const withConnect = connect(mapStateToProp, mapDispatchToProps);

export default compose(
  withConnect,
  reduxForm({
    form: key,
    touchOnChange: true,
  }),
)(Clients);
