/**
 * About you selectors
 */

import { createSelector } from 'reselect';
import { key } from './constants';
import { initialState } from './reducer';

const addACompanyForm = (state) => state[key] || initialState;

const makeSelectName = () => createSelector(addACompanyForm, (state) => state.name);
const makeSelectBrand = () => createSelector(addACompanyForm, (state) => state.brand);
const makeSelectRegisteredNumber = () => createSelector(addACompanyForm, (state) => state.registeredNumber);
const makeSelectVatNumber = () => createSelector(addACompanyForm, (state) => state.vatNumber);
const makeSelectIndustry = () => createSelector(addACompanyForm, (state) => state.industry);
const makeSelectCompanyType = () => createSelector(addACompanyForm, (state) => state.companyType);
const makeSelectCompanyCultures = () => createSelector(addACompanyForm, (state) => state.cultures);
const makeSelectCorporateWebsiteUrl = () => createSelector(addACompanyForm, (state) => state.corporateWebsiteUrl);

const makeSelectLinkedInProfile = () => createSelector(addACompanyForm, (state) => state.linkedInUrl);

const makeSelectGithubProfile = () => createSelector(addACompanyForm, (state) => state.gitHubUrl);

const makeSelectStackoverflowProfile = () => createSelector(addACompanyForm, (state) => state.stackOverFlowUrl);

const makeSelectDribbleProfile = () => createSelector(addACompanyForm, (state) => state.dribbbleUrl);

const makeSelectBehanceProfile = () => createSelector(addACompanyForm, (state) => state.behanceUrl);

const makeSelectGlassdoorUrl = () => createSelector(addACompanyForm, (state) => state.glassdoorUrl);

const makeSelectPersonalProfile = () => createSelector(addACompanyForm, (state) => state.portfolioUrl);

const makeSelectLocationName = () => createSelector(addACompanyForm, (state) => state.locationName);

const makeSelectPostcode = () => createSelector(addACompanyForm, (state) => state.postcode);

const makeSelectAddressLineOne = () => createSelector(addACompanyForm, (state) => state.addressLineOne);

const makeSelectAddressLineTwo = () => createSelector(addACompanyForm, (state) => state.addressLineTwo);

const makeSelectCity = () => createSelector(addACompanyForm, (state) => state.city);

const makeSelectCountry = () => createSelector(addACompanyForm, (state) => state.country);

const makeSelectState = () => createSelector(addACompanyForm, (state) => state.state);

const makeSelectTimeZone = () => createSelector(addACompanyForm, (state) => state.timezone);
const makeInvitedUserEmail = () => createSelector(addACompanyForm, (state) => state.invitedUserEmail);
const makeInvitedUsername = () => createSelector(addACompanyForm, (state) => state.invitedUsername);
const makeInvitedUserRole = () => createSelector(addACompanyForm, (state) => state.invitedUserRole);
const makeDomain = () => createSelector(addACompanyForm, (state) => state.domain);
const makeBio = () => createSelector(addACompanyForm, (aboutCompanyState) => aboutCompanyState.bio);

export {
  makeSelectName,
  makeSelectBrand,
  makeSelectRegisteredNumber,
  makeSelectVatNumber,
  makeSelectIndustry,
  makeSelectCompanyType,
  addACompanyForm,
  makeSelectCompanyCultures,
  makeSelectLinkedInProfile,
  makeSelectGithubProfile,
  makeSelectStackoverflowProfile,
  makeSelectDribbleProfile,
  makeSelectBehanceProfile,
  makeSelectPersonalProfile,
  makeSelectGlassdoorUrl,
  makeSelectLocationName,
  makeSelectPostcode,
  makeSelectAddressLineOne,
  makeSelectAddressLineTwo,
  makeSelectCity,
  makeSelectCountry,
  makeSelectState,
  makeSelectTimeZone,
  makeInvitedUserEmail,
  makeInvitedUsername,
  makeSelectCorporateWebsiteUrl,
  makeDomain,
  makeInvitedUserRole,
  makeBio,
};
