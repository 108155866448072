import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import { P } from 'components';
import {
  linkedinNewIcon,
  facebookIcon,
  dribbleIcon,
  discordIcon,
  instagramIcon,
  githubIcon,
  telegramIcon,
  twitterIcon,
  CM_LINKEDIN_URL,
  CM_DRIBBLE_URL,
  CM_FACEBOOK_URL,
  COMMUNITY_URL,
  CM_TWITTER_URL,
  CM_TELEGRAM_URL,
  CM_INSTAGRAM_URL,
  CM_GITHUB_URL,
} from 'containers/App/constants';
import FooterLinks from './FooterLinks';
import { Footer, SocialLinks } from './footer-style';
import messages from './messages';

function FooterWrapper(props) {
  const { className } = props;
  return (
    <Footer className={className}>
      <P className="p16 copyright" opacityVal="0.5">
        <FormattedMessage {...messages.copyrights} />
      </P>
      <FooterLinks />
      <SocialLinks>
        <li>
          <a target="_blank" title="Discord" href={COMMUNITY_URL} rel="noreferrer">
            <SVG src={discordIcon} />
          </a>
        </li>
        <li>
          <a target="_blank" title="Twitter" href={CM_TWITTER_URL} rel="noreferrer">
            <SVG src={twitterIcon} />
          </a>
        </li>
        <li>
          <a target="_blank" title="Linkedin" href={CM_LINKEDIN_URL} rel="noreferrer">
            <SVG src={linkedinNewIcon} />
          </a>
        </li>
        <li className="telegram-icon">
          <a target="_blank" title="Telegram" href={CM_TELEGRAM_URL} rel="noreferrer">
            <SVG src={telegramIcon} />
          </a>
        </li>
        <li>
          <a target="_blank" title="GitHub" href={CM_GITHUB_URL} rel="noreferrer">
            <SVG src={githubIcon} />
          </a>
        </li>
        <li>
          <a target="_blank" title="Dribbble" href={CM_DRIBBLE_URL} rel="noreferrer">
            <SVG src={dribbleIcon} />
          </a>
        </li>
        <li>
          <a target="_blank" title="Facebook" href={CM_FACEBOOK_URL} rel="noreferrer">
            <SVG src={facebookIcon} />
          </a>
        </li>
        <li>
          <a target="_blank" title="Instagram" href={CM_INSTAGRAM_URL} rel="noreferrer">
            <SVG src={instagramIcon} />
          </a>
        </li>
      </SocialLinks>
    </Footer>
  );
}

FooterWrapper.defaultProps = {
  className: '',
};
FooterWrapper.propTypes = {
  className: PropTypes.string,
};

export default FooterWrapper;
