/*
 * EmptyScreens Messages
 *
 * This contains all the text for the EmptyScreens.
 */
import { defineMessages } from 'react-intl';

export const scope = 'boilerplate.containers.EmptyScreens';

export default defineMessages({
  hireTalent: {
    id: `${scope}.hireTalent`,
    defaultMessage: 'Hire talent',
  },
  searchTelents: {
    id: `${scope}.searchTelents`,
    defaultMessage: 'Search talents',
  },
});
