/*
 * Dashboard Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

import { TableSkeletonCol6 } from 'components/SkeletonLoader';
import { paypalIcon, payoneerIcon, stripeIcon, wiseIcon, metamaskIcon } from 'containers/App/constants';

export const CHANGE_INVITE = 'notchup/HomePage/CHANGE_INVITE';
export const SUBMIT_INVITE_MAILS = 'notchup/HomePage/SUBMIT_INVITE_MAILS';
export const SUBMIT_ADD_TALENT = 'notchup/HomePage/SUBMIT_ADD_TALENT';
export const REFER_EARN_TYPE = 'notchup/HomePage/REFER_EARN_TYPE';
export const RELOAD_TABLE = 'notchup/HomePage/RELOAD_TABLE';

export const key = 'homepageInviteForm';
export const MINIMUM_INVITE_ROWS = 5;
export const MINIMUM_TELENT_TEAM_ROWS = 5;

export const payoutItems = [
  {
    name: 'Paypal',
    icon: paypalIcon,
  },
  {
    name: 'Wise',
    icon: wiseIcon,
  },
  {
    name: 'Stripe',
    icon: stripeIcon,
  },
  {
    name: 'Payoneer',
    icon: payoneerIcon,
  },
  {
    name: 'Metamask',
    icon: metamaskIcon,
  },
];

export const columns = [
  {
    name: 'Date',
    selector: 'date',
  },
  {
    name: 'Details',
    selector: 'details',
    grow: 2,
  },
  {
    name: 'Deposit',
    selector: 'deposit',
  },
  {
    name: 'Withdrawal',
    selector: 'withdrawal',
  },
  {
    name: 'Balance',
    selector: 'balance',
  },
];

export const LinearIndeterminate = () => (
  <div className="w-100 flex-column d-flex">
    <TableSkeletonCol6 cardCount={5} />
  </div>
);
