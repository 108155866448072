import styled from 'styled-components';
import { white } from 'themes/variables';

export const PublicWrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(${white});
`;
