import React from 'react';
import PropTypes from 'prop-types';
import { CompleteProfileModal } from 'containers/Talent/ApplyBrief/styles';
import history from 'utils/history';
import { agencyRedirectPageURL, getCurrentProfileStep, clientRedirectPageURL } from 'containers/App/utils';
import SVG from 'react-inlinesvg';
import { closeIcon, circleTickIcon } from 'containers/App/constants';
import StorageService from 'utils/StorageService';
import { UserBulletPointList } from 'containers/Auth/SignUp/signup-styles';
import { H3, P, Button } from 'components';
import { isEmpty, isNil } from 'lodash';

export class ProfileCompleteModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileCompleteModal: false,
    };
  }

  handleProfileCompleteOpenModal = () => {
    this.setState({ showProfileCompleteModal: true });
  };

  handleProfileCompleteCloseModal = () => {
    this.setState({ showProfileCompleteModal: false });
  };

  handleRedirect = (e) => {
    e.stopPropagation();
    const { userType } = this.props;
    if (userType === 'client') {
      const formDataValue = StorageService.get('validForm');
      const validFormData = formDataValue === 'undefined' ? undefined : formDataValue;
      const validFormObj = !isNil(validFormData) && JSON.parse(validFormData);
      const formStep = !isEmpty(validFormObj) && Object.values(validFormObj).findIndex((ele) => !ele.status);
      const step = getCurrentProfileStep() - 1;
      this.handleProfileCompleteCloseModal();
      const redirectionBaseUrl = formStep !== -1 ? clientRedirectPageURL(formStep) : clientRedirectPageURL(step - 1);
      history.push(redirectionBaseUrl);
    } else if (userType === 'agency') {
      const step = getCurrentProfileStep();
      this.handleProfileCompleteCloseModal();
      history.push(agencyRedirectPageURL(step));
    }
  };

  render() {
    const { showProfileCompleteModal } = this.state;
    const { title, description, btnText, listOption1, listOption2, listOption3, listHeading } = this.props;

    return (
      <>
        <CompleteProfileModal
          isOpen={showProfileCompleteModal}
          contentLabel="Complete Profile Model"
          onRequestClose={this.handleProfileCompleteCloseModal}
          className="modal-dialog"
          style={{ overlay: { zIndex: 12 } }}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          ariaModal
        >
          <div className="modal-content">
            <div className="modal-header modal-close">
              <button type="button" className="modal-dismiss ms-auto" onClick={this.handleProfileCompleteCloseModal}>
                <img src={closeIcon} alt="close" />
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <div className="modal-body">
              <H3 className="text-center">{title}</H3>
              <P className="p16 text-center" opacityVal="0.5">
                {description}
              </P>
              <div className="benefits-list">
                <P className="p16">{listHeading}</P>
                <UserBulletPointList>
                  <li>
                    <SVG src={circleTickIcon} />
                    <P className="p16" opacityVal="0.5">
                      {listOption1}
                    </P>
                  </li>
                  <li>
                    <SVG src={circleTickIcon} />
                    <P className="p16" opacityVal="0.5">
                      {listOption2}
                    </P>
                  </li>
                  <li>
                    <SVG src={circleTickIcon} />
                    <P className="p16" opacityVal="0.5">
                      {listOption3}
                    </P>
                  </li>
                </UserBulletPointList>
              </div>
              <div className="text-center">
                <Button className="btn mx-auto btn-primary px-5 py-2" onClick={this.handleRedirect}>
                  {btnText}
                </Button>
              </div>
            </div>
          </div>
        </CompleteProfileModal>
      </>
    );
  }
}

ProfileCompleteModel.defaultProps = {
  title: '',
  description: '',
  btnText: '',
  listOption1: '',
  listOption2: '',
  listOption3: '',
  listHeading: '',
  userType: 'client',
};

ProfileCompleteModel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnText: PropTypes.string,
  listOption1: PropTypes.string,
  listOption2: PropTypes.string,
  listOption3: PropTypes.string,
  listHeading: PropTypes.string,
  userType: PropTypes.string,
};

export default ProfileCompleteModel;
