/*
 * ApplyBrief Messages
 *
 * This contains all the text for the ApplyBrief component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.containers.ApplyBrief';

export default defineMessages({
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description:',
  },
  modalTitle: {
    id: `${scope}.modalTitle`,
    defaultMessage: 'Your application',
  },
  ProfileCompleteHeading: {
    id: `${scope}.ProfileCompleteHeading`,
    defaultMessage: 'Let’s complete your profile',
  },
  ProfileCompleteContent: {
    id: `${scope}.ProfileCompleteContent`,
    defaultMessage: 'Before you apply to any job, you will need to do the following',
  },
  benefitsInfo: {
    id: `${scope}.benefitsInfo`,
    defaultMessage: 'Once completed, you’ll start to enjoy some immediate benefits',
  },
  benefitsItem1: {
    id: `${scope}.benefitsItem1`,
    defaultMessage: 'Increased profile visibility',
  },
  benefitsItem2: {
    id: `${scope}.benefitsItem2`,
    defaultMessage: 'Personalised job matching',
  },
  benefitsItem3: {
    id: `${scope}.benefitsItem3`,
    defaultMessage: 'Quicker hiring process',
  },
  motivateLabel: {
    id: `${scope}.motivateLabel`,
    defaultMessage: 'What interests you about this role?',
  },
  whenToStartLabel: {
    id: `${scope}.whenToStartLabel`,
    defaultMessage: 'When is the earliest date you can start?',
  },
  btnApplyNow: {
    id: `${scope}.btnApplyNow`,
    defaultMessage: 'Apply now',
  },
});
