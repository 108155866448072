import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TERMS_CONDITION_URL, PRIVACY_POLICY_URL, COOKIE_POLICY_URL, ACCEPTABLE_POLICY_URL } from 'containers/App/constants';
import { FooterLinkList } from './footer-style';
import messages from './messages';

const FooterLinks = () => (
  <FooterLinkList className="footer-links">
    <li>
      <a target="_blank" href={TERMS_CONDITION_URL} rel="noreferrer">
        <FormattedMessage {...messages.ourTermsPolicy} />
      </a>
    </li>
    <li>
      <a target="_blank" href={PRIVACY_POLICY_URL} rel="noreferrer">
        <FormattedMessage {...messages.privacyPolicy} />
      </a>
    </li>
    <li>
      <a target="_blank" href={COOKIE_POLICY_URL} rel="noreferrer">
        <FormattedMessage {...messages.cookiePolicy} />
      </a>
    </li>
    <li>
      <a target="_blank" href={ACCEPTABLE_POLICY_URL} rel="noreferrer">
        <FormattedMessage {...messages.acceptablePolicy} />
      </a>
    </li>
  </FooterLinkList>
);

export default FooterLinks;
