import styled from 'styled-components';
import { primaryDarkNew, infoGradient } from 'themes/variables';

export const PlanCard = styled.div`
  border: 1px solid rgba(${primaryDarkNew}, 0.1);
  border-radius: 10px;
  text-align: center;
  position: relative;
  margin-bottom: 20px;

  &.bg-info {
    border: 1px solid rgba(18, 196, 114, 1);
    background: linear-gradient(114.05deg, #fde5bf 0%, #ffceca 100%);
    box-shadow: 0px 15px 10px 0px rgba(4, 0, 78, 0.1);

    & .best-value {
      background: rgba(18, 196, 114, 1);
      top: 5px;
      right: 5px;
      border-radius: 8px;
    }
  }

  & .popular-badge {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background: ${infoGradient};
  }

  & .inner-block {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;

    & button.btn-primary,
    & a.btn-outline,
    & button.btn-outline {
      max-width: 270px;
      margin: 0 auto;
      width: 100%;
    }

    &.pt-30 {
      min-height: 137px;
      border-top: 1px solid rgba(${primaryDarkNew}, 0.1);
    }
    &.pb-30 {
      min-height: 260px;
    }
  }

  .divider {
    border-top: 1px solid rgba(${primaryDarkNew}, 0.1);
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-30 {
    margin-top: 30px;
  }
`;
