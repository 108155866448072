/*
 * Wallet Messages
 *
 * This contains all the text for the Wallet component.
 */
import { defineMessages } from 'react-intl';
import StorageService from 'utils/StorageService';

export const scope = 'boilerplate.containers.Wallet';

const changeEmailContent = `Dear ${StorageService.get('firstName')}, you have been removed by your employer.
 If you want to retain your account with Notchup and continue finding great work as a freelancer,
then simply change your email address here`;
export default defineMessages({
  title: {
    id: `${scope}.title.head`,
    defaultMessage: 'Wallet',
  },
  metaTitle: {
    id: `${scope}.metaTitle.head`,
    defaultMessage: 'Wallet',
  },
  buttonInvite: {
    id: `${scope}.buttonInvite`,
    defaultMessage: 'Send',
  },
  modelInvitePeopleHeader: {
    id: `${scope}.modelInvitePeopleHeader`,
    defaultMessage: 'Invite people',
  },
  labelInviteLink: {
    id: `${scope}.labelInviteLink`,
    defaultMessage: 'Invite link',
  },

  transactions: {
    id: `${scope}.transactions`,
    defaultMessage: 'Transactions',
  },
  exportTitle: {
    id: `${scope}.export`,
    defaultMessage: 'Export',
  },
  balanceTitle: {
    id: `${scope}.balanceTitle`,
    defaultMessage: 'Balance',
  },
  payoutTitle: {
    id: `${scope}.payoutTitle`,
    defaultMessage: 'Payout accounts',
  },

  //
  noRecordTitle: {
    id: `${scope}.noRecordTitle`,
    defaultMessage: 'Get earning now!',
  },
  noRecordDesc: {
    id: `${scope}.noRecordDesc`,
    defaultMessage: 'You do not have any transactions yet.',
  },
});
