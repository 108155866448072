import styled from 'styled-components';

export const MessageWrapper = styled.div`
  display: flex;
  alignItems: center;
  .icon {
    margin-right: 20px;
  }

  span.success {
    fontFamily: 'GT Walsheim Pro',
    fontWeight: 100,
    fontSize: "16px",
    lineHeight: "18px",
  }

  span.error {
    fontFamily: 'GT Walsheim Pro',
    fontWeight: 100,
    fontSize: "16px",
    lineHeight: "18px",
  }
`;
