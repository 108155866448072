import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

const EVENTS = {
  HAMBURGER_TOGGLE: 'Hamburger_Toggle',
  Proxy_Login_Recruiter: 'Proxy_Login_Recruiter',
  Proxy_Login_Ambassador: 'Proxy_Login_Ambassador',
  SEARCHBAR_OPEN: 'Searchbar_Open',
  Progressbar_Val: 'Progressbar_Val',
  ADD_COWORKERS_TO_TEAM: 'Add_Coworkers_To_Team',
  SUBSCRIPTION_MODAL_OPEN: 'SUBSCRIPTION_MODAL_OPEN',
  SUBSCRIPTION: 'SUBSCRIPTION',
  CLOSE_ADD_BRIEF_MODAL: 'CLOSE_ADD_BRIEF_MODAL',
};

const Emitter = {
  on: (event, fn) => eventEmitter.on(event, fn),
  once: (event, fn) => eventEmitter.once(event, fn),
  off: (event, fn) => eventEmitter.off(event, fn),
  emit: (event, payload) => eventEmitter.emit(event, payload),
  EVENTS,
};

Object.freeze(Emitter);

export default Emitter;
