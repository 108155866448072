import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { white, primaryDarkNew, primaryNew, infoGradientRight } from 'themes/variables';
import media from 'themes/media';
import { Card } from 'components';
import { Dropdown } from 'reactstrap';

import balanceCardBG from '../../../images/balance-card-bg.png';

const w100 = 'flex: 0 0 100%; max-width: 100%;';

export const CircularProgressbarBlock = styled.div`
  width: 130px;
  height: 130px;
  margin: 60px auto;
`;

export const LinkViewAll = styled(Link)`
  font-size: 16px;
  line-height: 18px;
  color: rgb(${primaryNew});
  text-decoration: none;
  font-family: 'GT-Walsheim-Pro-Regular';

  &:hover {
    text-decoration: underline;
  }
`;

export const DBcontainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  ${media.large`
    grid-template-columns: 1fr auto;
  `};
`;

export const LeftCol = styled.div`
  overflow-x: hidden;
`;

export const RightCol = styled.div`
  width: 100%;
  position: relative;
  margin-top: 50px;

  ${media.large`
    width: 300px;
  `};

  hr {
    margin: 10px 0;
    border-color: rgba(${primaryNew}, 0.1);
    border-width: 1px;

    &.my-20 {
      margin: 20px 0;
    }
  }
  .pt-60 {
    padding-top: 60px;
  }
`;

export const BalanceCard = styled(Card)`
  height: 295px;
  background-color: rgb(${primaryNew});
  background-image: url(${balanceCardBG});
  background-size: 100% 105%;
  background-position: center;
  background-repeat: no-repeat;
`;

export const PayoutCard = styled(Card)`
  height: 295px;
`;

export const WalletIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  background-color: rgb(${white});

  svg {
    & g {
      fill: rgb(${primaryDarkNew});
    }

    path {
      stroke: rgba(${primaryDarkNew});
    }
  }
`;

export const CardContainer = styled(Card)`
  &.rounded-bordered-card {
    border-radius: 10px !important;
  }

  .rdt_TableHead .rdt_TableCol:first-child {
    padding-left: 20px;
  }
`;

export const WalletListingFilter = styled(Dropdown)`
  margin-top: 10px;

  ${media.medium`
    margin-top: 0;
    margin-right: 8px;
  `};

  .dropdown-menu {
    border: 1px solid rgba(${primaryNew}, 0.1);
    box-shadow: 0px 1px 6px rgba(${primaryDarkNew}, 0.15);
    width: 264px;
    padding: 10px;
    margin-top: 0;
    max-height: 500px;
    border-radius: 10px;

    .dropdown-item {
      font-size: 16px;
      line-height: 18px;
      color: rgba(${primaryDarkNew}, 0.7);
      padding: 10px 10px 2px 10px;
      border-radius: 4px;

      &:hover,
      &:focus {
        background-color: transparent;
        cursor: pointer;
      }
      .active {
        color: rgb(${primaryNew});
      }
    }

    ${media.large`
      max-height: 1000px;
      min-width: 264px;
      width: auto;
    `};

    .radio-div {
      .radio-sm {
        font-size: 14px;

        .checkmark {
          top: 6%;
          left: 2.9px;
        }
      }
      &:last-child {
        .radio-sm {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  &.show > .btn-secondary.dropdown-toggle,
  &.show > .btn-secondary {
    color: rgb(${primaryDarkNew});
    border-color: rgba(${primaryNew}, 0.1);
  }
  .btn-secondary {
    width: 264px;
    border: 1px solid rgba(${primaryNew}, 0.1);
    align-items: center;
    color: rgb(${primaryDarkNew});
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    min-height: 46px;
    position: relative;
    margin-right: 5px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    @media (max-width: 850px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    div,
    img {
      opacity: 0.7;
      &.active {
        color: rgb(${primaryNew});
      }
    }
    div {
      margin-right: 5px;
      max-width: 200pxpx;
      width: 185px;
      text-align-last: left;
      &.active {
        color: rgb(${primaryNew});
      }
    }

    svg {
      opacity: 0.7;
      transition: transform 0.15s ease-in-out;
      transform: rotate(-90deg);
      width: 8px;
      height: 11px;
      margin-left: 3px;
      g {
        fill: rgb(${primaryDarkNew});
      }

      &.title-icon {
        width: 15px;
        height: 14px;
        transform: rotate(0deg);
      }
    }
    &:hover {
      svg {
        &.title-icon {
          transform: rotate(0deg);
        }
      }
    }

    &.active-filter {
      background: ${infoGradientRight};
    }

    &:hover,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: rgb(${primaryDarkNew});
      outline: 2px solid rgba(${primaryNew}, 0.2);
      border-color: rgba(${primaryNew}, 0.5);
      cursor: pointer;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &.show .dropdown-toggle:focus {
      box-shadow: none !important;
      border-color: rgba(${primaryNew}, 0.5);
      cursor: pointer;
    }
  }
`;

export const WalletListingExport = styled(Dropdown)`
  margin-top: 10px;

  ${media.medium`
    margin-top: 0;
    margin-right: 8px;
  `};

  .dropdown-menu {
    border: 1px solid rgba(${primaryNew}, 0.1);
    box-shadow: 0px 1px 6px rgba(${primaryDarkNew}, 0.15);
    width: 150px;
    padding: 10px;
    margin-top: 0;
    max-height: 500px;
    border-radius: 10px;

    .dropdown-item {
      font-size: 16px;
      line-height: 18px;
      color: rgba(${primaryDarkNew}, 0.7);
      padding: 10px 10px 10px 10px;
      border-radius: 4px;

      &:hover,
      &:focus {
        background-color: rgba(${primaryNew}, 0.1);
        cursor: pointer;
      }
    }

    ${media.large`
      max-height: 200px;
      min-width: 150px;
      width: auto;
    `};
  }
  &.show > .btn-secondary.dropdown-toggle,
  &.show > .btn-secondary {
    color: rgb(${primaryDarkNew});
    border-color: rgba(${primaryNew}, 0.1);
  }
  .btn-secondary {
    width: 150px;
    border: 1px solid rgba(${primaryNew}, 0.1);
    align-items: center;
    color: rgb(${primaryDarkNew});
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    min-height: 46px;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    @media (max-width: 850px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    img {
      margin-top: 3px;
    }
    div,
    img {
      opacity: 0.7;
      width: 16px;
      height: 16px;
      margin-left: 3px;
    }
    div {
      margin-top: 4px;
      min-width: 70px;
      align-self: start;
      text-align-last: left;
    }

    svg {
      opacity: 0.7;
      transition: transform 0.15s ease-in-out;
      transform: rotate(-90deg);
      width: 8px;
      height: 11px;
      margin-left: 3px;
      g {
        fill: rgb(${primaryDarkNew});
      }

      &.title-icon {
        width: 15px;
        height: 14px;
        transform: rotate(0deg);
      }
    }
    &:hover {
      svg {
        &.title-icon {
          transform: rotate(0deg);
        }
      }
    }

    &.active-filter {
      background: ${infoGradientRight};
    }

    &:hover,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      /* backgorund-color: rgb(${primaryDarkNew}); */
      cursor: pointer;
      outline: 2px solid rgba(${primaryNew}, 0.2);
      border-color: rgba(${primaryNew}, 0.5);
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &.show .dropdown-toggle:focus {
      box-shadow: none !important;
      border-color: rgba(${primaryNew}, 0.5);
      cursor: pointer;
    }
  }
`;

export const TransTypetListing = styled(Dropdown)`
  margin-top: 10px;

  ${media.medium`
    margin-top: 0;
    margin-right: 8px;
  `};

  .dropdown-menu {
    border: 1px solid rgba(${primaryNew}, 0.1);
    box-shadow: 0px 1px 6px rgba(${primaryDarkNew}, 0.15);
    width: 200px;
    padding: 10px;
    margin-top: 0;
    max-height: 500px;
    border-radius: 10px;

    .dropdown-item {
      font-size: 16px;
      line-height: 18px;
      color: rgba(${primaryDarkNew}, 0.7);
      padding: 10px 10px 10px 10px;
      border-radius: 4px;

      &:hover,
      &:focus {
        background-color: transparent;
        cursor: pointer;
      }
      .active {
        color: rgb(${primaryNew});
      }
    }

    ${media.large`
      max-height: 200px;
      min-width: 200px;
      width: auto;
    `};
  }
  &.show > .btn-secondary.dropdown-toggle,
  &.show > .btn-secondary {
    color: rgb(${primaryDarkNew});
    border-color: rgba(${primaryNew}, 0.1);
  }
  .btn-secondary {
    width: 200px;
    border: 1px solid rgba(${primaryNew}, 0.1);
    align-items: center;
    color: rgb(${primaryDarkNew});
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    min-height: 46px;
    position: relative;
    margin: 5px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    @media (max-width: 850px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    div {
      opacity: 0.7;
      margin-top: 5px;
      min-width: 135px;
      align-self: start;
      text-align-last: left;
    }

    svg {
      opacity: 0.7;
      transition: transform 0.15s ease-in-out;
      transform: rotate(-90deg);
      width: 8px;
      height: 11px;
      margin-left: 3px;
      g {
        fill: rgb(${primaryDarkNew});
      }

      &.title-icon {
        width: 15px;
        height: 14px;
        transform: rotate(0deg);
      }
    }
    &:hover {
      svg {
        &.title-icon {
          transform: rotate(0deg);
        }
      }
    }

    &.active-filter {
      background: ${infoGradientRight};
    }

    &:hover,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: rgb(${primaryDarkNew});
      outline: 2px solid rgba(${primaryNew}, 0.2);
      border-color: rgba(${primaryNew}, 0.5);
      cursor: pointer;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &.show .dropdown-toggle:focus {
      box-shadow: none !important;
      border-color: rgba(${primaryNew}, 0.5);
      cursor: pointer;
    }
  }
`;
