import styled from 'styled-components';
import { Card } from 'components';

export const Paragrpah = styled.div`
  span {
    display: flex;
  }
`;

export const GridCard = styled(Card)`
  span {
    display: block;
  }
`;

export const TalentCard = styled(Card)`
  border-radius: 20px;
  padding: 30px !important;
  height: 256px;
  margin-top: 30px;
  .project-image-loader {
    margin-top: -60px;
    border-radius: 20px;
  }
  span {
    display: block;
  }
`;

export const CompetitionCardLoader = styled(Card)`
  border-radius: 20px;
  padding: 0 !important;
  margin-top: 40px;
  .image {
    span {
      border-radius: 20px 20px 0 0;
      width: 100% !important;
      span {
        width: 100% !important;
        .project-image-loader {
          width: 100% !important;
          border-radius: 20px 20px 0 0;
        }
      }
    }
  }
  span {
    display: block;
  }
  .content {
    padding: 20px 20px 15px;
  }
`;
