import styled from 'styled-components';
import { Card } from 'components';
import { primaryDarkNew, primaryNew } from 'themes/variables';

import media from 'themes/media';

export const TalentCardLink = styled.div`
  display: block;
  text-decoration: none;
  min-height: 212px;
  p.user-title {
    margin: 20px 0 10px;
  }
  .image-container {
    width: 72px;
    height: 72px;
    border-radius: 10px;
  }
`;

export const TalentCardBlock = styled(Card)`
  position: relative;
  min-height: 212px;
  border-radius: 10px !important;
  border: 1px solid rgba(${primaryNew}, 0.1);
  .LinesEllipsis,
  .LinesEllipsis * {
    font-size: inherit !important;
    line-height: inherit !important;
    font-family: inherit !important;
    color: inherit !important;
    background-color: transparent !important;
  }

  .rounded-bordered-card {
    padding: 20px !important;
    margin-bottom: 30px !important;
  }

  .title-icon {
    width: 20px;
    height: 20px;
    path {
      stroke: rgb(${primaryNew});
    }
  }

  .industry-icon {
    width: 60px;
    height: 60px;

    ${media.medium`
    width: 20px;
    height: 20px;
    `};
    path {
      stroke: rgb(${primaryNew});
    }
  }

  &:hover:not(.no-hover) {
    border: 1px solid rgb(${primaryNew}, 0.2);
    cursor: pointer;

    .p16:not(.no-hover) {
      color: rgb(${primaryDarkNew});
    }

    .p14:not(.no-hover) {
      color: rgb(${primaryDarkNew});
    }

    .p20 {
      color: rgb(${primaryNew});
    }
  }
`;
