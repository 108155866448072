/*
 * SignInLayoutV2 Messages
 *
 * This contains all the text for the SignInLayoutV2 component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.components.SignInLayoutV2';

export default defineMessages({
  talentTeamsCardTitle: {
    id: `${scope}.talentTeamsCardTitle`,
    defaultMessage: 'Create your dream team',
  },
  talentProjectsCardTitle: {
    id: `${scope}.talentProjectsCardTitle`,
    defaultMessage: 'Elevate your career',
  },
  talentEarningsCardTitle: {
    id: `${scope}.talentEarningsCardTitle`,
    defaultMessage: 'Take charge of your wellbeing and future',
  },
  clientProjectsCardTitle: {
    id: `${scope}.clientProjectsCardTitle`,
    defaultMessage: 'Build borderless tech teams, exactly as you want',
  },
  clientTalentsCardTitle: {
    id: `${scope}.clientTalentsCardTitle`,
    defaultMessage: 'Pre-vetted and verified talent to get started immediately',
  },
  clientDashboardCardTitle: {
    id: `${scope}.clientDashboardCardTitle`,
    defaultMessage: 'Fully compliant and hassle free',
  },
  talentTeamsCardContent: {
    id: `${scope}.talentTeamsCardContent`,
    defaultMessage:
      'Assemble your ideal team with on-demand talents with matched skills on a flexible basis for your ever-changing product roadmaps.',
  },
  talentProjectsCardContent: {
    id: `${scope}.talentProjectsCardContent`,
    defaultMessage: 'Work with top companies to build exciting projects and unleash your full potential while learning continuously.',
  },
  talentEarningsCardContent: {
    id: `${scope}.talentEarningsCardContent`,
    defaultMessage: 'Discover new possibilities for work, boost your earnings and propel your career to new heights.',
  },
  clientProjectsCardContent: {
    id: `${scope}.clientProjectsCardContent`,
    defaultMessage: 'Create cross-discipline tech teams that are remote, agile and 100% flexible.',
  },
  clientTalentsCardContent: {
    id: `${scope}.clientTalentsCardContent`,
    defaultMessage: 'Choose from a global pool of fully accredited tech professionals — all ready to join your new team line-up today.',
  },
  clientDashboardCardContent: {
    id: `${scope}.clientDashboardCardContent`,
    defaultMessage:
      'We take care of all the paperwork, and every compliance issue — so you can channel all your energy, and focus, into your delivery.',
  },
});
