import React from 'react';
import {
  CLIENT_DASHBOARD,
  HostCompetitionBannerBackground,
  TrophyIcon,
  UpgradePlanIcon,
  UpgradePriceBannerBackground,
} from 'containers/App/constants';
import { GPTSuggestionContainer } from './styles';

export const successPath = `${CLIENT_DASHBOARD}?success=true`;
export const cancelPath = `${CLIENT_DASHBOARD}?success=false`;

export const bannerCardData = {
  hostCompetition: {
    background: HostCompetitionBannerBackground,
    headerText: 'Create and host custom competitions',
    description:
      'Notchup connects you with talents and teams that are eager to solve real-world business challenges. \nStage a bounty now!',
    icon: TrophyIcon,
    iconWidth: 76.43,
    iconHeight: 92.46,
    testId: 'host-icon',
  },
  upgradePlan: {
    background: UpgradePriceBannerBackground,
    headerText: 'Supercharge your hiring process',
    description: "Accelerate your company's growth by upgrading to a Plus plan and unlock more tools for faster hiring",
    icon: UpgradePlanIcon,
    testId: 'upgrade-icon',
  },
};

export const gptSearchSuggestions = [
  'I am looking for an AI & Data Science engineer',
  'Help me to find Machine Learning Developer with 5+ years of experience',
  'Find me a Full-stack developer with experience in React and NodeJS',
  'Build me a tech team that can develop a FinTech application',
  'Build me a specialist team to develop AI / ML applications for my business',
  'Find me a Python developer with expertise in AI/ML projects',
  'Look for AI/ML engineer with experience in Deep Learning & Predictive Analysis',
  'Find me a developer with expertise in ChatGPT integration',
  'Look for an engineer with experience in Clean Energy projects',
  'I want to hire a nearshore developer for my AI/ML project',
];

export const getRandomSuggestions = (arr, num) => {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
};

export const renderGPTSearchSuggestions = ({ onClick, className, suggestions = [] }) => (
  <GPTSuggestionContainer className={`client-header ${className || ''}`} onClick={onClick}>
    {suggestions.map((suggestion, index) => (
      <div key={index} className="suggestion-item" data-testid={`suggestion-item-${index}`}>
        {suggestion}
      </div>
    ))}
  </GPTSuggestionContainer>
);
