/**
 * Signup Page
 *
 * This is the Signup page for the App, at the '/signup' route
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { sha256 } from 'js-sha256';
import get from 'lodash/get';
import { createStructuredSelector } from 'reselect';
import { reduxForm, change } from 'redux-form';
import { toast } from 'react-toastify';
import { VALIDATION } from 'utils/constants';
import request from 'utils/request';
import StorageService from 'utils/StorageService';
import { API_URL, AUTH, REFERRAL, AGENCY, TALENT, EMAIL } from 'containers/App/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { loadRepos } from 'containers/App/actions';
import { makeSelectLoading, makeSelectSuccess, makeSelectError } from 'containers/App/selectors';
import { OnboardingForm, OnBoardingFormBody, ToastifyMessage } from 'components';
import { defaultProps, propTypes } from 'containers/proptypes';
import { signUpEmail, signUpPassword, changePrivacyPolicy, signUp, signUpReferral } from 'containers/Auth/SignUp/actions';
import { makeSelectEmail, makeSelectPassword, makeSelectPrivacyCheck, makeSelectReferralVal } from 'containers/Auth/SignUp/selectors';
import reducer, { initialState } from 'containers/Auth/SignUp/reducer';
import { SignupFields } from 'containers/Auth/SignUp/SignupFields';
import saga from 'containers/Auth/SignUp/saga';
import { key } from 'containers/Auth/SignUp/constants';

export class SignupWidget extends Component {
  constructor(props) {
    super(props);
    const talentToken = get(props, 'match.params.talentToken', '');
    const referralToken = get(props, 'location.referralToken', '');
    const params = get(props, 'location.params', {});
    const referralId = params.userId ? params.userId : '';
    this.state = {
      agencyTalent: false,
      talentToken,
      referralToken,
      disableEmail: false,
      isAgency: false,
      isRecruiter: false,
      isAmbassador: false,
      referral: referralId,
    };
  }

  componentDidMount() {
    const { talentToken, referral, referralToken } = this.state;
    const { onChangeReferral } = this.props;

    onChangeReferral(referral);
    if (talentToken) {
      this.getTalentDetails(talentToken);
    }
    if (referralToken) {
      this.getReferralDetails(referralToken);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isRecruiter, isAmbassador } = this.state;
    if (nextProps.location.pathname.split('/').includes('agency')) {
      StorageService.set('selectedRoleType', 'agency', { hash: true });
      this.setState({ isAgency: true });
    }
    if (nextProps.location.pathname.split('/').includes('talent-partner')) {
      StorageService.set('selectedRoleType', 'recruiter', { hash: true });
      // eslint-disable-next-line no-unused-expressions
      !isRecruiter && this.setState({ isRecruiter: true });
    }
    if (nextProps.location.pathname.split('/').includes('ambassador')) {
      StorageService.set('selectedRoleType', 'ambassador', { hash: true });
      // eslint-disable-next-line no-unused-expressions
      !isAmbassador && this.setState({ isAmbassador: true });
    }
  }

  getTalentDetails = (talentToken) => {
    const data = { method: 'GET' };
    const requestURL = `${API_URL}${AUTH}${AGENCY}${TALENT}${EMAIL}?token=${talentToken}`;
    request(requestURL, data)
      .then(this.setUserDetails)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  getReferralDetails = (referralToken) => {
    const data = { method: 'GET' };
    const requestURL = `${API_URL}${AUTH}${REFERRAL}${EMAIL}?token=${referralToken}`;
    request(requestURL, data)
      .then(this.setReferralDetails)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  setReferralDetails = (response) => {
    const { dispatch, onChangeEmail } = this.props;
    if (get(response, 'status')) {
      const { data } = response;
      const email = data;
      this.setState({ disableEmail: true });
      dispatch(change(key, 'email', email));
      onChangeEmail(email);
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message', '')} type="error" />, { className: 'Toast-error' });
    }
  };

  setUserDetails = (response) => {
    const { dispatch, onChangeEmail } = this.props;
    if (get(response, 'status')) {
      const { data } = response;
      const email = data;
      this.setState({ disableEmail: true, agencyTalent: true });
      dispatch(change(key, 'email', email));
      onChangeEmail(email);
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message', '')} type="error" />, { className: 'Toast-error' });
    }
  };

  render() {
    const { handleSubmit, onSubmitForm } = this.props;
    const { disableEmail, agencyTalent, talentToken, isAgency } = this.state;
    return (
      <>
        <form onSubmit={handleSubmit((e) => onSubmitForm(e, agencyTalent, talentToken))}>
          <OnboardingForm>
            <OnBoardingFormBody className={!isAgency ? 'm-0' : ''}>
              <SignupFields signupWidget {...this.props} disableEmail={disableEmail} talentActive isAgency={isAgency} />
            </OnBoardingFormBody>
          </OnboardingForm>
        </form>
      </>
    );
  }
}

SignupWidget.defaultProps = defaultProps;
SignupWidget.propTypes = propTypes;

export function mapDispatchToProps(dispatch) {
  return {
    onChangeEmail: (value) => dispatch(signUpEmail(value)),
    onChangeReferral: (value) => dispatch(signUpReferral(value)),
    onChangePassword: (evt) => {
      const passwordHashed = sha256(evt.target.value);
      dispatch(signUpPassword(passwordHashed));
    },
    onPrivacyPolicyCheck: (evt) => {
      const check = evt.target.value;
      if (!check) {
        dispatch(changePrivacyPolicy(false));
      } else {
        dispatch(changePrivacyPolicy(true));
      }
    },
    onSubmitForm: (evt, agencyTalent, talentToken) => {
      if (evt !== undefined && evt.preventDefault) {
        evt.preventDefault();
      }
      dispatch(loadRepos());
      dispatch(signUp(agencyTalent, talentToken));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  email: makeSelectEmail(),
  password: makeSelectPassword(),
  privacyCheck: makeSelectPrivacyCheck(),
  loading: makeSelectLoading(),
  responseSuccess: makeSelectSuccess(),
  responseError: makeSelectError(),
  referral: makeSelectReferralVal(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  reduxForm({
    form: key,
    initialValues: initialState,
    touchOnChange: true,
  }),
)(SignupWidget);
