import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { primaryNew, white } from 'themes/variables';
import media from 'themes/media';

export const ResponsiveSlider = styled(Carousel)`
  margin: 0 auto;
  .carousel {
    padding-bottom: 30px;
    .control-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      & .dot {
        width: 6px;
        height: 6px;
        background: rgba(${primaryNew}, 0.3);
        box-shadow: none;
        &.selected {
          background: rgba(${primaryNew});
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;
ResponsiveSlider.displayName = 'ResponsiveSlider';

export const SlideItem = styled.div`
  max-width: 510px;
  margin: 0 auto;
  padding: 0 15px;
  &.w-100 {
    max-width: 100%;
  }
`;
SlideItem.displayName = 'SlideItem';

export const SliderItemDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  .h-90 {
    height: 95%;
  }
`;

export const LeftSectionBlock = styled.div`
  align-items: center;
  display: flex;
  background: rgba(${primaryNew}, 0.03);
  position: relative;
  padding-bottom: 60px;
`;
LeftSectionBlock.displayName = 'LeftSectionBlock';

export const RightSection = styled.div`
  background-color: rgb(${white});
  display: grid;
  overflow: ${(props) => (props.isIframe ? 'hidden' : 'auto')};

  .inner-content {
    padding: ${(props) => (props.isIframe ? '0' : '30px')};

    ${media.large`
      padding: ${(props) => (props.isIframe ? '0' : '100px 100px 50px 100px')}
    `};
  }
`;
RightSection.displayName = 'RightSection';

export const FooterContent = styled.div`
  position: absolute;
  padding: 0 30px;
  bottom: 30px;
  width: 100%;
`;
FooterContent.displayName = 'FooterContent';

export const SignInContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${RightSection}, ${LeftSectionBlock} {
    height: 100vh;
  }
  @media (min-width: 992px) {
    grid-template-columns: auto 560px;
  }
  ${media.large`
    grid-template-columns: auto 660px;
  `};
  &.iframe-content- {
    &w-90 {
      width: 90%;
      margin: auto;
    }
    &w-80 {
      width: 80%;
      margin: auto;
    }
  }
`;
SignInContent.displayName = 'SignInContent';
