/**
 * Add a company saga
 */
import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';
import ToastifyMessage from 'components/ToastifyMessage';
import { repoLoadingError, isLoading, reset } from 'containers/App/actions';
import { API_URL, CLIENT, SAVE_LATER_API, COMPANY, LOCATION } from 'containers/App/constants';
import request from 'utils/request';
import { SUBMIT_ADD_A_COMPANY_FORM, SUBMIT_COMPANY_LOCATION_FORM, SUBMIT_CREATE_ACCOUNT } from './constants';

/**
 * user Forget request/response handler
 */
export function* addACompanyDetails(data) {
  try {
    const { payload: submitType, data: body, onSuccess } = data;

    const formData = new FormData();
    formData.append('logo', body.logo);
    formData.append('name', body.name);
    formData.append('brand', body.brand);
    formData.append('registeredNumber', body.registeredNumber);
    formData.append('vatNumber', body.vatNumber ? body.vatNumber : 0);
    formData.append('industry', body.industry);
    formData.append('companyType', body.companyType);
    formData.append('cultures', body.cultures);
    formData.append('linkedInUrl', body.linkedInUrl);
    formData.append('gitHubUrl', body.gitHubUrl);
    formData.append('stackOverFlowUrl', body.stackOverFlowUrl);
    formData.append('dribbbleUrl', body.dribbbleUrl);
    formData.append('behanceUrl', body.behanceUrl);
    formData.append('portfolioUrl', body.portfolioUrl);
    formData.append('glassdoorUrl', body.glassdoorUrl);
    formData.append('corporateWebsiteUrl', body.corporateWebsiteUrl);
    formData.append('bio', body.bio);

    const apiCallData = {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      data: formData,
    };

    let requestURL = '';
    if (submitType === 'saveForLater') {
      requestURL = `${API_URL}${CLIENT}${SAVE_LATER_API}`;
    } else if (submitType === 'continue') {
      requestURL = `${API_URL}${COMPANY}`;
    }

    // Call our request helper (see 'utils/request')
    const log = yield call(request, requestURL, apiCallData);
    if (get(log, 'status')) {
      yield put(reset());
      yield put(isLoading(false));
      if (submitType === 'continue') {
        toast.success(<ToastifyMessage message={get(log, 'message')} type="success" />, {
          className: 'Toast-success',
        });
        onSuccess(get(log, 'data'), 2);
      } else if (submitType === 'saveForLater') {
        toast.success(<ToastifyMessage message={get(log, 'message')} type="success" />, {
          className: 'Toast-success',
        });
        yield put(push({ pathname: '/admin/company-listing' }));
      }
    } else {
      toast.error(log.message, { className: 'Toast-error' });
      yield put(repoLoadingError(log.response.statusText));
    }
  } catch (err) {
    yield put(repoLoadingError(err));
  }
}

export function* getCompanyLocations(data) {
  const { payload: submitType, data: body, onSuccess } = data;

  let method = 'PUT';
  let requestURL = '';

  if (submitType === 'add') {
    method = 'POST';
  } else if (submitType === 'edit') {
    method = 'PUT';
  }

  const apiCallData = { method, body };

  if (submitType === 'saveForLater') {
    apiCallData.body.step = '3';
    requestURL = `${API_URL}${CLIENT}${SAVE_LATER_API}`;
  } else if (submitType === 'add' || submitType === 'edit') {
    requestURL = `${API_URL}${COMPANY}${LOCATION}`;
  }

  try {
    // Call our request helper (see 'utils/request')
    const log = yield call(request, requestURL, apiCallData);
    if (get(log, 'status')) {
      yield put(reset());
      yield put(isLoading(false));
      if (submitType === 'add' || submitType === 'edit') {
        if (typeof onSuccess === 'function') {
          onSuccess(get(log, 'data'));
        }
      } else if (submitType === 'saveForLater') {
        toast.success(<ToastifyMessage message={get(log, 'message')} type="success" />, {
          className: 'Toast-success',
        });
      }
    } else {
      toast.error(log.message, { className: 'Toast-error' });
      yield put(repoLoadingError(log.response.statusText));
    }
  } catch (err) {
    yield put(repoLoadingError(err));
  }
}

export function* submitInviteAdmin(data) {
  const { data: body, onSuccess } = data;

  let method = 'POST';
  const requestURL = `${API_URL}${COMPANY}/invite-admin`;

  if (get(body, 'id', '')) {
    method = 'PUT';
  }

  const apiCallData = { method, body };

  try {
    // Call our request helper (see 'utils/request')
    const log = yield call(request, requestURL, apiCallData);
    if (get(log, 'status')) {
      yield put(reset());
      yield put(isLoading(false));

      if (typeof onSuccess === 'function') {
        toast.success(<ToastifyMessage message={get(log, 'message')} type="success" />, {
          className: 'Toast-success',
        });
        onSuccess();
      }
    } else {
      toast.error(log.message, { className: 'Toast-error' });
      yield put(repoLoadingError(log.response.statusText));
    }
  } catch (err) {
    yield put(repoLoadingError(err));
  }
}

export default function* aboutCompanyForm() {
  yield takeLatest(SUBMIT_CREATE_ACCOUNT, submitInviteAdmin);
  yield takeLatest(SUBMIT_COMPANY_LOCATION_FORM, getCompanyLocations);
  yield takeLatest(SUBMIT_ADD_A_COMPANY_FORM, addACompanyDetails);
}
