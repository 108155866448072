/*
 * Sign Up Page Constants
 */

export const key = 'signup';

export const SIGN_UP_EMAIL = 'Notchup/SignUp/SIGN_UP_EMAIL';
export const SIGN_UP_PASSWORD = 'Notchup/SignUp/SIGN_UP_PASSWORD';
export const CHANGE_PRIVACY_POLICY = 'Notchup/SignUp/CHANGE_PRIVACY_POLICY';
export const SIGN_UP = 'Notchup/App/SignUp/';
export const REFERRAL = 'Notchup/App/SignUp/REFERRAL';
export const CHANGE_ACCOUNT_TYPE = 'Notchup/App/SignUp/CHANGE_ACCOUNT_TYPE';
export const CHANGE_USER_TYPE = 'Notchup/App/SignUp/CHANGE_USER_TYPE';
