/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  LOAD_REPOS,
  LOAD_REPOS_SUCCESS,
  LOAD_REPOS_ERROR,
  RESET,
  VERIFY,
  VERIFY_PHONE_OTP,
  RESEND_CODE,
  LOADING,
  LOGOUT,
  POP_UP_SAGA,
  SHOW_VETTED_BANNER,
  SHOW_COMPLETE_PROFILE_BANNER,
} from './constants';

/**
 * Load the repositories, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_REPOS
 */
export function loadRepos() {
  return {
    type: LOAD_REPOS,
  };
}

/**
 * Dispatched when the repositories are loaded by the request saga
 *
 * @param  {array} repos The repository data
 * @param  {string} username The current username
 *
 * @return {object}      An action object with a type of LOAD_REPOS_SUCCESS passing the repos
 */
export function reposLoaded(repos, username) {
  return {
    type: LOAD_REPOS_SUCCESS,
    repos,
    username,
  };
}

/**
 * Dispatched when loading the repositories fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */
export function repoLoadingError(error) {
  return {
    type: LOAD_REPOS_ERROR,
    error,
  };
}

/**
 * Dispatched when loading the repositories fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */
export function verification() {
  return {
    type: VERIFY,
  };
}

export function verificationPhoneOTP() {
  return {
    type: VERIFY_PHONE_OTP,
  };
}

/**
 * Dispatched when loading the repositories fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */
export function resendCode() {
  return {
    type: RESEND_CODE,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

export function isLoading(payload) {
  return {
    type: LOADING,
    payload,
  };
}

/**
 * Dispatched when the user click on logout button
 *
 * @return {object} An action object with a type of LOGOUT passing the auth
 */
export function userLogout() {
  return {
    type: LOGOUT,
  };
}

export function popUpSagaAction(payload) {
  return {
    type: POP_UP_SAGA,
    payload,
  };
}

export function showVettedBanner(payload) {
  return {
    type: SHOW_VETTED_BANNER,
    payload,
  };
}

export function showCompleteProfileBanner(payload) {
  return {
    type: SHOW_COMPLETE_PROFILE_BANNER,
    payload,
  };
}
