import styled from 'styled-components';
import { OnboardingForm } from 'components';

export const OnboardingFormContainer = styled(OnboardingForm)`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

  background-color: #100e25 !important;
  button {
    font-family: 'Poppins SemiBold', sans-serif !important;
    font-weight: 600;
  }
  input {
    font-family: 'Poppins', sans-serif !important;
  }
`;
