/* eslint-disable prettier/prettier */
import React from 'react';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { Row, Col, DropdownMenu, DropdownToggle, FormGroup } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { Helmet } from 'react-helmet';
import debounce from 'lodash/debounce';
import Pagination from 'rc-pagination';
import { userExists, proxyLogin } from 'utils/Helper';
import AuthTokenService from 'utils/AuthTokenService';
import get from 'lodash/get';
import Content from 'components/Content';
import { FormattedMessage } from 'react-intl';
import { PrivateGrid, H3, P, Badge, ToastifyMessage, Button, FormLabel } from 'components';
import StorageService from 'utils/StorageService';
import request from 'utils/request';
import { VALIDATION } from 'utils/constants';
import {
  API_URL,
  JobEmptyIcon,
  TALENT,
  backIcon,
  importIcon,
  pdfIcon,
  csvIcon,
  calendarIcon,
  leftArrowIcon,
  transactionCustomStyles,
  currencyData,
} from 'containers/App/constants';
import { localeInfo } from 'containers/TalentListingPage/constants';
import { redirectBack } from 'containers/App/utils';
import containerMessage from 'containers/messages';
import { propTypes } from 'containers/proptypes';
import SearchComponent from 'components/SearchComponent';
import { DBcontainer } from 'containers/Talent/Dashboard/styles';
import { CardContainer, WalletListingFilter, WalletListingExport, TransTypetListing } from './styles';
import SVG from 'react-inlinesvg';
import messages from './messages';
import { compose } from 'redux';
import DatePicker from 'react-datepicker';
import { DatePickerContainer } from 'components/DatePickers/style';
import { key, columns, LinearIndeterminate } from './constants';
import { getCurrencySymbol } from 'containers/MyProfilePage/components/utils';
import DataTable from 'react-data-table-component';
import EmptyScreenCard from 'components/EmptyScreens/EmptyScreenCard';
import { primaryDarkNew } from 'themes/variables';
import moment from 'moment';
import { FormControlRadio } from 'components/Input';
import { searchUrl, textItemRender } from 'containers/TalentListingPage/utils';
import 'rc-pagination/assets/index.css';

moment.suppressDeprecationWarnings = true;

export class WalletTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNum: '1',
      paginationData: {},
      transactionsList: [],
      isListLoading: false,
      pagination: '',
      search: '',
      startDate: '',
      endDate: '',
      exportdropdownOpen: false,
      timedropdownOpen: false,
      transDropdown: false,
      dateFilterValue: '',
      dateType: 'All time',
      trancationType: 'All transactions',
    };
  }

  componentDidMount() {
    const { pageNum } = this.state;
    this.loadWalletTransactions(pageNum);
  }

  loadWalletTransactions = (pageNum) => {
    this.setState({ isListLoading: true });
    const data = { method: 'GET' };
    const { search, dateType, startDate, endDate, trancationType } = this.state;
    let requestURL = `${API_URL}${TALENT}/wallet/transactions?page=${pageNum}&limit=${10}`;
    requestURL += searchUrl(search);

    const start = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const end = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
    requestURL += dateType ? `&duration=${dateType}` : '';
    requestURL += start ? `&specificDateStart=${start}` : '';
    requestURL += end ? `&specificDateEnd=${end}` : '';
    requestURL += trancationType === 'Deposit' ? `&type=${trancationType}` : '';
    requestURL += trancationType === 'Withdrawal' ? `&type=${trancationType}` : '';
    request(requestURL, data)
      .then(this.setTransactions)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  setTransactions = (response) => {
    if (get(response, 'status')) {
      const data = Object.assign({}, response.data);

      const array = [];
      const temp = response.data.page * response.data.limit - (response.data.limit - 1);
      data.docs.forEach((transaction, index) => {
        const currencySymbol = getCurrencySymbol(currencyData, 'code', get(transaction, 'currency'));
        const id = get(transaction, '_id');

        const details = get(transaction, 'description');
        const deposit = get(transaction, 'type') === 'CREDIT' ? currencySymbol + get(transaction, 'amount') : '-';
        const withdrawal = get(transaction, 'type') === 'WITHDRAWN' ? currencySymbol + get(transaction, 'amount') : '-';
        const balance = currencySymbol + get(transaction, 'balance');
        const date = moment(new Date(get(transaction, 'createdAt', ''))).format('DD/MM/YYYY');

        array.push({
          id,
          number: temp + index,
          date,
          details,
          deposit,
          withdrawal,
          balance,
        });
      });
      this.setState({ transactionsList: array, paginationData: get(response, 'data', {}), isListLoading: false });
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
    }
  };

  debounceFn = debounce((value) => this.handleSearchChange(value), 500);

  handleSearchChange = (value) => {
    StorageService.set('transactionWallet', value);
    this.setState({ search: value }, () => {
      this.loadWalletTransactions(1);
    });
  };

  handleFilterChange = (e) => {
    this.setState({ dateType: e, dateFilterValue: '' }, () => {
      this.loadWalletTransactions(1);
      if (e !== 'Specific dates') {
        this.toggleDropdown(!this.state.timedropdownOpen, 'timedropdownOpen');
      }
    });
    if (e !== 'Specific dates') {
      this.setState({ startDate: '', endDate: '' });
    }
  };

  toggleDropdown = (flag, dropdownKey) => {
    this.setState({ [dropdownKey]: flag });
  };

  handleExportFile = async (e) => {
    this.toggleDropdown(!this.state.exportdropdownOpen, 'exportdropdownOpen');
    const option = {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };

    if (userExists() && proxyLogin()) {
      option.headers.Authorization = `${proxyLogin()}`;
    } else if (userExists()) {
      option.headers.Authorization = `${AuthTokenService.get()}`;
    }

    let requestURL = '';
    if (e === 'pdf') {
      requestURL = `${API_URL}${TALENT}/wallet/transactions/pdf/download`;
    } else {
      requestURL = `${API_URL}${TALENT}/wallet/transactions/csv/download`;
    }
    try {
      const downloadResult = await fetch(requestURL, option);
      const blob = await downloadResult.blob();
      saveAs(blob);
    } catch (error) {
      toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
    }
  };

  handleApplyDate = (item) => {
    const { startDate, endDate, timedropdownOpen } = this.state;
    const start = moment(startDate).format('DD/MM/YYYY');
    const end = moment(endDate).format('DD/MM/YYYY');
    if (item === 'Specific dates') {
      this.setState({ dateFilterValue: `${start} - ${end}`, timedropdownOpen: false }, () => {
        this.loadWalletTransactions(1);
      });
    } else {
      this.setState({ dateFilterValue: item, timedropdownOpen: false }, () => {
        this.loadWalletTransactions(1);
      });
    }
    this.toggleDropdown(!timedropdownOpen, 'timedropdownOpen');
  };

  handleDateRangeFilter = (type, date) => {
    if (type === 'start') {
      this.setState({ startDate: date });
    }
    if (type === 'end') {
      this.setState({ endDate: date });
    }
  };

  handletransactionChange = (item) => {
    this.setState({ trancationType: item }, () => {
      this.loadWalletTransactions(1);
      this.toggleDropdown(!this.state.transDropdown, 'transDropdown');
    });
  };
  render() {
    const {
      transactionsList,
      isListLoading,
      paginationData,
      search,
      trancationType,
      dateType,
      exportdropdownOpen,
      timedropdownOpen,
      transDropdown,
      dateFilterValue,
      startDate,
      endDate,
    } = this.state;

    const { history } = this.props;
    const path = '/talent/wallet';

    return (
      <React.Fragment>
        <Helmet>
          <title>{messages.title.defaultMessage}</title>
          <meta name="description" content={messages.metaTitle.defaultMessage} />
        </Helmet>
        <Content>
          <PrivateGrid>
            <DBcontainer>
              <Row className="mb-3">
                <Col lg={12} className="d-flex align-items-center mb-20">
                  <Button type="button" onClick={() => redirectBack(history, path)} className="btn-link">
                    <SVG src={backIcon} />
                  </Button>
                  <H3 className="ms-3">
                    <FormattedMessage {...messages.transactions} />
                  </H3>
                  <Badge className="ms-2 primary badge-sm">
                    <P className="p14 mb-0" lineHeight="16">
                      {paginationData?.totalDocs || 0}
                    </P>
                  </Badge>
                  <div className="d-flex ms-auto align-items-center mt-3 mt-lg-0">
                    <SearchComponent
                      className="select-input"
                      customClass="search-lg serach-bg "
                      handleSearchChange={this.debounceFn}
                      placeholder={containerMessage.searchPlaceholder.defaultMessage}
                      initialValue={search}
                    />
                    <WalletListingExport
                      onFocus={() => {}}
                      isOpen={exportdropdownOpen}
                      toggle={() => this.toggleDropdown(!exportdropdownOpen, 'exportdropdownOpen')}
                    >
                      <DropdownToggle>
                        <img src={importIcon} alt="s-logo" className="selected-logo" />
                        <div className="ms-2">
                          <FormattedMessage {...messages.exportTitle} />
                        </div>
                        <SVG src={leftArrowIcon} />
                      </DropdownToggle>
                      <DropdownMenu>
                        {[
                          { label: `PDF`, value: 'pdf', icon: pdfIcon },
                          { label: 'CSV', value: 'csv', icon: csvIcon },
                        ].map((e) => (
                          <div className="dropdown-item" key={e.value}>
                            <div className="d-flex">
                              <img src={e.icon} alt="s-logo" className="selected-logo" />
                              <P className="p16 m-0 ps-3" opacityVal="0.7" onClick={() => this.handleExportFile(e.value)}>
                                {e.label}
                              </P>
                            </div>
                          </div>
                        ))}
                      </DropdownMenu>
                    </WalletListingExport>
                    <WalletListingFilter
                      onFocus={() => {}}
                      isOpen={timedropdownOpen}
                      toggle={() => this.toggleDropdown(!timedropdownOpen, 'timedropdownOpen')}
                    >
                      <DropdownToggle>
                        <img src={calendarIcon} alt="s-logo" />
                        <div className={`${dateType === 'Specific dates' && 'active'} ms-2 `}>
                          {dateFilterValue !== '' ? dateFilterValue : dateType === 'Specific dates' ? '' : dateType}
                        </div>
                        <SVG src={leftArrowIcon} />
                      </DropdownToggle>
                      <DropdownMenu>
                        {[
                          { label: 'All time', value: 'All time' },
                          { label: 'Last 90 days', value: 'Last 90 days' },
                          { label: 'Last 180 days', value: 'Last 180 days' },
                          { label: 'Specific dates', value: 'Specific dates' },
                        ].map((item) => (
                          <div className="dropdown-item" key={item.value}>
                            <FormControlRadio className="d-flex radio-div">
                              <label className={`radio-label`}>
                                <input
                                  id={item.value}
                                  type="radio"
                                  value={item.value}
                                  name="status"
                                  className="radio-sm"
                                  onChange={(e) => this.handleFilterChange(item.value)}
                                  checked={dateType === item.value}
                                />
                                <span className="checkmark" />
                                <P className={`${dateType === item.value && 'active'} p16 m-0`} opacityVal="0.7">
                                  {item.label}
                                </P>
                              </label>
                            </FormControlRadio>
                            {item.value === 'Specific dates' && (
                              <div>
                                <div className={`${item.value === 'Specific dates' ? 'opacity-20' : 'opacity-70'} mb-3 ms-4 mt-2 `}>
                                  <DatePickerContainer>
                                    <FormGroup>
                                      <FormLabel className="p14 mb-0">From</FormLabel>
                                      <div className="position-relative">
                                        <DatePicker
                                          selected={startDate}
                                          onChange={(date) => this.handleDateRangeFilter('start', date)}
                                          selectsStart
                                          startDate={startDate}
                                          endDate={endDate}
                                          className="form-control"
                                          readOnly={dateType !== 'Specific dates' && true}
                                          placeholderText={containerMessage.placeholderDate.defaultMessage}
                                          dateFormat="dd/MM/yyyy"
                                        />
                                        <SVG src={calendarIcon} />
                                      </div>
                                    </FormGroup>
                                    <FormGroup>
                                      <FormLabel className="p14 mb-0">To</FormLabel>
                                      <div className="position-relative">
                                        <DatePicker
                                          selected={endDate}
                                          onChange={(date) => this.handleDateRangeFilter('end', date)}
                                          selectsStart
                                          minDate={startDate}
                                          startDate={endDate}
                                          endDate={endDate}
                                          readOnly={dateType !== 'Specific dates'}
                                          className="form-control"
                                          placeholderText={containerMessage.placeholderDate.defaultMessage}
                                          dateFormat="dd/MM/yyyy"
                                        />
                                        <SVG src={calendarIcon} />
                                      </div>
                                    </FormGroup>
                                  </DatePickerContainer>
                                </div>
                                <div>
                                  <Button
                                    className="btn btn-primary mt-2 me-0 float-end mb-2"
                                    disabled={!startDate && !endDate}
                                    onClick={() => this.handleApplyDate(dateType)}
                                  >
                                    Apply
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </DropdownMenu>
                    </WalletListingFilter>
                    <TransTypetListing
                      onFocus={() => {}}
                      isOpen={transDropdown}
                      toggle={() => this.toggleDropdown(!transDropdown, 'transDropdown')}
                    >
                      <DropdownToggle>
                        <div className="ms-2">{trancationType}</div>
                        <SVG src={leftArrowIcon} />
                      </DropdownToggle>
                      <DropdownMenu>
                        {[
                          { label: 'All transactions', value: 'All transactions' },
                          { label: 'Deposit', value: 'Deposit' },
                          { label: 'Withdrawal', value: 'Withdrawal' },
                        ].map((item) => (
                          <div className="dropdown-item" key={item.value}>
                            <div className="d-flex">
                              <P
                                className={`${trancationType === item.value && 'active'} p16 m-0`}
                                opacityVal="0.7"
                                onClick={() => this.handletransactionChange(item.value)}
                              >
                                {item.label}
                              </P>
                            </div>
                          </div>
                        ))}
                      </DropdownMenu>
                    </TransTypetListing>
                  </div>
                </Col>
              </Row>
            </DBcontainer>
            <CardContainer className="p-0 rounded-bordered-card">
              <DataTable
                noHeader
                columns={columns}
                data={transactionsList}
                totalRows={0}
                direction="ltr"
                progressPending={isListLoading}
                progressComponent={<LinearIndeterminate />}
                paginationComponentOptions={{ noRowsPerPage: true }}
                selectableRows
                overflowX
                noDataComponent={
                  <>
                    <EmptyScreenCard
                      cardTitle={messages.noRecordTitle}
                      cardDescription={messages.noRecordDesc}
                      icon={JobEmptyIcon}
                      textContainersClasses="text-muted d-flex flex-column align-items-start align-items-sm-center mt-3 mb-3 text-left text-md-center"
                      cardClasses="d-flex flex-column align-items-start align-items-sm-center p-40 mb-0 border-0 border-none"
                    />
                  </>
                }
                customStyles={{
                  ...transactionCustomStyles,
                  headCells: {
                    style: {
                      ...transactionCustomStyles.headCells.style,
                      color: '#04004E',
                    },
                  },
                  cells: {
                    style: {
                      ...transactionCustomStyles.cells.style,
                      color: `rgb(${primaryDarkNew},0.5)`,
                      textAlign: 'left',
                      paddingRight: '0px',
                    },
                  },
                }}
              />
            </CardContainer>
            {paginationData?.totalDocs > 10 ? (
              <Pagination
                total={paginationData.totalDocs}
                className="ant-pagination"
                current={paginationData.page}
                defaultPageSize={paginationData?.limit}
                onChange={this.loadWalletTransactions}
                itemRender={textItemRender}
                locale={localeInfo}
              />
            ) : (
              ''
            )}
          </PrivateGrid>
        </Content>
      </React.Fragment>
    );
  }
}

WalletTransactions.propTypes = propTypes;

export default compose(
  reduxForm({
    form: key,
    touchOnChange: true,
  }),
)(WalletTransactions);
