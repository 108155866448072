import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {
  textFileIcon,
  projectsIcon,
  calendarWithTimeIcon,
  quotesIcon,
  interviewIcon,
  winnerCupThin,
  teamIcon,
} from 'containers/App/constants';
import { get } from 'lodash';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

export const notificationTimeAgo = (createdAt) => timeAgo.format(new Date(createdAt));

export const talentNotficationRedirectTo = (notificationType, extra) => {
  let output = { link: '', icon: '', subTitle: '' };

  switch (notificationType) {
    case 'INTERVIEW_REQUESTED':
      output = { link: `/talent/interviews/${get(extra, 'metaData.interviewId')}`, icon: interviewIcon, subTitle: 'Interview' };
      break;
    case 'BRIEF_ADDED':
      output = { link: '/talent/job-briefs', icon: textFileIcon, subTitle: 'Briefs' };
      break;
    case 'TALENT_ADDED':
      output = { link: '/talent/my-projects', icon: projectsIcon, subTitle: 'Projects' };
      break;
    case 'TIMESHEET_APPROVED':
    case 'TIMESHEET_INREVIEW':
    case 'TIMESHEET_SETTELED':
      output = { link: '/talent/timesheets', icon: calendarWithTimeIcon, subTitle: 'Timesheets' };
      break;
    case 'NEW_BOUNTY':
      output = {
        link: `/${get(extra, 'metaData.companyBrand')}/competition-detail/${get(extra, 'metaData.slug')}`,
        icon: winnerCupThin,
        subTitle: 'Competitions',
      };
      break;
    case 'TEAM_INVITE':
      output = {
        // link: `/talent/team/${get(extra, 'metaData.teamId')}`,
        icon: teamIcon,
        subTitle: 'Teams',
      };
      break;

    default:
  }
  return output;
};

export const agencyNotficationRedirectTo = (notificationType) => {
  let output = { link: '', icon: '', subTitle: '' };

  switch (notificationType) {
    case 'NEW_QUOTE':
      output = { link: '/talent/quotes', icon: quotesIcon, subTitle: 'Quotes' };
      break;
    case 'TALENT_ADDED':
      output = { link: '/talent/agency-projects', icon: projectsIcon, subTitle: 'Projects' };
      break;
    case 'TIMESHEET_SUBMIT':
    case 'TIMESHEET_INREVIEW':
    case 'TIMESHEET_APPROVED':
    case 'TIMESHEET_SETTELED':
      output = { link: '/talent/timesheets', icon: calendarWithTimeIcon, subTitle: 'Timesheets' };
      break;
    case 'BRIEF_ADDED':
      output = { link: '/talent/job-briefs', icon: textFileIcon, subTitle: 'Briefs' };
      break;
    default:
  }
  return output;
};

export const clientNotficationRedirectTo = (notificationType, extra) => {
  let output = { link: '', icon: '', subTitle: '' };

  switch (notificationType) {
    case 'INTERVIEW_SLOT_ACCEPTED':
    case 'INTERVIEW_SLOT_REJECTED':
    case 'INTERVIEW_CANCELLED':
    case 'INTERVIEW_REMINDER':
      output = { link: `/client/interviews/${get(extra, 'metaData.interviewId')}`, icon: interviewIcon, subTitle: 'Interview' };
      break;
    case 'TALENT_ADDED':
      output = { link: '/client/projects', icon: projectsIcon, subTitle: 'Projects' };
      break;
    case 'TIMESHEET_SUBMIT':
      output = { link: '/client/timesheets', icon: calendarWithTimeIcon, subTitle: 'Timesheets' };
      break;
    case 'NEW_BOUNTY_SUBMISSION':
      output = {
        link: `/${get(extra, 'metaData.companyBrand')}/competition-detail/${get(extra, 'metaData.slug')}`,
        icon: winnerCupThin,
        subTitle: 'Competitions',
      };
      break;
    default:
  }
  return output;
};

export const notificationRedirectTo = (userType, notificationType, extra) => {
  let output = '';
  switch (userType) {
    case '1':
      output = talentNotficationRedirectTo(notificationType, extra);
      break;
    case '2':
      output = clientNotficationRedirectTo(notificationType, extra);
      break;
    case '3':
      output = agencyNotficationRedirectTo(notificationType);
      break;
    default:
  }
  return output;
};
