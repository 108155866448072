/* eslint-disable arrow-body-style */
/**
 * Invite Friends Page
 *
 */

import React, { Component } from 'react';
import { Field, change } from 'redux-form';
import * as formValidations from 'utils/formValidations';
import { renderField } from 'utils/Fields';
import { FormGroup } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FormLabel, P } from 'components';
import { CLIENT } from 'containers/App/constants';
import containerMessage from 'containers/messages';
import messages from './messages';
import { propTypes } from './proptypes';

export class InvitePeople extends Component {
  handleChangeInviteMail = (e, index) => {
    const { dispatch, formKey: key, onChangeInvite, inviteMails } = this.props;
    const newInviteMail = e.target.value;

    dispatch(change(key, `email${index}`, newInviteMail));

    const invite = inviteMails;
    invite[index].email = newInviteMail;
    onChangeInvite(invite);
  };

  handleChangeInviteName = (e, index) => {
    const { dispatch, formKey: key, onChangeInvite, inviteMails } = this.props;
    const newInviteName = e.target.value;

    dispatch(change(key, `name${index}`, newInviteName));

    const invite = inviteMails;
    invite[index].name = newInviteName;
    onChangeInvite(invite);
  };

  handleChangeInviteRole = (e, index) => {
    const { dispatch, formKey: key, onChangeInvite, inviteMails } = this.props;
    const newInviteRole = e.target.value;

    dispatch(change(key, `role${index}`, newInviteRole));
    const invite = inviteMails;
    invite[index].role = newInviteRole;
    onChangeInvite(invite);
  };

  handleChangeInviteCompanyDetails = (e, index) => {
    const { dispatch, formKey: key, onChangeInvite, inviteMails } = this.props;
    const newInvitecompanyDetails = e.target.value;

    dispatch(change(key, `companyDetails${index}`, newInvitecompanyDetails));
    const invite = inviteMails;
    invite[index].companyDetails = newInvitecompanyDetails;
    onChangeInvite(invite);
  };

  render() {
    const { inviteMails, referEarnType } = this.props;

    return (
      <React.Fragment>
        <form>
          <table className="table">
            <thead>
              <tr>
                <td className="border-0 py-1 px-0">
                  <FormLabel>
                    <FormattedMessage {...containerMessage.labelEmailAddress} />
                  </FormLabel>
                </td>
                <td className="border-0 py-1 px-0">
                  <FormLabel className="d-inline-flex align-items-center">
                    <FormattedMessage {...messages.labelFullName} />
                    <P className="p14 ms-1 mb-0 text-capitalize" opacityVal="0.5">
                      <FormattedMessage {...containerMessage.optionalText} />
                    </P>
                  </FormLabel>
                </td>
                {referEarnType === CLIENT && (
                  <React.Fragment>
                    <td className="border-0 py-1 px-0">
                      <FormLabel className="d-inline-flex align-items-center">
                        <FormattedMessage {...messages.labelRole} />
                        <P className="p14 ms-1 mb-0 text-capitalize" opacityVal="0.5">
                          <FormattedMessage {...containerMessage.optionalText} />
                        </P>
                      </FormLabel>
                    </td>
                    <td className="border-0 py-1 px-0">
                      <FormLabel className="d-inline-flex align-items-center">
                        <FormattedMessage {...messages.labelCompany} />
                        <P className="p14 ms-1 mb-0 text-capitalize" opacityVal="0.5">
                          <FormattedMessage {...containerMessage.optionalText} />
                        </P>
                      </FormLabel>
                    </td>
                  </React.Fragment>
                )}
              </tr>
            </thead>
            <tbody className="border-1">
              {inviteMails.map((inviteMail, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={`${index}inviteMail`}>
                    <td className="py-1 px-0">
                      <FormGroup className="input-sm mb-0">
                        <Field
                          wrapperClassName="shadow-none border-0"
                          name={`email${index}`}
                          component={renderField}
                          type="text"
                          defaultValue={inviteMails[index].email}
                          value={inviteMails[index].email}
                          placeholder={containerMessage.placeholderEmailAddress.defaultMessage}
                          onChange={(e) => this.handleChangeInviteMail(e, index)}
                          validate={[formValidations.email]}
                        />
                      </FormGroup>
                    </td>
                    <td className="py-1 px-0">
                      <FormGroup className="input-sm left-border mb-0">
                        <Field
                          wrapperClassName="shadow-none border-0"
                          name={`name${index}`}
                          component={renderField}
                          type="text"
                          defaultValue={inviteMails[index].name}
                          value={inviteMails[index].name}
                          placeholder={messages.placeholderFullName.defaultMessage}
                          onChange={(e) => this.handleChangeInviteName(e, index)}
                        />
                      </FormGroup>
                    </td>
                    {referEarnType === CLIENT && (
                      <React.Fragment>
                        <td className="py-1 px-0">
                          <FormGroup className="input-sm left-border mb-0">
                            <Field
                              wrapperClassName="shadow-none border-0"
                              name={`role${index}`}
                              component={renderField}
                              type="text"
                              defaultValue={inviteMails[index].role}
                              placeholder={messages.labelRole.defaultMessage}
                              onChange={(e) => this.handleChangeInviteRole(e, index)}
                            />
                          </FormGroup>
                        </td>
                        <td className="py-1 px-0">
                          <FormGroup className="input-sm left-border mb-0">
                            <Field
                              wrapperClassName="shadow-none border-0"
                              name={`companyDetails${index}`}
                              component={renderField}
                              type="text"
                              defaultValue={inviteMails[index].companyDetails}
                              placeholder={messages.labelCompany.defaultMessage}
                              onChange={(e) => this.handleChangeInviteCompanyDetails(e, index)}
                            />
                          </FormGroup>
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </form>
      </React.Fragment>
    );
  }
}

InvitePeople.propTypes = propTypes;

export default InvitePeople;
