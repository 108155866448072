/*
 * InviteClient Messages
 *
 * This contains all the text for the InviteClient component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.componentes.Modals.InviteClient';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Invite Client',
  },
  metaTitle: {
    id: `${scope}.metaTitle`,
    defaultMessage: 'Invite Client',
  },
});
