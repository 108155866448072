/** AddACompanyModal
 *
 */
import React from 'react';
import { H4, Button, FormLabel, ToastifyMessage } from 'components';
import ReactModal from 'react-modal';
import { arrowRightIcon, closeIcon, copyIcon } from 'containers/App/constants';
import containerMessage from 'containers/messages';
import { FormGroup } from 'reactstrap';
import { renderField } from 'utils/Fields';
import SVG from 'react-inlinesvg';
import { toast } from 'react-toastify';

const AddACompanyModal = (props) => {
  const { openDeleteModal, setOpenDeleteModal, url, onContinue } = props;

  const copyToclipboard = () => {
    navigator.clipboard.writeText(url);
    toast.success(<ToastifyMessage message="URL copied to clipboard" type="success" />, {
      className: 'Toast-success',
    });
  };

  return (
    <ReactModal
      isOpen={openDeleteModal}
      contentLabel={containerMessage.archiveModalTitle.defaultMessage}
      onRequestClose={() => {
        onContinue();
        setOpenDeleteModal(false);
      }}
      className="modal-dialog secondary-modal"
      style={{ overlay: { zIndex: 12 } }}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      ariaModal
    >
      <div className="modal-content">
        <div className="modal-header modal-close">
          <button
            type="button"
            className="modal-dismiss"
            onClick={() => {
              onContinue();
              setOpenDeleteModal(false);
            }}
          >
            <img src={closeIcon} alt="close" />
            <span className="visually-hidden">Close</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <p className="p16 text-success mb-4">Account successfully created</p>
          </div>
          <H4 className="newH4 mt-0">Share profile</H4>

          <FormGroup>
            <FormLabel>Profile link</FormLabel>
            {renderField({
              label: 'Profile link',
              type: 'text',
              meta: { touched: false, error: false, warning: false, active: false },
              readOnly: true,
              disabled: true,
              value: url,
            })}
          </FormGroup>
          <div className="d-flex flex-column">
            <Button className="btn btn-primary mb-3" type="button" onClick={copyToclipboard}>
              Copy profile link
              <SVG src={copyIcon} className="ms-2" />
            </Button>

            <Button className="btn-outline btn" type="button" onClick={onContinue}>
              Continue to dashboard
              <SVG src={arrowRightIcon} className="ms-2" />
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default AddACompanyModal;
