/**
 * Gets the repositories of the user from Github
 */

import React from 'react';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import has from 'lodash/has';
import { push } from 'react-router-redux';
import { repoLoadingError, reset } from 'containers/App/actions';
import { makeSelectLocation } from 'containers/App/selectors';
import request from 'utils/request';
import Emitter from 'utils/emitter';
import { storeApiSignupStep } from 'containers/Auth/utils';
import AuthTokenService from 'utils/AuthTokenService';
import StorageService from 'utils/StorageService';
import { ToastifyMessage } from 'components';
import {
  redirectPageURL,
  agencyRedirectPageURL,
  clientRedirectPageURL,
  recruiterRedirectPageURL,
  ambassadorRedirectPageURL,
  triggerAnalyticsEvent,
  storeUserProfileId,
} from 'containers/App/utils';
import {
  toastMessages,
  API_URL,
  VERSION2,
  RECRUITER,
  AMBASSADOR,
  AUTH,
  CLIENT,
  SIGN_UP_API,
  AGENCY,
  TALENT,
  TALENT_PARTNER,
} from 'containers/App/constants';
import { makeSelectEmail, makeSelectPassword, makeSelectReferralVal } from './selectors';

import { SIGN_UP } from './constants';
/**
 * user Forget request/response handler
 */
export function* getSignup(data) {
  const { agencyTalent, talentToken, teamId } = data;

  let email = yield select(makeSelectEmail());
  email = email ? email.replace(/ /g, '') : '';
  const password = yield select(makeSelectPassword());
  const referral = yield select(makeSelectReferralVal());

  const apiCallData = {
    method: 'POST',
    body: {
      email,
      password,
    },
  };

  let requestURL = '';
  let selectedRoleType = '';
  let widgetSourceType = '3';

  const signupType = window.location.href.split('/').pop().split('?')[0];

  if (signupType === 'signup-widget' || signupType === 'client-widget') {
    const query = new URLSearchParams(window.location.search);
    selectedRoleType = query.get('selectedRoleType');
    widgetSourceType = query.get('sourceType');
  } else {
    selectedRoleType = StorageService.get('selectedRoleType');
  }

  if (referral) {
    apiCallData.body.referral = referral;
  }
  if (selectedRoleType === 'client' || selectedRoleType === 'company') {
    requestURL =
      signupType === 'client-widget'
        ? `${API_URL}${AUTH}${CLIENT}${SIGN_UP_API}?sourceType=${widgetSourceType}`
        : `${API_URL}${AUTH}${CLIENT}${SIGN_UP_API}`;
  }
  if (selectedRoleType === 'agency') {
    requestURL = `${API_URL}${AUTH}${AGENCY}${SIGN_UP_API}`;
  }
  if (selectedRoleType === 'talent') {
    requestURL = `${API_URL}${VERSION2}${AUTH}${TALENT}${SIGN_UP_API}`;
  }
  if (selectedRoleType === 'recruiter') {
    requestURL = `${API_URL}${VERSION2}${AUTH}${RECRUITER}${SIGN_UP_API}`;
  }
  if (selectedRoleType === 'ambassador') {
    requestURL = `${API_URL}${AUTH}${AMBASSADOR}${SIGN_UP_API}`;
  }
  if (agencyTalent) {
    apiCallData.body.token = talentToken;
    requestURL = `${API_URL}${AUTH}${AGENCY}${TALENT}${SIGN_UP_API}`;
  }
  if (teamId) {
    apiCallData.body.teamId = teamId;
  }

  const location = yield select(makeSelectLocation());
  function redirectRespectivePage(userType, log, registerType, pathname) {
    if (userType === 1) {
      storeApiSignupStep(get(log, 'data.signupStep'));
      const clientOnboarded = get(log, 'data.clientOnboarded', false);
      const availability = get(log, 'data.availability', false);
      let signupStep = get(log, 'data.signupStep') === 0.1 ? 1 : get(log, 'data.signupStep', 0) + 1;
      if (registerType === 'agency' || (registerType === 'freelancer' && clientOnboarded && !availability)) {
        signupStep = signupStep === 8 ? 9 : signupStep;
        StorageService.set('signupStep', signupStep, { hash: true });
        pathname = redirectPageURL(signupStep);
      } else {
        storeUserProfileId(get(log, 'data.profileId'), get(log, 'data.userId'));
        pathname = `${TALENT}/${log?.data?.profileId}`;
      }
    } else if (userType === 3) {
      const signupStep = get(log, 'data.signupStep') === 0.1 ? 1 : get(log, 'data.signupStep', 0) + 1;
      StorageService.set('signupStep', signupStep, { hash: true });
      StorageService.set('agencyLogo', get(log, 'data.trading.logo', ''), { hash: true });
      pathname = agencyRedirectPageURL(signupStep);
    } else if (userType === 2) {
      if (get(log, 'data.phoneOtp') === 0) {
        StorageService.set('signupStep', 1, { hash: true });
        pathname = '/client/enter-phone';
      } else {
        const currentSignupStep = has(log, 'data.signupStep') === true ? get(log, 'data.signupStep') + 1 : 1;
        const signupStep = currentSignupStep >= 3 ? 3 : currentSignupStep;
        StorageService.set('signupStep', signupStep, { hash: true });
        pathname = clientRedirectPageURL(signupStep);
      }
    } else if (userType === 5) {
      const signupStep = get(log, 'data.signupStep') === 0.1 ? 1 : get(log, 'data.signupStep', 0) + 1;
      StorageService.set('signupStep', signupStep, { hash: true });
      pathname = recruiterRedirectPageURL(signupStep);
    } else if (userType === 6) {
      const signupStep = get(log, 'data.signupStep') === 0.1 ? 1 : get(log, 'data.signupStep', 0) + 1;
      StorageService.set('signupStep', signupStep, { hash: true });
      pathname = ambassadorRedirectPageURL(signupStep);
    } else if (userType === 4) {
      pathname = '/admin/';
    }
    return pathname;
  }

  // return;
  triggerAnalyticsEvent({
    labelData: '',
    userType: selectedRoleType,
    actionName: 'Signup',
    actionUser: '',
    category: `${selectedRoleType} Portal`,
    email,
  });
  try {
    StorageService.set('userEmail', get(apiCallData, 'body.email'), { hash: true });
    const log = yield call(request, requestURL, apiCallData);

    if (get(log, 'status')) {
      // GTM

      StorageService.set('userVersion', get(log, 'data.version'), { hash: true });

      let pathname = '';
      if (agencyTalent) {
        const userType = 1;
        const registerType = get(log, 'data.registerType');
        AuthTokenService.storeToken(get(log, 'data.token'));
        StorageService.set('signupStep', 1, { hash: true });
        StorageService.set('userType', userType, { hash: true });
        StorageService.set('registerType', registerType, { hash: true });
        Emitter.emit('userTypeSelected', true);
        pathname = '/talent/about-you';
      } else if (get(log, 'data.token')) {
        const userType = get(log, 'data.role', '');
        const registerType = get(log, 'data.registerType');
        AuthTokenService.storeToken(get(log, 'data.token'));
        StorageService.set('userType', userType, { hash: true });
        StorageService.set('registerType', registerType, { hash: true });
        StorageService.set('profilePicture', get(log, 'data.profilePicture'), { hash: true });
        StorageService.set('clientOnboarded', get(log, 'data.clientOnboarded'), { hash: true });
        StorageService.set('availability', get(log, 'data.availability'), { hash: true });

        pathname = redirectRespectivePage(userType, log, registerType, pathname);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (selectedRoleType === 'client' || selectedRoleType === 'company') {
          pathname =
            signupType === 'signup-widget' || signupType === 'client-widget'
              ? `${window.location.origin}${CLIENT}/verification`
              : `${CLIENT}/verification`;
        } else if (selectedRoleType === 'talent') {
          pathname = signupType === 'signup-widget' ? `${window.location.origin}${TALENT}/verification` : `${TALENT}/verification`;
        } else if (selectedRoleType === 'agency') {
          pathname = signupType === 'signup-widget' ? `${window.location.origin}${AGENCY}/verification` : `${AGENCY}/verification`;
        } else if (selectedRoleType === 'recruiter') {
          pathname =
            signupType === 'signup-widget' ? `${window.location.origin}${TALENT_PARTNER}/verification` : `${TALENT_PARTNER}/verification`;
        } else if (selectedRoleType === 'ambassador') {
          pathname = signupType === 'signup-widget' ? `${window.location.origin}${AMBASSADOR}/verification` : `${AMBASSADOR}/verification`;
        }
      }

      yield put(reset());
      if (signupType === 'signup-widget' || signupType === 'client-widget') {
        window.open(`${pathname}?userEmail=${apiCallData.body.email}`);
      } else {
        yield put(push({ pathname, search: location.search }));
      }
    } else {
      toast.error(<ToastifyMessage message={get(log, 'message')} type="error" />, { className: 'Toast-error' });
      yield put(repoLoadingError(log.message));
    }
  } catch (err) {
    toast.error(<ToastifyMessage message={toastMessages.errorMSG} type="error" />, { className: 'Toast-error' });
    yield put(repoLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* signup() {
  yield takeLatest(SIGN_UP, getSignup);
}
