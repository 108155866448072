import { AGENCY, TALENT, userIcon, settingIcon } from 'containers/App/constants';

export const talentNavPages = [
  {
    title: 'My profile',
    pathName: `${TALENT}`,
    icon: userIcon,
    key: 'my_profile',
    paths: [`${TALENT}`],
  },
  {
    title: 'Account settings',
    pathName: `${TALENT}/account-settings`,
    icon: settingIcon,
    key: 'account_setting',
    paths: [`${TALENT}/account-settings`],
  },
];

export const agencyNavPages = [
  {
    title: 'My profile',
    pathName: `${AGENCY}/about-you`,
    icon: userIcon,
    paths: [`${AGENCY}/about-you`],
    key: 'agency_my_profile',
  },
  {
    title: 'Account settings',
    pathName: `${AGENCY}/agency-account-settings`,
    icon: settingIcon,
    paths: [`${AGENCY}/agency-account-settings`],
    key: 'agency_account_setting',
  },
];

export const clientOnboardPage = ['about-you', 'about-company', 'company-location'];
