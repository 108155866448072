import styled from 'styled-components';
import { primaryNew } from 'themes/variables';
import media from 'themes/media';

export const SortDiv = styled.div`
  .sort-dropdown {
    justify-content: end;
    .selected-sort {
      color: rgb(${primaryNew}) !important;
      &.small-text {
        ${media.medium`
          font-size: 16px !important;
        `};
        font-size: 13px !important;
      }

      &.dense {
        font-size: 14px !important;

        svg {
          margin-right: 0px !important;
        }
      }
    }
  }
  svg.down-arrow {
    margin-left: 6px;
    margin-right: 0px;
    fill: none !important;
    path {
      stroke: rgb(${primaryNew});
    }
  }
`;
