import produce from 'immer';

// The initial state of the App
export const initialState = {
  linkedInLogin: {},
};

const reducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'LINKEDIN_LOGIN':
        draft.linkedInLogin = action.payload;
        break;
      default:
        break;
    }
    return draft;
  });

export default reducer;
