/**
 * Dashboard selectors
 */

import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { initialState } from './reducer';

const homepageInviteForm = (state) => state.homepageInviteForm || initialState;

const makeSelectInviteMails = () => createSelector(homepageInviteForm, (formState) => formState.inviteMails);
const makeSelectReferEarnType = () => createSelector(homepageInviteForm, (formState) => formState.referEarnType);
const makeSelectReloadTable = () => createSelector(homepageInviteForm, (formState) => formState.isReloadTable);

const formSelector = formValueSelector('homepageInviteForm');
const firstName = (state) => formSelector(state, 'firstName');
const lastName = (state) => formSelector(state, 'lastName');
const email = (state) => formSelector(state, 'email');
const currency = (state) => formSelector(state, 'currency');
const rate = (state) => formSelector(state, 'rate');
const role = (state) => formSelector(state, 'role');
const companyDetails = (state) => formSelector(state, 'companyDetails');

export {
  homepageInviteForm,
  makeSelectInviteMails,
  firstName,
  lastName,
  email,
  currency,
  rate,
  makeSelectReferEarnType,
  role,
  companyDetails,
  makeSelectReloadTable,
};
