import React from 'react';
import get from 'lodash/get';
import { userProfileIcon } from 'containers/App/constants';
import { defaultProps, propTypes } from 'containers/proptypes';
import { TalentNameBtn, TalentName } from './styles';

export const imgError = (e) => {
  e.target.onerror = null;
  e.target.src = userProfileIcon;
};

export const TalentNameButton = (props) => {
  const { redirectTo, profileId, talentName, buttonClasses = '', underline = false } = props;
  const extra = get(props, 'extra', {});
  extra.backLinkVisible = false;
  const profilePicture = get(props, 'profilePicture', '');
  const disabled = get(props, 'disabled', false);

  return disabled ? (
    <TalentName>
      <img src={`${profilePicture}?_t=${new Date().getTime()}`} alt="user profile" onError={imgError} />
      <span className="ms-2">{talentName}</span>
    </TalentName>
  ) : (
    <TalentNameBtn
      type="button"
      className={`btn-link ${buttonClasses}`}
      to={{
        pathname: `${redirectTo}${profileId}`,
        query: { ...extra },
      }}
      target="_blank"
    >
      <img src={`${profilePicture}?_t=${new Date().getTime()}`} alt="user profile" onError={imgError} />
      {!underline ? (
        <span className="ms-2">{talentName}</span>
      ) : (
        <span className="ms-2">
          <u>{talentName}</u>
        </span>
      )}
    </TalentNameBtn>
  );
};

TalentNameButton.defaultProps = defaultProps;
TalentNameButton.propTypes = propTypes;

export default TalentNameButton;
