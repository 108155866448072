import React from 'react';
import { brandingDarkIcon } from 'containers/App/constants';
import { NavBrand } from './brand-style';

const Logo = () => (
  <NavBrand to="/" title="Notchup" className="cm-logo">
    <img src={brandingDarkIcon} alt="Notchup" />
  </NavBrand>
);

export default Logo;
