/*
 * TalentCardDescription Component
 */

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { P, Button, H3, Badge, SVGIcon, UnControlledTooltip } from 'components';
import { getTimezoneOffest } from 'containers/MyProfilePage/components/utils';
import containerMessage from 'containers/messages';
import { CLIENT, addedToCompareIcon, circleTickIcon, compareTalentIcon, plusIcon, starTickIcon, timeXZone } from 'containers/App/constants';
import ToolTip from 'components/ToolTip';
import profileContainerMessages from 'containers/MyProfilePage/messages';
import { Cell, PieChart, Pie } from 'recharts';
import { COLORS } from 'containers/Admin/Talents/constants';
import { getBadgeClass, redirectTo } from 'containers/App/utils';
import { LoaderLines } from 'components/SkeletonLoader';
import { primaryNew } from 'themes/variables';
import { getUserRegisterType } from 'utils/Helper';
import { useDispatch, useSelector } from 'react-redux';
import { updateTalentComparison } from 'containers/CompareTalentSticky/reducer';
import { colorMapper, getColorVariant, getScoreBreakdownContent } from 'containers/TalentListingPage/utils';

function TalentCardDescription(props) {
  const { data, showInterviewTalent, loading, isSubscribed, SubscriptionModalRef, hireButtonText, setSaveToAList } = props;
  const dispatch = useDispatch();
  const compareTalentsList = useSelector((state) => state.compareTalents?.compareTalents);

  const parsedMatchValue = parseFloat(get(data, 'match', '0%')?.slice(0, -1));
  const matchData = [
    {
      name: 'Filled',
      value: parsedMatchValue,
    },
    {
      name: 'Not-Filled',
      value: 100 - parsedMatchValue,
    },
  ];

  // eslint-disable-next-line no-nested-ternary
  const variant = getColorVariant(parsedMatchValue);
  const percentageFillColor = variant in colorMapper ? `rgb(${colorMapper[variant]})` : `rgba(${primaryNew}, 0.2)`;
  const badgeActiveColor = parsedMatchValue < 40 ? 'Rejected' : parsedMatchValue < 80 ? 'Registered' : 'Active';

  const registerType = getUserRegisterType();

  const handleCompareTalentClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(updateTalentComparison(data));
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row align-items-start justify-content-between w-100 mt-2">
        <div className={`d-flex align-items-start ${get(data, 'match', '') ? 'col-md-6 col-lg-8' : ''}`}>
          <div>
            <div className="d-flex align-items-center">
              {isSubscribed ? (
                <H3>
                  {get(data, 'firstName')} {get(data, 'lastName')}.
                </H3>
              ) : (
                <LoaderLines className="m-0 p-0" count={1} height={25} width={150} />
              )}

              {get(data, 'verifiedProfile', false) && (
                <ToolTip
                  titleClass="lh-1"
                  wrapperClass="d-inline-flex m-0 p-0 ms-2 align-text-top"
                  type="other"
                  otherIcon={starTickIcon}
                  placement="right"
                  content={
                    <ul className="m-0 ps-2">
                      <li className="text-start">{profileContainerMessages.interviewedForSkillsAndExpertise.defaultMessage}</li>
                      <li className="text-start">{profileContainerMessages.checkedForEducationAndExperiences.defaultMessage}</li>
                      <li className="text-start">{profileContainerMessages.verifiedPhotoIDandAddress.defaultMessage}</li>
                    </ul>
                  }
                  tooltipId={`tooltip${data._id}_talent_long_card`}
                />
              )}
            </div>
            <P className="p14 mb-0 mt-1" opacityVal="0.5">
              {get(data, 'city')}, {get(data, 'country')} ({getTimezoneOffest(timeXZone, 'name', get(data, 'timeZone'))})
            </P>
          </div>

          {get(data, 'match', '') && (
            <div className="ms-2">
              <Badge
                className={`${getBadgeClass(badgeActiveColor)} mb-0 ms-md-2 py-0 px-2`}
                id={`TooltipTalentCardComponentMatch_${data._id}`}
              >
                <div className="d-flex align-items-center">
                  <PieChart width={30} height={30}>
                    <Pie data={matchData} cx={10} cy={10} innerRadius={5} outerRadius={10} fill="#8884d8" paddingAngle={0}>
                      {matchData.map((entry, index) => {
                        const fill = entry.name === 'Filled' ? percentageFillColor : `rgba(${primaryNew}, 0.2)`;
                        return <Cell fill={fill} key={index} />;
                      })}
                    </Pie>
                  </PieChart>
                  <P
                    className="p16 m-0 p-0"
                    style={{
                      color: percentageFillColor,
                    }}
                  >
                    {get(data, 'match', '')}
                  </P>
                  <P className="p16 m-0 p-0 ms-2">Profile match</P>
                </div>
              </Badge>
              {data.scoreBreakdown && (
                <UnControlledTooltip
                  targetId={`TooltipTalentCardComponentMatch_${data._id}`}
                  content={getScoreBreakdownContent(data.scoreBreakdown)}
                  placement="top"
                  className="tooltip-white-bg"
                />
              )}
            </div>
          )}
        </div>

        <div className="mt-2 mt-md-0 d-flex align-items-center">
          {showInterviewTalent && (
            <Button
              disabled={loading}
              className="btn btn-sm btn-outline d-none d-md-inline me-2"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                if (!isSubscribed) {
                  return SubscriptionModalRef.current.handleSubscriptionPlanOpenModal();
                }
                redirectTo(null, `${CLIENT}/schedule-interview`, {
                  data: {
                    ...data,
                    talentId: get(data, 'talentId', get(data, '_id', get(data, 'talentUserId', ''))),
                  },
                });
              }}
            >
              <span>{hireButtonText}</span>
            </Button>
          )}

          {registerType === 'client' && (
            <Button className="btn-link d-flex align-items-center" onClick={handleCompareTalentClick}>
              <ToolTip
                tooltipId={`addToCompare_${data.profileId}`}
                content={compareTalentsList[data.talentUserId] ? 'Added to compare' : 'Compare'}
                otherIcon={compareTalentsList[data.talentUserId] ? addedToCompareIcon : compareTalentIcon}
                type={'other'}
                placement={'top'}
              />
            </Button>
          )}

          {registerType === 'client' && (
            <>
              <Button
                className="btn-link align-items-center ms-3 d-none d-md-flex"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSaveToAList(true);
                }}
              >
                <SVGIcon
                  src={data?.isAddedToList ? circleTickIcon : plusIcon}
                  className="me-1"
                  width={10}
                  height={10}
                  {...(!data?.isAddedToList && { iconColor: `rgb(${primaryNew})` })}
                />
                <P className={`p14 ${data?.isAddedToList ? 'text-success' : 'text-primary'} m-0 p-0`} lineHeight="16.03">
                  {data?.isAddedToList ? 'Added to a list' : 'Save to a list'}
                </P>
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

TalentCardDescription.defaultProps = {
  data: {},
  showInterviewTalent: true,
  loading: false,
};

TalentCardDescription.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  showInterviewTalent: PropTypes.bool,
};

export default TalentCardDescription;
