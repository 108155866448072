/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';

import { CHANGE_LOCALE } from './constants';

export const initialState = {
  locale: 'en',
};

const languageProviderReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    if (action.type === CHANGE_LOCALE) {
      draft.locale = action.locale;
    }
  });

export default languageProviderReducer;
