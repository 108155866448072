/*
 * AdminCompanies Constants
 */

export const key = 'AdminCompanies';
export const DEFAULT_PAGE_NO = 1;

export const CHANGE_COMPANY_STATUS = 'Notchup/AdminCompanies/CHANGE_STATUS';

export const sortFilterCompanyArray = [
  { label: 'All', value: '' },
  { label: 'Active', value: '1' },
  { label: 'Invited', value: '0' },
  { label: 'Suspended', value: '2' },
];

export const columns = [
  {
    name: 'SL No',
    selector: 'number',
    maxWidth: '100px',
    style: {
      paddingLeft: '40px',
    },
  },
  {
    name: 'Company name',
    selector: 'companyName',
    width: '14%',
  },
  {
    name: 'Client',
    selector: 'name',
  },
  {
    name: 'Email',
    selector: 'email',
    minWidth: '150px',
  },
  {
    name: 'Subscription',
    selector: 'subscription',
  },
  {
    name: 'Invite users',
    selector: 'inviteUsers',
    maxWidth: '150px',
  },
  {
    name: 'Status',
    selector: 'status',
    maxWidth: '120px',
  },
  {
    name: 'Action',
    selector: 'action',
    allowOverflow: true,
    style: {
      paddingRight: '40px',
    },
  },
];
