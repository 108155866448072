import React from 'react';
import { compose } from 'redux';
import { logout, gtm, getUserRegisterType } from 'utils/Helper';
import { defaultProps, propTypes } from 'containers/proptypes';

import { connect } from 'react-redux';
import { userLogout } from 'containers/App/actions';
import { withRouter } from 'react-router-dom';

function Logout(props) {
  const { children } = props;

  const callLogout = () => {
    // GTM
    gtm({ action: 'Button Click', label: 'user_logout', category: `${getUserRegisterType()} Portal`, directGA: true });

    const { dispatch, onClick } = props;
    dispatch(userLogout());
    logout();
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span onKeyPress={() => callLogout()} onClick={() => callLogout()}>
      {children}
    </span>
  );
}

Logout.defaultProps = defaultProps;
Logout.propTypes = propTypes;

export default compose(connect())(withRouter(Logout));
