import styled from 'styled-components';
import media from 'themes/media';
import { white } from 'themes/variables';

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  .with-sidebar & {
    padding: 30px;
  }

  ${media.medium`
		padding: 30px 0;
	`};

  .client-block {
    .talent-card {
      ${media.large`
		  width: 33.33% !important;
  	`};
      ${media.medium`
		  width: 33.33%;
  	`};
    }
    .card-md {
      ${media.large`
		  width: 66.66% !important;
  	`};
      ${media.medium`
		  width: 66.66%;
  	`};
    }
    .title-text {
      align-items: center;
      display: flex;
      h3 {
        color: rgb(${white});
      }
      hr {
        vertical-align: middle;
        height: 30px;
        color: rgb(${white});
        margin: 0;
        opacity: 0.3;
        border: 1px solid !important;
      }
      .h6 {
        color: rgb(${white});
      }
      .link {
        min-width: 0;
        background: transparent;
        padding: 0;
        cursor: pointer;
        ${media.small`
        float:right;
	  `};
      }
      .text-sm {
        color: rgb(${white});
        opacity: 0.5;
      }
      .p16 {
        color: rgb(${white});
      }
      ${media.medium`
      display: flex !important;
	  `};
    }
  }
`;

export default Content;
