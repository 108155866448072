import styled from 'styled-components';
import { white, primaryDarkNew, primaryNew, black } from 'themes/variables';
import { Dropdown } from 'reactstrap';

export const HrLine = styled.div`
  border-right: 1px solid rgba(${primaryNew}, 0.2);
  height: 22px;
`;

export const SortDropdown = styled(Dropdown)`
  margin-right: 30px;
  display: flex;
  align-items: start;
  &.show {
    button.btn-secondary {
      transition: 0s all;
      color: rgb(${primaryDarkNew});
      &:hover,
      &:focus,
      &:not(:disabled):not(:disabled):active {
        box-shadow: none;
        color: rgb(${primaryDarkNew});
        background: none;
      }
      svg {
        fill: rgb(${primaryDarkNew});
        g {
          fill: rgb(${primaryDarkNew});
        }
      }
    }
  }
  button.btn-secondary {
    margin-left: 10px;
    background: rgb(${white});
    font-family: 'GT-Walsheim-Pro-Regular';
    color: rgba(${primaryDarkNew});
    font-size: 14px;
    display: flex;
    align-items: center;
    min-width: auto;
    line-height: 16px;
    height: 36px;
    border: 1px solid rgba(${primaryNew}, 0.1);
    padding: 10px 15px;
    border-radius: 10px;

    &:hover,
    &:active {
      border-color: rgb(${primaryNew}, 0.5);
      outline: 2px solid rgba(${primaryNew}, 0.2);
      svg {
        path {
          stroke: rgb(${primaryNew});
        }
      }
    }

    &:active {
      background: rgb(${white});
    }
    svg {
      height: 12px;
      width: 12px;
      margin-right: 10px;

      path {
        stroke: rgb(${primaryDarkNew});
      }
    }
    .count {
      margin-left: 5px;
      color: rgb(${white});
      font-size: 12px;
      height: 20px;
      width: 20px;
      line-height: 20px;
      border-radius: 100%;
      background-color: rgb(${primaryNew});
    }
  }

  .dropdown-menu {
    margin-top: 10px;
    border-radius: 3px;
    padding: 13px 33px;
    min-width: 201px;
    border: 0;
    box-shadow: 0 2px 14px rgba(${black}, 0.07);
    & .dropdown-item {
      font-size: 16px;
      letter-spacing: 0.25px;
      color: rgba(${primaryDarkNew}, 0.5);
      padding: 7px 0;

      &:hover,
      &:focus {
        background-color: transparent;
      }
      &:focus {
        outline: none;
      }

      &.active {
        background: none;
        color: rgb(${primaryDarkNew});
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 13px;
          left: -15px;
          width: 5px;
          height: 8px;
          border: solid rgb(${primaryDarkNew});
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
`;

export const ClientNameWithProfile = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  img {
    width: 34px;
    height: 34px;
    border-radius: 100%;
  }
  span {
    text-transform: capitalize;
  }
`;
