/*
 * Dashboard
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import request from 'utils/request';
import { VALIDATION } from 'utils/constants';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { getUserRegisterType } from 'utils/Helper';
import { API_URL, USER, DETAILS, TALENT, currencyData } from 'containers/App/constants';
import { storeApiSignupStep } from 'containers/Auth/utils';
import { storeUserProfileId } from 'containers/App/utils';
import { ToastifyMessage, P } from 'components';
import { propTypes } from './proptypes';
import Wallet from './Wallet';
import moment from 'moment';
import { getCurrencySymbol } from 'containers/MyProfilePage/components/utils';
import StorageService from 'utils/StorageService';
import { history } from 'store/configureStore';

export class TalentWallet extends React.Component {
  constructor(props) {
    super(props);

    const apiSignupStep = Number(StorageService.get('apiSignupStep'));
    const registerType = StorageService.get('registerType');

    if (registerType !== 'freelancer' || apiSignupStep !== 7) {
      history.goBack();
    }

    this.state = {
      userDetails: {},
      walletBalance: {},
      transactionsList: [],
      roleType: getUserRegisterType(),
    };
  }

  componentDidMount() {
    this.callFetchAPI();
    this.getWalletBalance();
    this.loadWalletTransactions();
  }

  callFetchAPI = () => {
    const data = {
      method: 'GET',
    };
    const requestURL = `${API_URL}${USER}${DETAILS}`;
    request(requestURL, data)
      .then((response) => {
        storeApiSignupStep(get(response, 'data.signupStep'));
        const profileId = get(response, 'data.profileId');
        const userId = get(response, 'data.userId');
        storeUserProfileId(profileId, userId);
        this.setState({ userDetails: response });
      })
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  getWalletBalance = () => {
    const data = {
      method: 'GET',
    };
    const requestURL = `${API_URL}${TALENT}/wallet/balance`;

    request(requestURL, data)
      .then((response) => {
        if (get(response, 'status')) {
          this.setState({ walletBalance: get(response, 'data', {}) });
        }
      })
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  loadWalletTransactions = (pageNum) => {
    this.setState({ isListLoading: true });
    const data = { method: 'GET' };
    const requestURL = `${API_URL}${TALENT}/wallet/transactions?sort=-createdAt&limit=5`;

    request(requestURL, data)
      .then(this.setTransactions)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  setTransactions = (response) => {
    if (get(response, 'status')) {
      const data = Object.assign({}, response.data);

      const array = [];
      const temp = response.data.page * response.data.limit - (response.data.limit - 1);
      data.docs.forEach((transaction, index) => {
        const currencySymbol = getCurrencySymbol(currencyData, 'code', get(transaction, 'currency'));
        const id = get(transaction, '_id');

        const deposit = get(transaction, 'type') === 'CREDIT' ? currencySymbol + get(transaction, 'amount') : '-';
        const withdrawal = get(transaction, 'type') === 'WITHDRAWN' ? currencySymbol + get(transaction, 'amount') : '-';
        const balance = currencySymbol + get(transaction, 'balance');

        const details = (
          <P className="p16 ms-3 m-0 p-0" opacityVal="0.5">
            {get(transaction, 'description')}
          </P>
        );
        const date = (
          <P className="p16 ms-3 m-0 p-0" opacityVal="0.5">
            {moment(new Date(get(transaction, 'createdAt', ''))).format('DD/MM/YYYY')}
          </P>
        );

        array.push({
          id,
          number: temp + index,
          date,
          details,
          deposit,
          withdrawal,
          balance,
        });
      });
      this.setState({ transactionsList: array, isListLoading: false });
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
    }
  };

  render() {
    const { userDetails, roleType, walletBalance, transactionsList, isListLoading } = this.state;

    return (
      <React.Fragment>
        {roleType !== 'agency' && !isEmpty(userDetails) && (
          <Wallet
            {...this.props}
            userData={userDetails}
            walletBalance={walletBalance}
            transactionsList={transactionsList}
            isListLoading={isListLoading}
          />
        )}
      </React.Fragment>
    );
  }
}

TalentWallet.propTypes = propTypes;

export default TalentWallet;
