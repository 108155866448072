/**
 *
 * UnControlledTooltip
 *
 */

import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';

function UnControlledTooltip({ targetId, content, placement, bgColor, ...props }) {
  return (
    <UncontrolledTooltip
      placement={placement}
      target={targetId}
      arrowClassName={bgColor}
      innerClassName={bgColor}
      className="opacity-100"
      delay={0}
      {...props}
    >
      {content}
    </UncontrolledTooltip>
  );
}

UnControlledTooltip.defaultProps = {
  targetId: '',
  content: '',
  placement: 'top',
  bgColor: 'bg-danger',
};

UnControlledTooltip.propTypes = {
  targetId: PropTypes.string,
  content: PropTypes.string,
  placement: PropTypes.string,
  bgColor: PropTypes.string,
};

export default UnControlledTooltip;
