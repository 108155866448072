/* eslint-disable react/no-unused-state */
/**
 * SubscriptionModal
 * This is the onboarding page for the client user, at the '/about-you' route
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import StorageService from 'utils/StorageService';
import get from 'lodash/get';
import find from 'lodash/find';
import { signupLink } from 'containers/App/utils';
import request from 'utils/request';
import { P, H2, Button, A, SVGIcon, H6, H3, H5, H1 } from 'components';
import { CardSkeleton } from 'components/SkeletonLoader';
import ModalWrapper from 'components/ModalWrapper';
import {
  currencyData,
  BUILD_TEAM_FORM_URL,
  PRICING_URL,
  rightAngleIcon,
  API_URL,
  CLIENT,
  LIST_PRICES,
  SUBSCRIPTION,
  SUBSCRIBE,
  arrowRightIcon,
  LIST_PLANS,
  CONTACT_US_FORM_URL,
} from 'containers/App/constants';
import { getCurrencySymbol } from 'containers/MyProfilePage/components/utils';
import { VALIDATION } from 'utils/constants';
import ToastifyMessage from 'components/ToastifyMessage';
import { primaryNew } from 'themes/variables';
import messages from './messages';
import { PlanCard } from './styles';

export class SubscriptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubscriptionPlanModal: false,
      subscriptionPrices: [],
      subscriptionLoading: false,
      planData: [],
      isPlanDataLoading: false,
      oneTimeAmount: 0,
    };
    this.subscriptionPlanModal = this.subscriptionPlanModal.bind(this);
  }

  /**
   * call on open handleSubscriptionPlanOpenModal popup
   * @author Innovify
   */
  handleSubscriptionPlanOpenModal = () => {
    this.setState({ showSubscriptionPlanModal: true }, () => {
      this.loadSubscriptionDetails();
    });
  };

  /** call on open handleSubscriptionPlanCloseModal popup
   * @author Innovify
   */
  handleSubscriptionPlanCloseModal = () => {
    this.setState({ showSubscriptionPlanModal: false });
  };

  loadSubscriptionDetails = () => {
    const data = { method: 'GET' };
    this.setState({ isPlanDataLoading: true });
    const requestURLNew = `${API_URL}${CLIENT}${SUBSCRIPTION}${LIST_PLANS}`;
    request(requestURLNew, data)
      .then((response) => {
        if (get(response, 'status')) {
          this.setState({ planData: response.data, isPlanDataLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isPlanDataLoading: false });
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });

    const requestURL = `${API_URL}${CLIENT}${SUBSCRIPTION}${LIST_PRICES}`;
    request(requestURL, data)
      .then(this.setSubscriptionDetails)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  setSubscriptionDetails = (response) => {
    const { history } = this.props;
    if (get(response, 'status')) {
      const subscriptionPlanDetails = StorageService.get('subscriptionPlanDetails');
      const PlanDetailsObj = subscriptionPlanDetails === 'undefined' ? {} : JSON.parse(subscriptionPlanDetails);
      const oneTimeAmount = response.data.length > 0 && response.data.find((a) => a.amount === PlanDetailsObj?.extraJobFee).amount;
      this.setState({ subscriptionPrices: response.data, oneTimeAmount });
    } else {
      history?.push(signupLink);
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
    }
  };

  handleSubscribePlan = (priceId, planType) => {
    const { history } = this.props;
    if (planType === 0) {
      this.setState({ subscriptionLoading: true });
    } else {
      this.setState({ subscriptionTwoLoading: true });
    }
    const currentHost = window.location.host;
    const protocolType = window.location.protocol;
    const successURL = `${protocolType}//${currentHost}${history?.location?.pathname}?success=true`;
    const cancelURL = `${protocolType}//${currentHost}${history?.location?.pathname}?success=false`;

    const body = { priceId, successURL, cancelURL };
    const data = { method: 'POST', body };
    const requestURL = `${API_URL}${CLIENT}${SUBSCRIPTION}${SUBSCRIBE}`;

    request(requestURL, data)
      .then((response) => {
        if (get(response, 'status')) {
          if (planType === 0) {
            this.setState({ subscriptionLoading: false });
          } else {
            this.setState({ subscriptionTwoLoading: false });
          }
          window.location.replace(response?.data);
        } else {
          if (planType === 0) {
            this.setState({ subscriptionLoading: false });
          } else {
            this.setState({ subscriptionTwoLoading: false });
          }
          toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
        }
      })
      .catch(() => {
        if (planType === 0) {
          this.setState({ subscriptionLoading: false });
        } else {
          this.setState({ subscriptionTwoLoading: false });
        }
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  render() {
    const { invalid, loading, responseSuccess, responseError } = this.props;
    return <>{this.subscriptionPlanModal(loading, responseSuccess, responseError, invalid)}</>;
  }

  subscriptionPlanModal(loading, responseSuccess, responseError, invalid) {
    const { isNormalUpgrade, isTalentUpgrade } = this.props;
    const { showSubscriptionPlanModal, subscriptionPrices, subscriptionTwoLoading, planData, isPlanDataLoading, oneTimeAmount } =
      this.state;
    const subscriptionPlanType = StorageService.get('subscriptionPlan');
    // For single job-post calculation
    const oneTimeCurrency = subscriptionPrices.length > 0 && subscriptionPrices.find((a) => a.type === 'one_time')?.currency;
    const oneTimeCurrencySign = getCurrencySymbol(currencyData, 'code', oneTimeCurrency?.toString().toUpperCase());

    // Calculate the discount percentage
    const getCurrencySymbolFromPlan = (planObj) => {
      const currentCurrency = getCurrencySymbol(currencyData, 'code', get(planObj, 'currency', '').toString().toUpperCase());
      return currentCurrency;
    };

    const renderPlanButtons = (type, index, activePlanId = '') => {
      // eslint-disable-next-line no-shadow
      const { planData } = this.state;
      // eslint-disable-next-line no-shadow
      const findCurrentPrice = planData.find((ele) => ele.id === activePlanId);
      // eslint-disable-next-line no-shadow
      const activePriceId = find(findCurrentPrice.prices, { is_default: true })?.id;
      let output = null;
      const subscriptionPlanType = StorageService.get('subscriptionPlan');
      output = (
        <Button
          type="button"
          className={`${subscriptionTwoLoading ? 'loading btn-primary' : ''} btn btn-sm btn-primary mt-2`}
          onClick={() => this.handleSubscribePlan(activePriceId, index)}
          disabled={subscriptionPlanType === 'Plus'}
        >
          {<FormattedMessage {...messages.btnSelectPlan} />}
        </Button>
      );
      return output;
    };

    const payNowAction = () => {
      StorageService.set('ONE_TIME_SUBSCRIPTION_PRICE', oneTimeAmount);
      this.handleSubscriptionPlanCloseModal();
      // eslint-disable-next-line no-shadow
      const { history } = this.props;
      const successURL = `${history?.location?.pathname}?success=true`;
      const cancelURL = `${history?.location?.pathname}?success=false`;
      history.push('/client/checkout', { successURL, cancelURL });
    };

    return (
      <ModalWrapper
        modalId="showSubscriptionPlanModal"
        loading={loading}
        responseSuccess={responseSuccess}
        responseError={responseError}
        disabled={invalid}
        // isOpen
        isOpen={showSubscriptionPlanModal}
        onDiscard={this.handleSubscriptionPlanCloseModal}
        primaryBtnText="Ok"
        title=""
        onHandleSubmit={this.handleSubscriptionPlanCloseModal}
        modalBodyClass="no-header-footer p-0"
        modalHeaderClass="d-none"
        modalContentClass={this.props.modalContentClass}
        modalClassName={this.props.modalClassName}
        noFooter
      >
        <H1 className="text-center mb-3">
          {isTalentUpgrade ? (
            `Supercharge your talent sourcing with our AI`
          ) : isNormalUpgrade ? (
            <FormattedMessage {...messages.normalUpgradeTitle} />
          ) : (
            <FormattedMessage {...messages.forceUpgradeTitle} />
          )}
        </H1>
        <div className="d-flex justify-content-center w-100">
          <P
            className="p16 text-center mb-3 mb-xxl-4 "
            style={{
              width: '80%',
            }}
          >
            {isTalentUpgrade ? (
              `Access global talent database, attract the best talents, host more competitions, and build multiple scrum teams anywhere with a click of a button.`
            ) : isNormalUpgrade ? (
              <FormattedMessage {...messages.normalUpgradeDescription} />
            ) : (
              <FormattedMessage {...messages.forceUpgradeDescription} />
            )}
          </P>
        </div>
        {!isNormalUpgrade && !isTalentUpgrade && (
          <>
            <hr className="mt-3 mb-3" />
            <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
              <H6>
                <FormattedMessage {...messages.additionalJobText} />
              </H6>
              <div className="d-flex align-items-center mt-3 mt-md-0">
                <div className="d-flex align-items-center">
                  <H3 className="text-primary">
                    {oneTimeCurrencySign}
                    {oneTimeAmount}
                  </H3>
                  <P className="p16 mb-0">/per job</P>
                </div>
                <Button type="button" className="ms-3 btn btn-outline btn-sm mt-auto" onClick={payNowAction}>
                  <FormattedMessage {...messages.payNowBtn} />
                  <SVGIcon src={arrowRightIcon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
                </Button>
              </div>
            </div>
            <hr className="mt-3 mb-4" />
          </>
        )}
        {!isNormalUpgrade && !isTalentUpgrade && (
          <div className="my-1 py-2">
            <>
              <H6 className="me-2">
                <FormattedMessage {...messages.upgradToSuitablePlan} />
              </H6>
            </>
          </div>
        )}
        {isPlanDataLoading ? (
          <div className="d-flex flex-column flex-md-row">
            {isNormalUpgrade ? (
              <>
                <CardSkeleton cardCount={1} />
                <CardSkeleton cardCount={1} />
                <CardSkeleton cardCount={1} />{' '}
              </>
            ) : (
              <>
                <CardSkeleton cardCount={1} />
                <CardSkeleton cardCount={1} />
              </>
            )}
          </div>
        ) : (
          <Row className="mt-4">
            {isNormalUpgrade && (
              <Col md="4">
                <PlanCard>
                  <div className="inner-block">
                    <H2>
                      <FormattedMessage {...messages.freePlan} />
                    </H2>
                    <P className="text-primary p16 mb-0" opacityVal="0.6">
                      $0/annum
                    </P>
                    <Button
                      type="button"
                      className={`${subscriptionTwoLoading ? 'loading btn-primary' : ''} btn btn-sm btn-outline mt-4`}
                      onClick={() => this.handleSubscriptionPlanCloseModal()}
                    >
                      <FormattedMessage {...messages.titleSelectPlan} />
                    </Button>
                    <P className="p14 mt-3 mb-0">
                      <FormattedMessage {...messages.starterPlanContent} />
                    </P>
                    <P className="p14 mb-0">
                      <FormattedMessage {...messages.starterPlanText} />
                    </P>
                  </div>
                  <div className="inner-block divider">
                    <H5>
                      <FormattedMessage {...messages.whatsIncludedText} />
                    </H5>
                    <P className="p16 mt-10 mb-10">
                      <FormattedMessage {...messages.starterPlanText1} />
                    </P>
                    <P className="p16 mb-10">
                      <FormattedMessage {...messages.starterPlanText2} />
                    </P>
                    {/* <P className="p16 mb-10">
                      <FormattedMessage {...messages.starterPlanText3} />
                    </P> */}
                    <P className="p16 mb-10">
                      <FormattedMessage {...messages.starterPlanText4} />
                    </P>
                    {/* <P className="p16 mb-10">
                      <FormattedMessage {...messages.starterPlanText5} />
                    </P> */}
                    <A href={PRICING_URL} target="_blank" className="mt-auto pt-3 mx-auto d-flex align-items-center">
                      <FormattedMessage {...messages.btnSeeFeatures} />
                      <SVGIcon src={rightAngleIcon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
                    </A>
                  </div>
                </PlanCard>
              </Col>
            )}
            {planData.length > 0 &&
              planData.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${i}plan`}>
                  <Col md={'4'}>
                    <PlanCard className="bg-info position-relative">
                      <div className="best-value position-absolute">
                        <P className="text-white p14 m-0 p-2 fst-italic">Best Value</P>
                      </div>
                      <div className="inner-block">
                        <H2>{item.name}</H2>
                        <div className="d-flex align-items-center justify-content-center">
                          <P className="text-primary p16 mb-0">
                            <>
                              {getCurrencySymbolFromPlan(find(item?.prices, { is_default: true }))}
                              {find(item?.prices, { is_default: true }).amount}/annum
                            </>
                          </P>
                        </div>
                        <div className="d-flex align-items-center justify-content-center my-1">
                          <P className="text-success p16 mb-0">(-20%)</P>
                        </div>
                        {renderPlanButtons(item.name, i, item.id)}
                        <P className="p14 mt-2 mb-0">
                          <FormattedMessage {...messages.growthPlanContent} />
                        </P>
                        <P className="p14 mb-0">
                          <FormattedMessage {...messages.growthPlanText} />
                        </P>
                      </div>
                      <div className="inner-block divider">
                        <H5>What’s included</H5>
                        <P className="p16 mt-10 mb-10">3 Teams / Projects</P>
                        <P className="p16 mb-10">15 Free Job Postings</P>
                        <P className="p16 mb-10">15 Hosted Competitions</P>
                        <A href={PRICING_URL} target="_blank" className="mt-auto pt-3 mx-auto d-flex align-items-center">
                          <FormattedMessage {...messages.btnSeeFeatures} />
                          <SVGIcon src={rightAngleIcon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
                        </A>
                      </div>
                    </PlanCard>
                  </Col>
                </React.Fragment>
              ))}
            <Col md={'4'}>
              <PlanCard>
                <div className="inner-block">
                  <H2>
                    <FormattedMessage {...messages.enterprisePlanTitle} />
                  </H2>
                  <P className="text-primary p16 mb-0" opacityVal="0.6">
                    <FormattedMessage {...messages.enterprisePlanSubContent} />
                  </P>
                  <Button
                    type="button"
                    className="btn btn-sm btn-outline mt-3"
                    onClick={() => window.open(CONTACT_US_FORM_URL, '_blank', 'noopener,noreferrer')}
                  >
                    <FormattedMessage {...messages.btnEnterprisePlan} />
                  </Button>
                  <P className="p14 mt-3 mb-0">Custom Solution</P>
                </div>
                <div className="inner-block divider">
                  <H5>What's included</H5>
                  <P className="p16 mt-10 mb-10">Unlimited Job Postings</P>
                  <P className="p16 mb-10">Unlimited Teams / Projects</P>
                  <P className="p16 mb-10">Unlimited Hosted Competitions</P>
                  <P className="p16 mb-10">Unlimited Talents / Teams Search</P>
                  <A href={PRICING_URL} target="_blank" className="mt-auto pt-2 mx-auto d-flex align-items-center">
                    <FormattedMessage {...messages.btnSeeFeatures} />
                    <SVGIcon src={rightAngleIcon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
                  </A>
                </div>
              </PlanCard>
            </Col>
          </Row>
        )}

        <div className="w-100 d-flex justify-content-end">
          <Button
            type="button"
            className="btn btn-link d-flex align-items-center"
            onClick={this.handleSubscriptionPlanCloseModal}
            style={{
              color: `rgba(91, 113, 145, 1)`,
            }}
          >
            <FormattedMessage {...messages.btnContinueFreePlan} />
            <SVGIcon src={rightAngleIcon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
          </Button>
        </div>

        <div className="w-100 d-flex justify-content-center mt-4">
          <Button type="button" className="btn btn-link btn-lg d-flex align-items-center" onClick={() => window.open(PRICING_URL)}>
            Want to see all feature side-by-side? Compare plans
            <SVGIcon src={rightAngleIcon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
          </Button>
        </div>
      </ModalWrapper>
    );
  }
}

SubscriptionModal.defaultProps = {
  history: {},
  invalid: true,
  loading: false,
  responseSuccess: false,
  responseError: false,
  successPath: '',
  cancelPath: '',
  isNormalUpgrade: false,
};

SubscriptionModal.propTypes = {
  history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  invalid: PropTypes.bool,
  loading: PropTypes.bool,
  responseSuccess: PropTypes.bool,
  responseError: PropTypes.bool,
  successPath: PropTypes.string,
  cancelPath: PropTypes.string,
  isNormalUpgrade: PropTypes.bool,
};

export default SubscriptionModal;
