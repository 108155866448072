import React from 'react';
import get from 'lodash/get';
import { defaultProps, propTypes } from 'containers/proptypes';
import Content from 'components/Content';
import { H1, PrivateGrid } from 'components';
import history from 'utils/history';
import { routes } from 'containers/App/constants';

export class PublicPageTitle extends React.Component {
  getFormattedPath = (pathName) => {
    let output = '';
    output = pathName.split('/', 3).join('/');
    return output;
  };

  getPageTitle = () => {
    let { title } = this.props;
    if (!title) {
      let output = '';
      const pathName = get(history.location, 'pathname');
      const formattedPath = this.getFormattedPath(pathName);
      output = routes.find((obj) => obj.paths.find((i) => this.getFormattedPath(i.path) === formattedPath));
      title = output ? output.headerTitle : '';
    }
    return title;
  };

  render() {
    return (
      <Content>
        <PrivateGrid>
          <H1 className="mb-0">{this.getPageTitle()}</H1>
        </PrivateGrid>
      </Content>
    );
  }
}

PublicPageTitle.defaultProps = defaultProps;
PublicPageTitle.propTypes = propTypes;

export default PublicPageTitle;
