export const key = 'aboutCompany';

export const CHANGE_NAME = 'Notchup/Client/AboutCompanyPage/CHANGE_NAME';
export const CHANGE_BRAND = 'Notchup/Client/AboutCompanyPage/CHANGE_BRAND';
export const CHANGE_REGISTER_NUMBER = 'Notchup/Client/AboutCompanyPage/CHANGE_REGISTER_NUMBER';
export const CHANGE_VAT_NUMBER = 'Notchup/Client/AboutCompanyPage/CHANGE_VAT_NUMBER';
export const CHANGE_INDUSTRY = 'Notchup/Client/AboutCompanyPage/CHANGE_INDUSTRY';
export const CHANGE_COMPANY_TYPE = 'Notchup/Client/AboutCompanyPage/CHANGE_COMPANY_TYPE';
export const COMPANY_CULTURES = 'Notchup/Client/AboutCompanyPage/COMPANY_CULTURES';

export const CHANGE_LINKEDIN_PROFILE = 'CHANGE_LINKEDIN_PROFILE';
export const CHANGE_GITHUB_PROFILE = 'CHANGE_GITHUB_PROFILE';
export const CHANGE_STACKOVERFLOW_PROFILE = 'CHANGE_STACKOVERFLOW_PROFILE';
export const CHANGE_DRIBBBLE_PROFILE = 'CHANGE_DRIBBBLE_PROFILE';
export const CHANGE_BEHANCE_PROFILE = 'CHANGE_BEHANCE_PROFILE';
export const CHANGE_PERSONAL_PROFILE = 'CHANGE_PERSONAL_PROFILE';
export const CHANGE_GLASSDOOR_URL = 'CHANGE_GLASSDOOR_URL';
export const CHANGE_CORPORATE_URL = 'Notchup/Client/AboutCompanyPage/CHANGE_CORPORATE_URL';
export const CHANGE_TECH_STACK = 'Notchup/Client/AboutCompanyPage/CHANGE_TECH_STACK';
export const CHANGE_MEDIA = 'Notchup/Client/AboutCompanyPage/CHANGE_MEDIA';
export const CHANGE_BIO = 'Notchup/Client/AboutCompanyPage/CHANGE_BIO';

export const SUBMIT_ABOUT_COMPANY_FORM = 'Notchup/Client/AboutCompanyPage/SUBMIT_ABOUT_COMPANY_FORM';
