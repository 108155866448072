export const notificationPage = 1;
export const notificationPageLimit = 10;

export const NOTIFICATION_TYPE = {
  BRIEF_ADDED: 'BRIEF_ADDED',
  NEW_QUOTE: 'NEW_QUOTE',
  TALENT_ADDED: 'TALENT_ADDED',
  TIMESHEET_SUBMIT: 'TIMESHEET_SUBMIT',
  TIMESHEET_APPROVED: 'TIMESHEET_APPROVED',
  TIMESHEET_INREVIEW: 'TIMESHEET_INREVIEW',
  TIMESHEET_SETTELED: 'TIMESHEET_SETTELED',
};
