import React from 'react';
import { Progress } from 'reactstrap';
import { colorMapper, getColorVariant } from './utils';

const CustomProgressBar = ({ value }) => {
  const color = getColorVariant(value);

  return (
    <Progress
      value={value}
      max={100}
      color={color}
      className="mb-2"
      style={{ height: '3px', backgroundColor: `rgb(${colorMapper[color]},0.4)` }}
    />
  );
};

export default CustomProgressBar;
