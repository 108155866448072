import produce from 'immer';
import { createAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ToastifyMessage } from 'components';

const initialState = {
  compareTalents: {},
};

export const updateTalentComparison = createAction('UPDATE_TALENT_COMPARISON_LIST');
export const resetTalentComparison = createAction('RESET_TALENT_COMPARISON_LIST');

export const compareTalentReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case updateTalentComparison.type:
        const talentId = action.payload.talentUserId;
        if (action.payload.talentUserId in draft.compareTalents) {
          delete draft.compareTalents[action.payload.talentUserId];
        } else {
          if (Object.keys(draft.compareTalents).length >= 5) {
            toast.warn(<ToastifyMessage message={'Cannot select more than 5 members'} type="error" />);
          } else {
            draft.compareTalents[talentId] = action.payload;
          }
        }
        break;
      case resetTalentComparison.type:
        draft.compareTalents = {};
        break;
      default:
    }
  });

export default compareTalentReducer;
