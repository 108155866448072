import {
  CHANGE_NAME,
  CHANGE_BRAND,
  CHANGE_REGISTER_NUMBER,
  CHANGE_VAT_NUMBER,
  CHANGE_INDUSTRY,
  CHANGE_COMPANY_TYPE,
  COMPANY_CULTURES,
  CHANGE_LINKEDIN_PROFILE,
  CHANGE_GITHUB_PROFILE,
  CHANGE_STACKOVERFLOW_PROFILE,
  CHANGE_DRIBBBLE_PROFILE,
  CHANGE_BEHANCE_PROFILE,
  CHANGE_GLASSDOOR_URL,
  CHANGE_PERSONAL_PROFILE,
  SUBMIT_ADD_A_COMPANY_FORM,
  CHANGE_LOCATION_NAME,
  CHANGE_POSTCODE,
  CHANGE_ADDRESSLINEONE,
  CHANGE_ADDRESSLINETWO,
  CHANGE_CITY,
  CHANGE_COUNTRY,
  CHANGE_STATE,
  CHANGE_TIMEZONE,
  SUBMIT_COMPANY_LOCATION_FORM,
  CHANGE_INVITED_USER_EMAIL,
  CHANGE_INVITED_USER_NAME,
  CHANGE_CORPORATE_WEBSITE_URL,
  SUBMIT_CREATE_ACCOUNT,
  CHANGE_COMPANY_DOMAIN,
  CHANGE_INVITED_USER_ROLE,
  CHANGE_BIO,
} from './constants';

export function changeName(payload) {
  return {
    type: CHANGE_NAME,
    payload,
  };
}

export function changeBrand(payload) {
  return {
    type: CHANGE_BRAND,
    payload,
  };
}

export function changeRegisteredNumber(payload) {
  return {
    type: CHANGE_REGISTER_NUMBER,
    payload,
  };
}

export function changeCorporateWebsiteUrl(payload) {
  return {
    type: CHANGE_CORPORATE_WEBSITE_URL,
    payload,
  };
}

export function changeVatNumber(payload) {
  return {
    type: CHANGE_VAT_NUMBER,
    payload,
  };
}

export function changeIndustry(payload) {
  return {
    type: CHANGE_INDUSTRY,
    payload,
  };
}

export function changeCompanyType(payload) {
  return {
    type: CHANGE_COMPANY_TYPE,
    payload,
  };
}

export function selectTagCompanyCultures(payload) {
  return {
    type: COMPANY_CULTURES,
    payload,
  };
}

export function changeLinkedInProfile(payload) {
  return {
    type: CHANGE_LINKEDIN_PROFILE,
    payload,
  };
}
export function changeGithubProfile(payload) {
  return {
    type: CHANGE_GITHUB_PROFILE,
    payload,
  };
}
export function changeStackoverflowProfile(payload) {
  return {
    type: CHANGE_STACKOVERFLOW_PROFILE,
    payload,
  };
}
export function changeDribbleProfile(payload) {
  return {
    type: CHANGE_DRIBBBLE_PROFILE,
    payload,
  };
}

export function changeGlassdoorURL(payload) {
  return {
    type: CHANGE_GLASSDOOR_URL,
    payload,
  };
}
export function changeBehanceProfile(payload) {
  return {
    type: CHANGE_BEHANCE_PROFILE,
    payload,
  };
}
export function changePersonalProfile(payload) {
  return {
    type: CHANGE_PERSONAL_PROFILE,
    payload,
  };
}

export function submitAddACompanyForm(payload, data, onSuccess) {
  return {
    type: SUBMIT_ADD_A_COMPANY_FORM,
    payload,
    data,
    onSuccess,
  };
}

export function changeLocationName(payload) {
  return {
    type: CHANGE_LOCATION_NAME,
    payload,
  };
}
export function changePostcode(payload) {
  return {
    type: CHANGE_POSTCODE,
    payload,
  };
}
export function changeAddressLineOne(payload) {
  return {
    type: CHANGE_ADDRESSLINEONE,
    payload,
  };
}
export function changeAddressLineTwo(payload) {
  return {
    type: CHANGE_ADDRESSLINETWO,
    payload,
  };
}
export function changeCity(payload) {
  return {
    type: CHANGE_CITY,
    payload,
  };
}
export function changeCountry(payload) {
  return {
    type: CHANGE_COUNTRY,
    payload,
  };
}
export function changeState(payload) {
  return {
    type: CHANGE_STATE,
    payload,
  };
}
export function changeTimeZone(payload) {
  return {
    type: CHANGE_TIMEZONE,
    payload,
  };
}
export function onChangeInvitedUserEmail(payload) {
  return {
    type: CHANGE_INVITED_USER_EMAIL,
    payload,
  };
}
export function onChangeInvitedUserRole(payload) {
  return {
    type: CHANGE_INVITED_USER_ROLE,
    payload,
  };
}

export function onChangeInvitedUsername(payload) {
  return {
    type: CHANGE_INVITED_USER_NAME,
    payload,
  };
}
export function onChangeDomain(payload) {
  return {
    type: CHANGE_COMPANY_DOMAIN,
    payload,
  };
}

export function submitAddCompanyLocationForm(payload, data, onSuccess) {
  return {
    type: SUBMIT_COMPANY_LOCATION_FORM,
    payload,
    data,
    onSuccess,
  };
}

export function submitCreateAccount(data, onSuccess) {
  return {
    type: SUBMIT_CREATE_ACCOUNT,
    data,
    onSuccess,
  };
}

export function changeBio(payload) {
  return {
    type: CHANGE_BIO,
    payload,
  };
}
