import styled from 'styled-components';
import { Container } from 'reactstrap';

export const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 110px 0 70px 0;
`;

export const ContainerMod = styled(Container)`
  padding-right: 16px;
  padding-left: 16px;
  max-width: 842px;
  display: flex;
  flex: 1;
`;
