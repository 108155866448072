import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  talentEarningsCard,
  talentTeamsCard,
  talentProjectsCard,
  clientDashboardCard,
  clientTalentsCard,
  clientProjectsCard,
} from 'containers/App/constants';
import { getUserRoleFromURL } from 'utils/Helper';
import { H2, P } from 'components';
import footerMessages from 'components/Footer/messages';
import FooterLinks from 'components/Footer/FooterLinks';
import { LeftSectionBlock, SlideItem, ResponsiveSlider, FooterContent } from './styles';
import messages from './messages';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const talentSliderData = [
  {
    id: 1,
    img: talentTeamsCard,
    title: messages.talentTeamsCardTitle.defaultMessage,
    content: messages.talentTeamsCardContent.defaultMessage,
  },
  {
    id: 2,
    img: talentProjectsCard,
    title: messages.talentProjectsCardTitle.defaultMessage,
    content: messages.talentProjectsCardContent.defaultMessage,
  },
  {
    id: 3,
    img: talentEarningsCard,
    title: messages.talentEarningsCardTitle.defaultMessage,
    content: messages.talentEarningsCardContent.defaultMessage,
  },
];

const clientSliderData = [
  {
    id: 1,
    img: clientProjectsCard,
    title: messages.clientProjectsCardTitle.defaultMessage,
    content: messages.clientProjectsCardContent.defaultMessage,
  },
  {
    id: 2,
    img: clientTalentsCard,
    title: messages.clientTalentsCardTitle.defaultMessage,
    content: messages.clientTalentsCardContent.defaultMessage,
  },
  {
    id: 3,
    img: clientDashboardCard,
    title: messages.clientDashboardCardTitle.defaultMessage,
    content: messages.clientDashboardCardContent.defaultMessage,
  },
];

const LeftSection = () => {
  let userSliderData = [];
  if (getUserRoleFromURL() === 'talent') {
    userSliderData = talentSliderData;
  } else if (getUserRoleFromURL() === 'client') {
    userSliderData = clientSliderData;
  } else {
    userSliderData = talentSliderData;
  }
  return (
    <LeftSectionBlock className="d-none d-lg-flex">
      <ResponsiveSlider
        animationHandler="fade"
        autoPlay
        interval={3000}
        swipeable={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop
      >
        {userSliderData.map((i) => (
          <SlideItem key={i.id}>
            <img src={i.img} alt={i.id} className="img-fluid" />
            <H2>{i.title}</H2>
            <P className="p16" opacityVal="0.5">
              {i.content}
            </P>
          </SlideItem>
        ))}
      </ResponsiveSlider>
      <FooterContent className="d-flex justify-content-between">
        <P className="p16 mb-0 copyright" opacityVal="0.5">
          <FormattedMessage {...footerMessages.copyrights} />
        </P>
        <FooterLinks />
      </FooterContent>
    </LeftSectionBlock>
  );
};

export default LeftSection;
