import styled from 'styled-components';
import { white, primaryGradientRight, primaryNew } from 'themes/variables';
import media from 'themes/media';

const Card = styled.div`
  background: rgb(${white});
  padding: 30px 25px;
  border-radius: 5px;

  &.70vh {
    min-height: 80vh;
  }

  &.unsubscribe {
    padding: 0;
    width: 43%;
    align-self: center;
    margin-top: 50px;
    div {
      margin-bottom: 10px;

      .unsubscribe-img {
        height: 78px;
        width: 78px;
      }
      .btn-sm {
        width: 124px;
        padding: 14px 30px;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        border-radius: 24px;
      }
      .btn-lg {
        width: 248px;
        padding: 14px 30px;
        margin-bottom: 38%;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        border-radius: 24px;
      }
      &.radio-check {
        width: 66%;
        margin: auto;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      .btn-sm {
        align-self: center;
      }
    }
  }

  ${media.large`
		padding: 50px;
	`};

  .auth-layout > & {
    flex: 1 1 0%;
    height: 100%;
  }

  &.pagination-block {
    margin-top: 5px;
    padding: 20px 40px !important;
  }
  &.table-header {
    padding: 30px 40px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    & .form-group {
      margin-bottom: 0;
      width: 200px;

      &.with-width {
        width: 130px;
        ${media.large`
          width: 200px;
	      `};
      }
    }
  }
  &.bg-secondary-gradient {
    background: ${primaryGradientRight};
  }
  &.talent-card {
    :first-child {
      margin-top: 20px;
    }
  }

  &.custom-table-header {
    padding: 30px 40px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    & .form-group {
      margin-bottom: 0;
    }
  }

  &.no-top-right-redius {
    border-radius: 0 0 20px 20px !important;
  }

  &.no-bottom-left-redius {
    border-radius: 20px 20px 0 0 !important;
  }

  .show-more-less-clickable {
    color: rgb(${primaryNew});
    text-decoration: none;
  }
`;

export default Card;
