import styled from 'styled-components';

// Import Fonts

import GTWalsheimProRegularWOFF from 'fonts/GT-Walsheim-Pro-Regular.woff';
import GTWalsheimProRegularWOFF2 from 'fonts/GT-Walsheim-Pro-Regular.woff2';
import GTWalsheimProRegularEOT from 'fonts/GT-Walsheim-Pro-Regular.eot';

import GTWalsheimProMediumWOFF from 'fonts/GT-Walsheim-Pro-Medium.woff';
import GTWalsheimProMediumWOFF2 from 'fonts/GT-Walsheim-Pro-Medium.woff2';
import GTWalsheimProMediumEOT from 'fonts/GT-Walsheim-Pro-Medium.eot';

import GTWalsheimProBoldWOFF from 'fonts/GT-Walsheim-Pro-Bold.woff';
import GTWalsheimProBoldWOFF2 from 'fonts/GT-Walsheim-Pro-Bold.woff2';
import GTWalsheimProBoldEOT from 'fonts/GT-Walsheim-Pro-Bold.eot';

export const ErrorBoundaryContainer = styled.div`

  @font-face {
    font-family: 'GT-Walsheim-Pro-Regular';
    src: url(${GTWalsheimProRegularEOT});
	  src: url('${GTWalsheimProRegularEOT}?#iefix') format('embedded-opentype'),
		url(${GTWalsheimProRegularWOFF2}) format('woff2'),
		url(${GTWalsheimProRegularWOFF}) format('woff'),
   
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
  }

  @font-face {
    font-family: 'GT-Walsheim-Pro-Bold';
    src: url(${GTWalsheimProBoldEOT});
	  src: url('${GTWalsheimProBoldEOT}?#iefix') format('embedded-opentype'),
		url(${GTWalsheimProBoldWOFF2}) format('woff2'),
		url(${GTWalsheimProBoldWOFF}) format('woff'),
   
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
  }

  @font-face {
    font-family:'GT-Walsheim-Pro-Medium';
    src: url(${GTWalsheimProMediumEOT});
	  src: url('${GTWalsheimProMediumEOT}?#iefix') format('embedded-opentype'),
		url(${GTWalsheimProMediumWOFF2}) format('woff2'),
		url(${GTWalsheimProMediumWOFF}) format('woff'),
	
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
  }

  text-align: center;
  margin-top: 190px;
  font-family: 'GT-Walsheim-Pro-Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;

  img {
    width: 120px;
    height: 120px;
  }

  .error-boundry-title {
    margin-top: 30px;
    font-size: 30px;
  }
`;
