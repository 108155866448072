/*
 * ProfessionalDetails Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.containers.PersonalDetails';

export default defineMessages({
  title: {
    id: `${scope}.title.head`,
    defaultMessage: 'Personal details',
  },
  dragAndDrop: {
    id: `${scope}.dragAndDrop`,
    defaultMessage: 'Drag & drop here or',
  },
  dragAndDropImgs: {
    id: `${scope}.dragAndDropImgs`,
    defaultMessage: 'Drag & drop images here or',
  },
  metaTitle: {
    id: `${scope}.metaTitle.head`,
    defaultMessage: 'Personal details',
  },
  fileSizeInfo: {
    id: `${scope}.fileSizeInfo`,
    defaultMessage: 'Min 200px X 200px. JPG or PNG files of max 2MB.',
  },
  headingPersonalDetails: {
    id: `${scope}.headingPersonalDetails`,
    defaultMessage: 'It’s all about you',
  },
  aboutYouTagLine: {
    id: `${scope}.aboutYouTagLine`,
    defaultMessage: 'So we can start building your profile, it’s time for you to fill in a few personal and professional details.',
  },
  csvFileSizeInfo: {
    id: `${scope}.csvFileSizeInfo`,
    defaultMessage: 'CSV, XLS and XLSX files only. Maximum file size 5 MB.',
  },
  cvFileSizeInfo: {
    id: `${scope}.cvFileSizeInfo`,
    defaultMessage: 'DOC, DOCX and PDF files of max 5 MB.',
  },
  photoAndPdfSizeInfo: {
    id: `${scope}.photoAndPdfSizeInfo`,
    defaultMessage: 'JPG, PNG or PDF files of max 2MB.',
  },

  certificateSizeInfo: {
    id: `${scope}.certificateSizeInfo`,
    defaultMessage: 'JPG, PNG or PDF files of 2MB max.',
  },
  labelProfilePhoto: {
    id: `${scope}.labelProfilePhoto`,
    defaultMessage: 'Profile Photo',
  },
  subHeadingBasic: {
    id: `${scope}.subHeadingBasic`,
    defaultMessage: 'Basic',
  },
  titlePreview: {
    id: `${scope}.titlePreview`,
    defaultMessage: 'Preview',
  },
  bannerSizeInfo: {
    id: `${scope}.bannerSizeInfo`,
    defaultMessage: 'Min 1400px X  325px. JPG or PNG files of 2MB max.',
  },
});
