/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { brandingIcon } from 'containers/App/constants';
import { P, Button } from '../index';
import { ErrorBoundaryContainer } from './style';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // state to hold the error
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error('Error In Application', error, errorInfo);
  }

  handleRefreshClick = (e) => {
    navigator.serviceWorker?.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });

    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryContainer className="p-4 d-flex flex-column align-items-center">
          <img src={brandingIcon} alt="Notchup" />
          <P className="error-boundry-title mb-0">Please bear with us..</P>
          <P className="p16 mt-3 mb-4" opacityVal="0.5">
            Sorry for the incovenience. We suggest you <strong>refresh the page</strong> to resolve the issue.
          </P>
          <Button className="btn-primary btn btn-sm" onClick={(e) => this.handleRefreshClick(e)}>
            Refresh
          </Button>
        </ErrorBoundaryContainer>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
