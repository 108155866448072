import styled from 'styled-components';
import { primaryDarkNew } from 'themes/variables';
import media from 'themes/media';

const H6 = styled.h6`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
  font-family: 'GT-Walsheim-Pro-Medium';
  color: rgba(${primaryDarkNew}, ${(props) => props.opacityVal || 1});

  ${media.medium`
    font-size: 16px;
    line-height: ${(props) => (props.lineHeight ? `${props.lineHeight}px` : '18px')};
  `};
`;
export default H6;
