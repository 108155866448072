/*
 * AmbassadorClients Messages
 *
 * This contains all the text for the AmbassadorClients component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.containers.AmbassadorClients';

export default defineMessages({
  title: {
    id: `${scope}.title.head`,
    defaultMessage: 'Clients',
  },
  metaTitle: {
    id: `${scope}.metaTitle.head`,
    defaultMessage: 'Clients',
  },
  headerManageClient: {
    id: `${scope}.headerManageClient`,
    defaultMessage: 'Clients',
  },
  allPartners: {
    id: `${scope}.allPartners`,
    defaultMessage: 'All your clients',
  },
});
