/**
 * FeatureJobWidget
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { P, Button } from 'components';
import {
  API_URL,
  JOB_POST,
  FEATURE,
  assignmentArray,
  workPreferenceArray,
  preferenceOfTeamArray,
  currencyData,
  employmentTypeArray,
} from 'containers/App/constants';
import request from 'utils/request';
import { getCurrencySymbol, getArrayLabels } from 'containers/MyProfilePage/components/utils';
import { VALIDATION } from 'utils/constants';
import ToastifyMessage from 'components/ToastifyMessage';
import containerMessage from 'containers/messages';
import { WidgetCard, JobWidgetSlider } from './styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { appyTransperantBg } from '../../../utils/Helper';

export class FeatureJobWidget extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;

    this.state = {
      jobDetails: [],
      params: queryString.parse(location.search),
    };
  }

  componentDidMount() {
    appyTransperantBg();
    this.loadJobPostDetails();
  }

  loadJobPostDetails = () => {
    const data = { method: 'GET' };
    const { params } = this.state;
    const { limit = 30 } = params;
    const requestURL = `${API_URL}${JOB_POST}${FEATURE}?limit=${limit}`;
    request(requestURL, data)
      .then(this.setJobPostDetails)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  setJobPostDetails = (response) => {
    if (get(response, 'status')) {
      const { data } = response;
      this.setState({ jobDetails: data });
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
    }
  };

  formatURL = (value) => value.toLowerCase().replaceAll(' ', '-').replaceAll('/', '-');

  redirectionURL = (obj) => {
    const companyBrand = this.formatURL(get(obj, 'companyBrand', ''));
    const companyName = this.formatURL(get(obj, 'companyName', ''));
    const jobTitle = this.formatURL(get(obj, 'name', ''));
    const jobID = get(obj, 'jobId');
    const redirectionPageURL = `/${companyBrand || companyName || 'client'}/jobs/${jobTitle}/${jobID}`;
    window.open(redirectionPageURL);
  };

  render() {
    const { jobDetails } = this.state;

    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: jobDetails.length < 3 ? jobDetails.length : 3,
      slidesToScroll: 3,
      arrows: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <>
        {jobDetails.length > 0 && (
          <JobWidgetSlider {...settings}>
            {jobDetails.map((jobData) => (
              // eslint-disable-next-line no-underscore-dangle
              <WidgetCard key={jobData._id}>
                <>
                  <div
                    className="inner-card-block"
                    role="button"
                    onClick={() => this.redirectionURL(jobData)}
                    onKeyDown={() => this.redirectionURL(jobData)}
                    tabIndex={0}
                  >
                    <P className="p14" opacityVal="0.6" fontFamily="'Poppins', sans-serif">
                      {jobData.expertise}
                    </P>
                    <h4>{jobData.name}</h4>
                    {jobData.assignments.length >= 1 && (
                      <div className="d-flex align-items-start align-items-md-center mb-3 flex-column flex-md-row">
                        {jobData.assignments.length >= 1 && (
                          <div className="job-tag bg-info mb-2 mb-md-0">
                            {getArrayLabels(get(jobData, 'assignments', []), assignmentArray)
                              .slice(0, 1)
                              .map((subItem) => subItem)}
                          </div>
                        )}
                        {jobData.assignments.length >= 1 && (
                          <div className="job-tag bg-primary">
                            {getArrayLabels(get(jobData, 'workPreference', []), workPreferenceArray)
                              .slice(0, 1)
                              .map((subItem) => subItem)}
                          </div>
                        )}
                      </div>
                    )}
                    <P className="p20 text-primary mb-0" fontFamily="'Poppins', sans-serif">
                      {get(jobData, 'employmentType', []).includes('permanent-employee') ? (
                        <>
                          {getCurrencySymbol(currencyData, 'code', get(jobData, 'currencyAnnualRate'))}
                          {jobData.annualRate}/annum
                        </>
                      ) : (
                        <>
                          {getCurrencySymbol(currencyData, 'code', get(jobData, 'currency'))}
                          {jobData.ratePerHour}/hr
                        </>
                      )}
                    </P>
                    <hr />
                    <Row>
                      <Col className="col-sm-6">
                        <P className="p14" opacityVal="0.6" fontFamily="'Poppins', sans-serif">
                          Type
                        </P>
                        <h5 className="p16">
                          {getArrayLabels(get(jobData, 'employmentType', []), employmentTypeArray)
                            .slice(0, 1)
                            .map((subItem) => subItem)}
                        </h5>
                      </Col>
                      <Col className="col-sm-6">
                        <P className="p14" opacityVal="0.6" fontFamily="'Poppins', sans-serif">
                          <FormattedMessage {...containerMessage.subHeadingTeam} />
                        </P>
                        <h5 className="p16">
                          {get(jobData, 'teamPreference', []).length !== 0 ? (
                            <>
                              {getArrayLabels(get(jobData, 'teamPreference', []), preferenceOfTeamArray)
                                .slice(0, 1)
                                .map((subItem) => subItem)}
                            </>
                          ) : (
                            '-'
                          )}
                        </h5>
                      </Col>
                    </Row>
                    <hr />
                    <div>
                      <ul>
                        {get(jobData, 'skills', []).length > 0
                          ? get(jobData, 'skills', [])
                              .slice(0, 2)
                              .map((skill) => <li key={skill}>{skill}</li>)
                          : null}
                        {get(jobData, 'skills', []).length > 2 ? <li>+{get(jobData, 'skills', []).length - 2} Skills</li> : ''}
                      </ul>
                    </div>
                    <Button type="button" className="btn btn-outline mt-auto w-100" onClick={() => this.redirectionURL(jobData)}>
                      Apply now
                    </Button>
                  </div>
                </>
              </WidgetCard>
            ))}
          </JobWidgetSlider>
        )}
      </>
    );
  }
}

FeatureJobWidget.defaultProps = {
  history: {},
  location: {},
};

FeatureJobWidget.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
export default FeatureJobWidget;
