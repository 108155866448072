/**
 * this is Public pages layout structure.
 * this have a code when user are  ogin into system this layout call else it will automatically
 * redirect to login page
 * @author Mahendra
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { userExists, getUserType } from 'utils/Helper';
import StorageService from 'utils/StorageService';
import MainContent from 'components/MainContent';
import Content from 'components/Content';
import Emitter from 'utils/emitter';
import { TALENT_DASHBOARD, AGENCY_DASHBOARD, TALENT } from 'containers/App/constants';
import ClientSidebar from 'components/Sidebar/ClientSidebar';
import TalentSidebar from 'components/Sidebar/TalentSidebar';
import RecruiterSidebar from 'components/Sidebar/RecruiterSidebar';
import AmbassadorSidebar from 'components/Sidebar/AmbassadorSidebar';
import Header from 'components/Header';
import PublicPageTitle from 'components/Header/PublicPageTitle';
import Footer from 'components/Footer/index';
import { clientRedirectPageURL, agencyRedirectPageURL, recruiterRedirectPageURL, ambassadorRedirectPageURL } from 'containers/App/utils';
import useEngagebay from '../../../hooks/useEngagebay';
import useCheckVersion from '../../../hooks/useCheckVersion';
import { PrivateWrapper } from '../styles';
import { useAppDispatch, useAppSelector } from 'hooks';

/**
 * Layout is main layout renderer const.
 * @param {object} props are property object pass into this const function
 * @author Mahendra
 */
export const Layout = (props) => {
  const { openMenu } = props;
  useEngagebay();
  useCheckVersion();
  const [isOpen, setToggle] = useState(StorageService.get('hamburgerToggle') ?? openMenu);

  useEffect(() => {
    Emitter.on(Emitter.EVENTS.HAMBURGER_TOGGLE, (isToggleOpen) => {
      setToggle(isToggleOpen);
    });
  }, [isOpen]);

  const { children } = props;
  let sidebar = '';
  if (getUserType() === 'talent' || getUserType() === 'agency') {
    sidebar = <TalentSidebar {...props} />;
  } else if (getUserType() === 'client' || getUserType() === 'admin') {
    sidebar = <ClientSidebar {...props} />;
  } else if (getUserType() === 'talent-partner') {
    sidebar = <RecruiterSidebar {...props} />;
  } else if (getUserType() === 'ambassador') {
    sidebar = <AmbassadorSidebar {...props} />;
  }
  const sideBarClsName = `${isOpen ? 'sidebar-open' : 'sidebar-close'} with-sidebar`;

  return (
    <PrivateWrapper className="public-layout">
      {userExists() ? (
        <React.Fragment>
          <Header {...props} />
          {sidebar}
        </React.Fragment>
      ) : (
        <PublicPageTitle {...props} />
      )}
      <MainContent id="main-content" className={userExists() ? sideBarClsName : 'public-layout'}>
        <Content id="content" className="p-0">
          {children}
        </Content>
        <Footer id="footer" />
      </MainContent>
    </PrivateWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

Layout.defaultProps = {
  children: <div>.</div>,
};

Layout.displayName = 'Layout';

const redirectToPage = (props) => {
  let output = '';
  const userType = parseInt(StorageService.get('userType'), 10);
  const signupStep = parseInt(StorageService.get('signupStep'), 10);
  const requestChangeEmail = parseInt(StorageService.get('requestChangeEmail'), 10);
  const { pathname } = props.location
    ? props.location
    : {
        location: '',
      };

  if (userType === 1 && requestChangeEmail === 1 && pathname !== TALENT_DASHBOARD && pathname !== `${TALENT}/account-settings`) {
    const url = TALENT_DASHBOARD;
    output = (
      <Redirect
        to={{
          pathname: url,
          state: { from: props.location },
          search: props.location.search,
        }}
      />
    );
  } else if (
    userType === 2 &&
    signupStep < 3 &&
    !['talent', 'team'].some((i) => pathname.split('/').includes(i)) &&
    !pathname.split('/').includes('jobs') &&
    !pathname.split('/').includes('competition-detail')
  ) {
    const url = clientRedirectPageURL(signupStep);
    output = (
      <Redirect
        to={{
          pathname: url,
          state: { from: props.location },
          search: props.location.search,
        }}
      />
    );
  } else if (userType === 3 && signupStep < 0 && pathname !== AGENCY_DASHBOARD) {
    const url = agencyRedirectPageURL(signupStep);
    output = (
      <Redirect
        to={{
          pathname: url,
          state: { from: props.location },
          search: props.location.search,
        }}
      />
    );
  } else if (userType === 5 && signupStep < 2) {
    const url = recruiterRedirectPageURL(signupStep);
    output = (
      <Redirect
        to={{
          pathname: url,
          state: { from: props.location },
          search: props.location.search,
        }}
      />
    );
  } else if (userType === 6 && signupStep < 1) {
    const url = ambassadorRedirectPageURL(signupStep);
    output = (
      <Redirect
        to={{
          pathname: url,
          state: { from: props.location },
          search: props.location.search,
        }}
      />
    );
  } else {
    output = (
      <Layout {...props}>
        <Route {...props} />
      </Layout>
    );
  }

  return output;
};
/**
 * PublicLayout is main layout files.
 * @param {object} props are property object pass into this const function
 * @author Innovify
 */

const PublicLayout = (props) => {
  const openMenu = useAppSelector((state) => state.global.openMenu);
  const dispatch = useAppDispatch();

  return redirectToPage({ ...props, openMenu, dispatch });
};

PublicLayout.displayName = 'PublicLayout';

export default PublicLayout;
