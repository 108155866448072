/*
 * ToolTip
 */

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'reactstrap';
import SVG from 'react-inlinesvg';
import { infoIcon } from 'containers/App/constants';
import SVGIcon from 'components/SVGIcon';

function ToolTip(props) {
  const [tooltipOpen, setTooltip] = useState(false);

  const tooltipToggle = useCallback(() => {
    setTooltip(!tooltipOpen);
  }, [tooltipOpen]);

  const {
    title,
    content,
    wrapperClass,
    titleClass,
    placement,
    type = 'info',
    otherIcon,
    tooltipId = 'TooltipExample',
    iconColor,
    pathFillColor,
  } = props;

  return (
    <div className={wrapperClass}>
      <div href="#" id={tooltipId} className={titleClass}>
        {title}
        {type === 'info' ? (
          <SVGIcon
            src={infoIcon}
            {...{
              iconColor,
              pathFillColor,
            }}
          />
        ) : (
          <SVGIcon
            src={otherIcon}
            {...{
              iconColor,
              pathFillColor,
            }}
          />
        )}
      </div>
      <ReactTooltip
        autohide={false}
        delay={0}
        placement={placement}
        isOpen={tooltipOpen}
        target={tooltipId}
        toggle={() => tooltipToggle(tooltipOpen)}
        innerClassName="text-start"
      >
        {content}
      </ReactTooltip>
    </div>
  );
}

ToolTip.defaultProps = {
  title: '',
  content: '',
  wrapperClass: '',
  titleClass: '',
  placement: 'right',
  type: 'info',
  tooltipId: 'TooltipExample',
  otherIcon: infoIcon,
};

ToolTip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  wrapperClass: PropTypes.string,
  titleClass: PropTypes.string,
  placement: PropTypes.string,
  type: PropTypes.string,
  tooltipId: PropTypes.string,
  otherIcon: PropTypes.any,
};

export default ToolTip;
