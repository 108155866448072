/**
 * FeatureDevlopersWidget
 */

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { API_URL, TALENT, FEATURE } from 'containers/App/constants';
import request from 'utils/request';
import { VALIDATION, DEFAULT_LIMIT } from 'utils/constants';
import ToastifyMessage from 'components/ToastifyMessage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'rc-pagination/assets/index.css';

import LongTalentCard from 'components/LongTalentCard';
import { PrivateGrid } from 'components';
import Content from 'components/Content';
import StorageService from 'utils/StorageService';
import { ResponsiveSlider, SlideItem, SliderItemDiv } from 'components/Layouts/SignInLayoutV2/styles';
import { chunk } from 'lodash';
import { isDesktopWidth } from 'utils/windowUtils';

export const sortUrl = (sort) => {
  let url = '';
  let val = '';
  switch (sort) {
    case 'recentlowToHigh':
      val = { _id: 1 };
      url = `sort=${encodeURIComponent(JSON.stringify(val))}`;
      break;
    case 'recenthighToLow':
      val = { _id: -1 };
      url = `sort=${encodeURIComponent(JSON.stringify(val))}`;
      break;
    case 'lowToHigh':
      val = { experienceOrder: 1 };
      url = `sort=${encodeURIComponent(JSON.stringify(val))}`;
      break;
    case 'highToLow':
      val = { experienceOrder: -1 };
      url = `sort=${encodeURIComponent(JSON.stringify(val))}`;
      break;
    default:
  }
  return url;
};

export class FeatureDevlopersWidget extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      developerList: [],
      params: queryString.parse(location.search),
      windowWidth: window.innerWidth,
    };
    this.contentRef = createRef();
  }

  componentDidMount() {
    this.appyTransperantBg();
    this.loadTalentDetails();
    this.setWindowWidth();
  }

  componentDidUpdate(prevProps, prevState) {
    const { developerList } = this.state;
    if (prevState.developerList !== developerList && developerList.length) {
      this.sendHeightToParent(); // Send height only once when the content is loaded
    }
  }

  sendHeightToParent = () => {
    if (this.contentRef.current) {
      const height = this.contentRef.current.scrollHeight; // Get the height of the content container
      window.parent.postMessage({ height, elementId: 'iframe-1' }, '*');
    }
  };

  appyTransperantBg = () => {
    document.body.className += ' bg-transparent';
    document.getElementById('app').className += ' bg-transparent';
  };

  loadTalentDetails = (pageNum = 1) => {
    StorageService.set('featuredTalentsPageNumber', JSON.stringify(pageNum));
    this.setState({ pageNum });
    const data = { method: 'GET' };
    const filterParam = [];
    const { params } = this.state;
    const { role = null, limit = 30, skills = null, yearsOfExperience = null, teamPreference = null, total = 27 } = params;
    const { sort = null } = params;
    if (role) {
      filterParam.push(`role=${role}`);
    }
    if (skills) {
      filterParam.push(`skills=${skills}`);
    }
    if (yearsOfExperience) {
      filterParam.push(`yearsOfExperience=${yearsOfExperience}`);
    }
    if (teamPreference) {
      filterParam.push(`teamPreference=${teamPreference}`);
    }
    if (sort) {
      filterParam.push(sortUrl(sort));
    }

    if (total) {
      filterParam.push(total);
    }

    const requestURL = `${API_URL}${TALENT}${FEATURE}?${filterParam.join('&')}&limit=${limit}`;
    request(requestURL, data)
      .then(this.setJobPostDetails)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  setJobPostDetails = (response) => {
    if (get(response, 'status')) {
      const { data } = response;
      this.setState({
        developerList: data.docs,
      });
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
    }
  };

  setWindowWidth = () => {
    window.addEventListener('resize', () => {
      this.setState({ windowWidth: window.innerWidth });
    });
  };

  render() {
    const { developerList, windowWidth } = this.state;
    const itemsPerSlide = isDesktopWidth() ? 6 : 1;
    const groupedDevelopers = chunk(developerList, itemsPerSlide);

    return (
      <Content ref={this.contentRef}>
        <PrivateGrid className="cardPrivateContainer">
          {developerList?.length ? (
            <ResponsiveSlider
              autoPlay
              interval={5000}
              swipeable
              showArrows
              showStatus={false}
              showThumbs={false}
              infiniteLoop
              showIndicators={false}
            >
              {groupedDevelopers.map((group, index) => (
                <SlideItem className="w-100" key={index}>
                  <SliderItemDiv className="row w-100">
                    {group.map((dev) => (
                      <div className={`${isDesktopWidth() ? 'col-4' : 'col-12'} mb-3`} key={dev._id}>
                        <LongTalentCard
                          data={dev}
                          clientDetails={{
                            subscribed: true,
                          }}
                          SubscriptionModalRef={null}
                          isFeatured
                          hireButtonText="Schedule Interview"
                          showInterviewTalent={false}
                          sliceBy={windowWidth <= 768 ? 2 : 3}
                          showHireButton={false}
                          columnClass={'h-100'}
                          talentCardContainerClass={'h-90'}
                          talentCardBlockClass={'h-100'}
                        />
                      </div>
                    ))}
                  </SliderItemDiv>
                </SlideItem>
              ))}
            </ResponsiveSlider>
          ) : null}
        </PrivateGrid>
      </Content>
    );

    // return (
    //   <DeveloperJobWidgetSlider {...settings}>
    //     {(developerList || []).map((developer) => (
    //       <DeveloperWidgetBlock key={developer} developer={developer} />
    //     ))}
    //   </DeveloperJobWidgetSlider>
    // );
  }
}

FeatureDevlopersWidget.defaultProps = {
  location: '',
};

FeatureDevlopersWidget.propTypes = {
  location: PropTypes.object,
};

export default FeatureDevlopersWidget;
