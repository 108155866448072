export const key = 'addACompany';

export const CHANGE_NAME = 'Notchup/Admin/AddACompanyPage/CHANGE_NAME';
export const CHANGE_BRAND = 'Notchup/Admin/AddACompanyPage/CHANGE_BRAND';
export const CHANGE_REGISTER_NUMBER = 'Notchup/Admin/AddACompanyPage/CHANGE_REGISTER_NUMBER';
export const CHANGE_VAT_NUMBER = 'Notchup/Admin/AddACompanyPage/CHANGE_VAT_NUMBER';
export const CHANGE_INDUSTRY = 'Notchup/Admin/AddACompanyPage/CHANGE_INDUSTRY';
export const CHANGE_COMPANY_TYPE = 'Notchup/Admin/AddACompanyPage/CHANGE_COMPANY_TYPE';
export const COMPANY_CULTURES = 'Notchup/Admin/AddACompanyPage/COMPANY_CULTURES';

export const CHANGE_LINKEDIN_PROFILE = 'CHANGE_LINKEDIN_PROFILE';
export const CHANGE_GITHUB_PROFILE = 'CHANGE_GITHUB_PROFILE';
export const CHANGE_STACKOVERFLOW_PROFILE = 'CHANGE_STACKOVERFLOW_PROFILE';
export const CHANGE_DRIBBBLE_PROFILE = 'CHANGE_DRIBBBLE_PROFILE';
export const CHANGE_BEHANCE_PROFILE = 'CHANGE_BEHANCE_PROFILE';
export const CHANGE_PERSONAL_PROFILE = 'CHANGE_PERSONAL_PROFILE';
export const CHANGE_GLASSDOOR_URL = 'CHANGE_GLASSDOOR_URL';
export const CHANGE_BIO = 'CHANGE_BIO';

export const SUBMIT_ADD_A_COMPANY_FORM = 'Notchup/Admin/AddACompanyPage/SUBMIT_ADD_A_COMPANY_FORM';

export const addCompanySteps = ['Company details', 'Locations', 'Invite'];

export const CHANGE_LOCATION_NAME = 'Notchup/Admin/AddACompanyPage/CHANGE_LOCATION_NAME';
export const CHANGE_POSTCODE = 'Notchup/Admin/AddACompanyPage/CHANGE_POSTCODE';
export const CHANGE_ADDRESSLINEONE = 'Notchup/Admin/AddACompanyPage/CHANGE_ADDRESSLINEONE';
export const CHANGE_ADDRESSLINETWO = 'Notchup/Admin/AddACompanyPage/CHANGE_ADDRESSLINETWO';
export const CHANGE_CITY = 'Notchup/Admin/AddACompanyPage/CHANGE_CITY';
export const CHANGE_COUNTRY = 'Notchup/Admin/AddACompanyPage/CHANGE_COUNTRY';
export const CHANGE_STATE = 'Notchup/Admin/AddACompanyPage/CHANGE_STATE';
export const CHANGE_TIMEZONE = 'Notchup/Admin/AddACompanyPage/CHANGE_TIMEZONE';
export const CHANGE_INVITED_USER_EMAIL = 'Notchup/Admin/AddACompanyPage/CHANGE_INVITED_USER_EMAIL';
export const CHANGE_INVITED_USER_ROLE = 'Notchup/Admin/AddACompanyPage/CHANGE_INVITED_USER_ROLE';
export const CHANGE_INVITED_USER_NAME = 'Notchup/Admin/AddACompanyPage/CHANGE_INVITED_USER_NAME';
export const CHANGE_COMPANY_DOMAIN = 'Notchup/Admin/AddACompanyPage/CHANGE_COMPANY_DOMAIN';
export const CHANGE_CORPORATE_WEBSITE_URL = 'Notchup/Admin/AddACompanyPage/CHANGE_CORPORATE_WEBSITE_URL';

export const SUBMIT_COMPANY_LOCATION_FORM = 'Notchup/Admin/AddACompanyPage/SUBMIT_COMPANY_LOCATION_FORM';
export const SUBMIT_CREATE_ACCOUNT = 'Notchup/Admin/AddACompanyPage/SUBMIT_CREATE_ACCOUNT';
export const SUBMIT_INVITE_ADMIN = 'Notchup/Admin/AddACompanyPage/SUBMIT_INVITE_ADMIN';
