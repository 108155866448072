export const key = 'TALENT_BRIEF';
export const projectPreference = [
  {
    label: 'Full time',
    value: 'fulltime',
  },
  {
    label: 'Part time',
    value: 'parttime',
  },
  {
    value: 'parttime-weekdays-am',
    label: 'Weekdays-AM',
  },
  {
    value: 'parttime-weekdays-pm',
    label: 'Weekdays-PM',
  },
  {
    value: 'parttime-weekends',
    label: 'Weekends',
  },
];

export const appliedArray = [
  { name: 'Applied', value: 'Applied' },
  { name: 'Not Applied', value: 'Not Applied' },
];

export const postedOnArray = [
  { name: 'Last 24 hours', value: 'Last 24 hours' },
  { name: 'Last 7 days', value: 'Last 7 days' },
  { name: 'Last 14 days', value: 'Last 14 days' },
  { name: 'Last 30 days', value: 'Last 30 days' },
];

export const initialFilterValue = {
  roleArray: [],
  expertiseArray: [],
  contractTypeArray: [],
  projectDuration: '',
  workPrefArray: [],
  assignmentsArray: [],
  companySizeArray: [],
  teamPrefArray: [],
};

export const briefFiltersKeys = [
  'primaryRole',
  'yearsOfExperience',
  'employmentType',
  'preferredProjectDuration',
  'workPreference',
  'assignments',
  'companyType',
  'teamPreference',
];
